import React from "react"
import PageWrapper from "../../components/wrapper/pageWrapper"
import ShowCase from "../../components/showcase"
import Venue from "../../components/venue";


const Accessibility = () => (
  <PageWrapper locale="en" location="/accessibility">
    <ShowCase type="accessibility"/>
    <section className="subPage_contents_wrapper">
      <br/>
      <br/>
      <p className="subPage_contents">JSConf Korea strives to be an accessible and inclusive conference. Here, you can read about the services we provide and the environment in and around the conference space.</p>
      <div className="listing_block">
        <ul className="subPage_list">
            <li className="subPage_list_item"><a href="#space">Conference Space</a></li>
            <li className="subPage_list_item"><a href="#talks">Talks</a></li>
            <li className="subPage_list_item"><a href="#noise">Noise, sound, and lights</a></li>
            <li className="subPage_list_item"><a href="#children">If you’re coming with children</a></li>
            <li className="subPage_list_item"><a href="#mobility">Access for wheelchair users and people with reduced mobility</a></li>
            <li className="subPage_list_item"><a href="#support">If you’re coming with a support person</a></li>
            <li className="subPage_list_item"><a href="#food">Food</a></li>
            <li className="subPage_list_item"><a href="#bathrooms">Bathrooms</a></li>
            <li className="subPage_list_item"><a href="#prayerroom">Prayer Room</a></li>
            <li className="subPage_list_item"><a href="#health">Health and Safety</a></li>
            <li className="subPage_list_item"><a href="#from_coex">Getting here</a></li>
            <li className="subPage_list_item"><a href="#contact">Contact</a></li>

        </ul>
      </div>
      <h1 id="space" className="subPage_title">Conference Space</h1>
      <p className="subPage_contents">Our conference space consists of the lobby, auditorium, and two conference rooms.</p>
      <br/>
      <Venue />
      
      <p className="subPage_contents">Entering the lobby, there will be registration desks on both sides, and bathrooms directly ahead. Ahead to your right is room 401, to the left is room 402, and beyond room 402 is room 403.</p> <br/>
      <p className="subPage_contents">Room 401, where the talks will take place, is an auditorium with 440 seats looking onto a stage. There is an entrance on either side of the stage, with a ramp leading up to the entrance on the right.</p><br/>
      <p className="subPage_contents">In the lobby and in room 402, there will be sponsor booths and some seats for rest. Lightning talks by sponsors and communities will be given in room 402.</p><br/>
      <p className="subPage_contents">Room 403, where tutorials will take place, is accessible from the hallway on the far left with respect to the lobby entrance.</p><br/>
      <p className="subPage_contents">For a virtual tour, visit <a href="http://k-mice.visitkorea.or.kr/vr/sites/COX.kto?lang=ko#" target="_blanc">Coex’s 360 photos and videos</a> page.</p>
      <br/>
      <p className="subPage_contents">
          <a href="#">🔼Back to top</a></p>
      <h1 id="talks" className="subPage_title">Talks</h1>
      <p className="subPage_contents">The talks will be given in the auditorium (Room 401). All talks will be live interpreted into English and Korean, and live captioned in Korean thanks to <a href="https://foundation.mozilla.org/en/" target="_blanc">Mozilla Foundation</a>.</p><br/>
      <p className="subPage_contents">The captions may not be clearly visible at the back of the auditorium. If you’d like to be seated close to the screen so that the captions are well visible, let us know in advance at <a href="mailto:contact@jsconfkorea.com">contact@jsconfkorea.com</a> and we will direct you accordingly.</p><br/>
      <p className="subPage_contents">You can also view the Korean captions through the <a href="http://sharetalk.co.kr/#download">ShareTalk application</a>.</p>
      <br/>
      <p className="subPage_contents">
          <a href="#">🔼Back to top</a></p>
      <h1 id="noise" className="subPage_title">Noise, sound, and lights</h1>
      <p className="subPage_contents">There may be some music playing in room 401, but there won’t be any flashing light effects.</p><br/>
      <p className="subPage_contents">The lobby may be crowded during break times since we expect to reach full capacity for the conference space. If you ever need some peace and quiet, head down to the 1st floor, step outside, and take a break at the Central Plaza.</p>
      <br/>
      <p className="subPage_contents">
          <a href="#">🔼Back to top</a></p>
      <h1 id="children" className="subPage_title">If you’re coming with children</h1>
      <h2 className="subPage_subTitle">Childcare</h2>
      <div className="listing_block plan_list">
        <ul className="subPage_list">
          <li className="subPage_list_item">Registration for complementary childcare service has closed.</li>
        </ul>
      </div>

      <h2 className="subPage_subTitle">Nursing room</h2>
      <p className="subPage_contents">There is a nursing room in the COEX mall on the basement floor. The nursing room is equipped with sinks, private nursing seats, and changing stations.</p>
      <div className="listing_block notice_list">
        <ul className="subPage_list">
          <li className="subPage_list_item">Location: Hallway next to Under $10 Shop</li>
          <li className="subPage_list_item">Getting there: Take the elevator on the west end of the hallway down to B1 floor, open the door to the left, and go straight until you reach the nursing room located to your right.</li>
          <li className="subPage_list_item">Phone: 02-6002-5318</li>
          <li className="subPage_list_item">Hours: 10:30 am - 8 pm</li>
        </ul>
      </div>
      <p className="subPage_contents">Detailed map can be found <a href="https://www.starfield.co.kr/coexmall/tenant/facility.do" target="_blanc">here</a>.</p>
      <br/>
      <p className="subPage_contents">
          <a href="#">🔼Back to top</a></p>
      <h1 id="mobility" className="subPage_title">Access for wheelchair users and people with reduced mobility</h1>
      <p className="subPage_contents">You’ll be able to explore all conference space with a wheelchair, including the auditorium. However, there is no automatic entrance for the building, so if you are coming without a support person, please let us know in advance with your contact information at <a href="mailto:contact@jsconfkorea.com">contact@jsconfkorea.com</a> and our staff will escort you from the door or from the subway station, bus stop, or parking lot.</p><br/>
      <p className="subPage_contents">All seats in the auditorium have armrests.</p>
      <br/>
      <p className="subPage_contents">
          <a href="#">🔼Back to top</a></p>
      <h2 className="subPage_subTitle">Wheelchair rentals</h2>
      <p className="subPage_contents">Wheelchairs are availble to rent for free at the Coex Safety Center. Just remember to bring your ID.</p>
      <div className="listing_block notice_list">
        <ul className="subPage_list">
          <li className="subPage_list_item">Location: Coex 1st floor next to West Gate</li>
          <li className="subPage_list_item">Phone: 02-6000-0112</li>
          <li className="subPage_list_item">Hours: 8:30 am - 6 pm</li>
        </ul>
      </div>
      <br/>
      <p className="subPage_contents">
          <a href="#">🔼Back to top</a></p>
      <h1 id="support" className="subPage_title">If you’re coming with a support person</h1>
      <p className="subPage_contents">A support person helps people with impairments/disabilities for their health and safety. A support person may help with, for example, </p>
      <div className="listing_block notice_list">
        <ul className="subPage_list">
          <li className="subPage_list_item">Sign language interpretation</li>
          <li className="subPage_list_item">Guiding around obstacles</li>
          <li className="subPage_list_item">Prompting or giving advice, including requesting a break if needed</li>
          <li className="subPage_list_item">Providing physical or emotional support during travel and/or the event</li>
        </ul>
      </div>
      <p className="subPage_contents">If you are coming with a support person, your support person does not need a ticket to attend. You will just need to indicate that they are coming when you purchase your ticket.</p>
      <br/>
      <p className="subPage_contents">
          <a href="#">🔼Back to top</a></p>
      <h1 id="food" className="subPage_title">Food</h1>
      <p className="subPage_contents">We provide a bottled water and a meal voucher for select restaurants in the COEX shopping mall.</p><br/>
      <p className="subPage_contents">You are welcome to eat and drink anywhere in the conference space, except for room 401.</p>
      <br/>
      <p className="subPage_contents">
          <a href="#">🔼Back to top</a></p>
      <h1 id="bathrooms" className="subPage_title">Bathrooms</h1>
      <p className="subPage_contents">There are bathrooms in the lobby, including wheelchair-accessible stalls. Please note that these are the only ones available on the same floor as the conference space. </p><br/>
      <p className="subPage_contents">A gender neutral, wheelchair-accessible bathroom is located on the 3rd floor, on the north side of Conference Room E. To get to the bathroom Take the nearest elevator or an escalator down to the third floor. Turn right immediately, then go straight. Take a right turn when you see Conference Room E. Walk along Conference Room E to your left and make a left. The bathroom is at the end of the hallway to your left.</p>
      <br/>
      <p className="subPage_contents">
          <a href="#">🔼Back to top</a></p>
      <h1 id="prayerroom" className="subPage_title">Prayer room</h1>
      <p className="subPage_contents">There is a prayer room on the 3rd floor, on the south side of Conference Room E. It’s divided into men’s and women’s rooms, and is open for use throughout the conference.</p>
      <div className="listing_block notice_list">
        <ul className="subPage_list">
          <li className="subPage_list_item">Location: South of Coex 3rd floor Conference Room E</li>
          <li className="subPage_list_item">Getting there: Take the nearest elevator or an escalator down to the third floor. Turn right immediately, then go straight. Take a right turn when you see Conference Room E. Walk a few steps to find the prayer room to your right.</li>
          <li className="subPage_list_item">Hours: 3 am - 10 pm</li>
          <li className="subPage_list_item">Amenities: Qibla, compass, clock, Qu’ran, carpet for worship, storage closet</li>
        </ul>
      </div>
      <br/>
      <p className="subPage_contents">
          <a href="#">🔼Back to top</a></p>
      <h1 id="health" className="subPage_title">Health and Safety</h1>
      <h2 className="subPage_subTitle">Emergencies</h2>
      <p className="subPage_contents">If your health or safety is in danger, please contact a number below or find a staff member for assistance.</p>
      <div className="listing_block beware_list">
        <ul className="subPage_list">
          <li className="subPage_list_item with_children">Police: 112 (no need for a SIM card or to unlock your phone)
            <ul className="subPage_list ">
              <li className="subPage_list_item">Samseong 1 Police Station: <a href="https://goo.gl/maps/dAMBBQSAuHv" target="_blanc">107-3 Samseong 1(il)-dong, Gangnam-gu, Seoul</a></li>
            </ul>
          </li>
          <li className="subPage_list_item">Medical emergency: 119 (no need for a SIM card or to unlock your phone)</li>
          <li className="subPage_list_item">Coex Safety Center: 02-6000-0112</li>
        </ul>
      </div>
      <h2 className="subPage_subTitle">First-aid</h2>
      <p className="subPage_contents">A first-aid kit will be available at the conference venue. If you are suffering a minor injury or pain, ask one of our staff members for first-aid medicine. </p><br/>
      <p className="subPage_contents">If you require a first-aid response, please ask our staff for help or head over to Coex Medical Office.</p>
      <h3 className="subPage_sectionTitle">Coex Medical Office</h3>
      <div className="listing_block notice_list">
        <ul className="subPage_list">
          <li className="subPage_list_item">Getting there: Take the nearest elevator down to the first floor. Getting out of the elevator and looking slightly to the right there will be a wide hallway; go straight along the hallway until you reach the West Gate. To your left is the Medical Office.</li>
          <li className="subPage_list_item">Phone: 02-6000-1119</li>
          <li className="subPage_list_item">Hours: 10 am - 6:30 pm</li>
        </ul>
      </div><br/>
      <p className="subPage_contents">For other medicines, visit the nearest pharmacy Pharm Ex or ask one of our staff members for assistance.</p>
      <h3 className="subPage_sectionTitle">Pharm Ex Pharmacy</h3>
      <div className="listing_block notice_list">
        <ul className="subPage_list">
          <li className="subPage_list_item">Getting there: Take the elevator on the west end of the hallway down to B1. Walk out to the mall and turn right, and take another right turn at the shoe store “Sneak Sol”. Go straight until you reach a post office; turn left and walk a few steps to find the pharmacy to your left.</li>
          <li className="subPage_list_item">Address: 513 Yeongdongdae-ro Starfield COEX Mall H112, Gangnam-gu, Seoul</li>
          <li className="subPage_list_item">Phone: 02-6002-3060</li>
        </ul>
      </div>
      <br/>
      <p className="subPage_contents">
          <a href="#">🔼Back to top</a></p>
      <h1 id="from_coex" className="subPage_title">Getting here from within COEX</h1>
      <p className="subPage_contents">If you're in Starfield COEX Mall, look for ZARA. Take the escalator or elevators across from ZARA and go up to the 4th floor.</p><br/>
      <p className="subPage_contents">Anywhere else, just make sure the elevator or escalator says it says "Conference Room (South)."</p>
      <h1 id="subway" className="subPage_title">Getting here by subway</h1>
      <p className="subPage_contents">If you are a wheelchair user or have reduced mobility, and coming without a support person, please let us know in advance with your contact information at <a href="mailto:contact@jsconfkorea.com">contact@jsconfkorea.com</a> so that our staff can escort you from the subway station, bus stop, parking lot, or from the building entrance.</p>
      <br/>
      <p className="subPage_contents">Google maps are unreliable in Korea. We recommend downloading <a href="https://therealseoul.com/tag/naver-map/" target="_blanc">Korean map application</a> for navigating.</p>
      <h2 className="subPage_subTitle">From Samseong station (line 2)</h2>
      <div className="listing_block notice_list">
        <ul className="subPage_list">
          <li className="subPage_list_item">Head towards exits number 5 and 6.</li>
          <li className="subPage_list_item with_children">If you are using a wheelchair,
            <ul className="subPage_list ">
              <li className="subPage_list_item">There is a wheelchair lift at the staircase between exits 5 and 6. For your safety, please call Samseong station’s information center at 02-6110-2191 to operate it.</li>
              <li className="subPage_list_item">Get on the elevator next to the staircase for exit 6.</li>
            </ul>
          </li>
          <li className="subPage_list_item">Coming out of exit 6 or the elevator, go straight with the big jumbotron to your left, for 270m. </li>
          <li className="subPage_list_item">To your left, enter Coex through the East Gate.</li>
          <li className="subPage_list_item">Go straight and take the first escalator, or the elevators to its left, up to 4F. Which ever one you take, make sure it says "Conference Room (South)."</li>
          <li className="subPage_list_item">Come out to the hallway and turn right. Go straight and you’ll find our conference venue to the right.</li>
        </ul>
      </div><br/>
      <h3 className="subPage_sectionTitle">Information for wheelchair users and people with reduced mobility</h3> 
      <p className="subPage_contents">Every door of Korean subway cars is numbered with the rule [car number]-[door number]. Here, we list the most convenient doors to get on and off for wheelchairs, strollers, or other mobility devices.</p>
      <div className="listing_block notice_list">
        <ul className="subPage_list">
          <ul className="subPage_list">
            <li className="subPage_list_item">Line 2 subway doors with designated space for wheelchairs or mobility device are at 1-4, 4-4, 7-1, and 10-1.</li>
            <li className="subPage_list_item">The closest doors to get to the elevators at Samseong station are 8-3 for Seolleung station direction, and 3-1 for Sports Complex station direction.</li>
            </ul>
        </ul>
      </div>
      <p className="subPage_contents">In addition, all subway cars have priority seats near doors number 1 and 4.</p>
      <h2 className="subPage_subTitle">From Bongeunsa station (line 9)</h2>
      <div className="listing_block notice_list">
        <ul className="subPage_list">
          <li className="subPage_list_item with_children">Exit the station from exit 7 and turn left.
            <ul className="subPage_list ">
              <li className="subPage_list_item">If you are using a wheelchair, use the elevator next to the staircase leading to exit 7. After getting off the elevator, turn left along the pedestrian path.</li>
            </ul>
          </li>
          <li className="subPage_list_item">Go straight for 280m.</li>
          <li className="subPage_list_item">To your right, enter Coex through the East Gate.</li>
          <li className="subPage_list_item">Go straight and take the first escalator, or the elevators to its left, up to 4F. Which ever one you take, make sure it says "Conference Room (South)."</li>
          <li className="subPage_list_item">Take the elevator to the 4th floor. Come out to the hallway and turn right. Go straight and you’ll find our conference venue to the right.</li>
        </ul>
      </div><br/>
      <h3 className="subPage_sectionTitle">Information for wheelchair users and people with reduced mobility</h3> 
      <p className="subPage_contents">Every door of Korean subway cars is numbered with the rule [car number]-[door number]. Here, we list the most convenient doors to get on and off for wheelchairs, strollers, or other mobility devices.</p>

      <div className="listing_block notice_list">
        <ul className="subPage_list">
          <li className="subPage_list_item with_children">Line 9 subway doors with designated space for wheelchairs or mobility device are at
            <ul className="subPage_list ">
              <li className="subPage_list_item">2-4 and 5-1 for 4-car trains</li>
              <li className="subPage_list_item">1-4 and 6-1 for 6-car trains</li>
            </ul>
          </li>
          <li className="subPage_list_item">The closest doors to get to the elevators for Bongeunsa station are 3-4 for both directions.</li>
        </ul>
      </div>
      <p className="subPage_contents">In addition, all subway cars have priority seats near doors number 1 and 4.</p>
      <br/>
      <p className="subPage_contents">You can get to the conference rooms from the Trade Center or Starfield Mall as well. For more details, please see the directions at <a href="https://www.coexcenter.com/directions-map-subway/" target="_blanc">Coex Directions</a> page</p>
      <h1 id="car" className="subPage_title">Getting here by car</h1>
      <p className="subPage_contents">We recommend using public transportation as the parking space at Coex is limited. If you need a parking space due to reduced mobility, please contact us at <a href="mailto:contact@jsconfkorea.com">contact@jsconfkorea.com</a>.</p>
      
      <h2 className="subPage_subTitle">Parking at Coex</h2>
      <p className="subPage_contents">If you’d like to park at Coex, the most convenient spots are at areas C and D on the B2 floor. Use elevators at entrances 2, 3, 4 or 5 to get to the 4th floor conference space.</p>

      <div className="listing_block notice_list">
        <ul className="subPage_list">
          <li className="subPage_list_item">Address: 58 Teheran-ro 87-gil, Gangnam-gu, Seoul (<a href="https://goo.gl/maps/WeaPUHKAE62KqzD79" target="_blanc">link to map</a>)</li>
          <li className="subPage_list_item with_children">Rates: ₩6,000 / hour, ₩48,000 / day
            <ul className="subPage_list ">
              <li className="subPage_list_item">People with disabilities can get 50% off</li>
              <li className="subPage_list_item">1 hour off every ₩50,000 spent at Starfield Mall, up to 3 hours off.</li>
            </ul>
          </li>
        </ul>
      </div>
      <p className="subPage_contents">For more details, see the <a href="https://www.coexcenter.com/parking/" target="_blanc">Parking</a> page at Coex.</p>
      
      <h2 className="subPage_subTitle">Gangnam Tancheon Parking Lot</h2>
      <p className="subPage_contents">You may also use the Tancheon parking lot, located 15-minute walk away.</p>

      <div className="listing_block notice_list">
        <ul className="subPage_list">
          <li className="subPage_list_item">Address: 171 Samseong-dong, Gangnam-gu, Seoul (<a href="https://goo.gl/maps/MUj4stDxpaihAw7HA" target="_blanc">link to map</a>)</li>
          <li className="subPage_list_item">Phone: 02-2176-0900</li>
          <li className="subPage_list_item"><a href="http://kko.to/N-Lml4mTM" target="_blanc">Directions to Coex</a></li>
          <li className="subPage_list_item">Rates: ₩1,200 / hour (₩100/ 5 minutes)</li>
        </ul>
      </div>
      <p className="subPage_contents">For more details, see the <a href="http://www.coex.co.kr/services/traffic/parking-5?type=visitor" target="_blanc">Parking</a> page at Coex.</p>

      <h1 id="contact" className="subPage_title">Contact us</h1>
      <p className="subPage_contents">If you have any further questions or requests regarding accessibility, please get in touch at <a href="mailto:contact@jsconfkorea.com">contact@jsconfkorea.com</a>.</p>
      <br/>
      <br/>
      <br/>
      <p className="subPage_contents">
          <a href="#">🔼Back to top</a></p>
    </section>
  </PageWrapper>
);

export default Accessibility