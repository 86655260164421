import React from "react"
import PageWrapper from '../../../components/wrapper/pageWrapper'
import ShowCase from "../../../components/showcase"

const Woowahan = () => (
    <PageWrapper locale="en" location="/sponsors/woowahan">
        <ShowCase type="woowahan"/>
        <section className="subPage_contents_wrapper">
            <div className="sponsor_list sponsor_list_detail">
                <div className="sponsor_item">
                    <img className={`sponsor_img woowahan`} alt="woowahan" src={`/sponsors/logo_woowahan.svg`}/>
                </div>
            </div>
            <p className="subPage_contents">
            Woowa Brothers Corp., is unrivaled food delivery platform in Korea that operates online marketplace "Baedal Minjok" for delivery and takeout foods. <br/>
            Woowa Bros is under a mission "Enjoy the food you desire at any place you want" to transform the way customer eat. <br/>
            Under the mission, Woowa Bros is striving to push boundaries even further forward through future projects such as a self-driving delivery robot.
            </p>
        </section>
    </PageWrapper>
)

export default Woowahan