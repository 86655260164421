import React from "react"
import PageWrapper from "../components/wrapper/pageWrapper"
import ShowCase from "../components/showcase"
import Venue from "../components/venue";

const Accessibility = () => (
  <PageWrapper locale="ko" location="/accessibility">
    <ShowCase type="accessibility" />
    <section className="subPage_contents_wrapper">
      <h1 className="subPage_title"></h1>
      <p className="subPage_contents">JSConf Korea는 참가하는 모든 분이 편안하게 즐길 수 있는 컨퍼런스가 되기 위해 최선을 다하고 있습니다. 이 페이지를 통해 JSConf Korea 2019에서 기대할 수 있는 행사장 안팎의 환경과 서비스를 알아보실 수 있습니다.</p>
      <div className="listing_block">
        <ul className="subPage_list">
            <li className="subPage_list_item"><a href="#space">행사장</a></li>
            <li className="subPage_list_item"><a href="#talks">발표 및 통역</a></li>
            <li className="subPage_list_item"><a href="#noise">소음, 음향 볼륨, 조명</a></li>
            <li className="subPage_list_item"><a href="#children">어린이와 함께 오실 때</a></li>
            <li className="subPage_list_item"><a href="#mobility">휠체어 이용자 및 거동 보조가 필요한 분</a></li>
            <li className="subPage_list_item"><a href="#support">활동 보조인과 함께 오실 때</a></li>
            <li className="subPage_list_item"><a href="#food">음식</a></li>
            <li className="subPage_list_item"><a href="#bathrooms">화장실</a></li>
            <li className="subPage_list_item"><a href="#prayerroom">기도실</a></li>
            <li className="subPage_list_item"><a href="#health">안전과 건강</a></li>
            <li className="subPage_list_item"><a href="#from_coex">오시는 길</a></li>
            <li className="subPage_list_item"><a href="#contact">문의</a></li>

        </ul>
      </div>
      <h1 id="space" className="subPage_title">행사장</h1>
      <p className="subPage_contents">컨퍼런스가 열리는 코엑스 컨퍼런스룸(남) 4층(이하 행사장)은 로비, 강당, 그리고 회의실 두개로 구성되어있습니다. </p>
      <br/>
      <Venue />
      <p className="subPage_contents">4층 로비로 들어오시면 양쪽으로 등록 데스크가 있고, 앞으로는 로비를 지나 화장실이 있습니다. 전면 오른편에는 401호 강당이, 왼편으로는 402호가 위치해있고, 그 뒤로는 403호가 있습니다. </p><br/>
      <p className="subPage_contents">401호는 440여 석이 무대를 내려다보는 강당으로, 발표가 진행될 곳입니다. 좌석 구역의 좌우측으로 출입문이 있고 우측 출입문은 경사로를 통해 접근이 가능합니다.</p><br/>
      <p className="subPage_contents">로비와 402호에는 부스와 소량의 좌석이 마련되어있고, 스폰서와 커뮤니티들의 라이트닝 토크는 402호 무대에서 진행됩니다.</p><br/>
      <p className="subPage_contents">튜토리얼이 진행되는 403호는 로비 입구 기준으로 가장 왼쪽에 있는 복도를 통해 진입하실 수 있습니다.</p><br/>
      <p className="subPage_contents"><a href="http://k-mice.visitkorea.or.kr/vr/sites/COX.kto?lang=ko#" target="_blanc">코엑스 360 사진첩</a>으로 자세한 사진을 확인하실 수 있습니다.</p>
      
      <br/>
      <p className="subPage_contents">
          <a href="#">🔼위로 가기</a></p>
      <h1 id="talks" className="subPage_title">발표 및 통역</h1>
      <p className="subPage_contents">발표는 401호에서 이루어집니다. 모든 발표는 영어와 한국어로 동시통역이 이루어지고, <a href="https://foundation.mozilla.org/en/" target="_blanc">모질라 재단</a>의 후원으로 실시간 자막도 제공됩니다. </p><br/>
      <p className="subPage_contents">강당 뒤쪽에서는 자막이 잘 안 보일 수 있습니다. 실시간 자막이 꼭 필요하신 분들은 미리 <a href="mailto:contact@jsconfkorea.com">contact@jsconfkorea.com</a>로 연락해주시면 컨퍼런스 당일 자막이 잘 보이는 자리로 안내해드리겠습니다.</p>

      <br/>
      <p className="subPage_contents">
          <a href="#">🔼위로 가기</a></p>
      <h1 id="noise" className="subPage_title">소음, 음향 볼륨, 조명</h1>
      <p className="subPage_contents">컨퍼런스중 401호에서 음악이 재생될 수 있으나, 눈부신 조명은 없을 예정입니다.</p><br/>
      <p className="subPage_contents">행사장 수용 인원이 거의 채워지므로 휴식 시간에는 많이 북적일 수 있습니다. 실내가 답답하거나 조용한 휴식이 필요하다면 언제든지 1층 야외에 있는 코엑스 정원을 이용할 수 있습니다.</p>

      <br/>
      <p className="subPage_contents">
          <a href="#">🔼위로 가기</a></p>
      <h1 id="children" className="subPage_title">어린이와 함께 오실 때</h1>
      <h2 className="subPage_subTitle">아이돌봄 서비스</h2>
      <div className="listing_block plan_list">
        <ul className="subPage_list">
          <li className="subPage_list_item">아이를 동반한 참석자를 위한 무료 아이돌봄 서비스 신청이 마감되었습니다.</li>
        </ul>
      </div>

      <h2 className="subPage_subTitle">수유실</h2>
      <p className="subPage_contents">같은 층은 아니지만 멀지 않은 곳에 스타필드몰 아기사랑방이 있습니다. 이곳에는 모유 수유를 위한 의자 및 기저귀 교체 시설이 갖춰져 있습니다.</p>
      <div className="listing_block notice_list">
        <ul className="subPage_list">
          <li className="subPage_list_item">위치: "UNDER $10 SHOP" 옆 통로</li>
          <li className="subPage_list_item">오시는 길: 컨퍼런스장 바깥 복도의 서쪽 끝에 있는 승강기를 타고 지하 1층으로 이동하여, 바로 좌측에 있는 비상문을 열고 직진하면 우측에 위치해있습니다.</li>
          <li className="subPage_list_item">연락처: 02-6002-5318</li>
          <li className="subPage_list_item">운영시간: 오전 10시 30분 ~ 오후 8시 </li>
        </ul>
      </div>
      <p className="subPage_contents">자세한 지도는 <a href="https://www.starfield.co.kr/coexmall/tenant/facility.do" target="_blanc">이곳</a>을 참고하세요.</p>

      <br/>
      <p className="subPage_contents">
          <a href="#">🔼위로 가기</a></p>
      <h1 id="mobility" className="subPage_title">휠체어 이용자 및 거동 보조가 필요한 분</h1>
      <p className="subPage_contents">강당의 모든 좌석에 팔받침이 있고, 휠체어를 위한 출입구와 좌석이 마련되어있습니다. 
      </p><br/>
      <p className="subPage_contents">행사장 내 모든 장소에 휠체어 및 거동 보조 기구로 이동이 가능합니다. 다만, 활동보조인 없이 오시는 경우, 코엑스 건물 입구에 자동문이 없어 출입이 어려우실 수 있으므로 <a href="mailto:contact@jsconfkorea.com">contact@jsconfkorea.com</a>으로 연락처와 함께 미리 알려주시면 코엑스 동문, 혹은 지하철역이나 주차장에서 컨퍼런스장까지 이동을 도와드리겠습니다.</p><br/>
      <h2 className="subPage_subTitle">휠체어 대여</h2>
      <p className="subPage_contents">코엑스 안전상황실에서 휠체어를 무료로 대여할 수 있습니다. 대여시에는 신분증이 필요합니다.</p>
      <div className="listing_block notice_list">
        <ul className="subPage_list">
          <li className="subPage_list_item">위치: 코엑스 1층 서문 옆</li>
          <li className="subPage_list_item">연락처: 02-6000-0112</li>
          <li className="subPage_list_item">운영시간: 오전 8시 30분 ~ 오후 6시</li>
        </ul>
      </div>

      <br/>
      <p className="subPage_contents">
          <a href="#">🔼위로 가기</a></p>
      <h1 id="support" className="subPage_title">활동 보조인과 함께 오실 때</h1>
      <p className="subPage_contents">활동 보조인이란 장애를 가진 분의 건강과 안전을 위해 일상생활에 필요한 활동을 지원하는 사람입니다. 예를 들어 다음과 같은 보조를 수행합니다.</p>
      <div className="listing_block notice_list">
        <ul className="subPage_list">
          <li className="subPage_list_item">수화 통역</li>
          <li className="subPage_list_item">길 안내</li>
          <li className="subPage_list_item">필요에 따라 휴식을 요청해주는 일</li>
          <li className="subPage_list_item">행사를 오가는 길 및 행사중 거동을 돕거나 정신적 지지를 해주는 일</li>
        </ul>
      </div>
      <p className="subPage_contents">참가자분이 활동 보조인을 필요로 하신다면 활동 보조인은 무료로 입장하실 수 있습니다. 다만 티켓 구매시 해당 옵션을 꼭 체크해주세요.</p>

      <br/>
      <p className="subPage_contents">
          <a href="#">🔼위로 가기</a></p>
      <h1 id="food" className="subPage_title">음식</h1>
      <p className="subPage_contents">코엑스몰 일부 식당을 이용하실 수 있는 점심 식권과 생수를 제공해드립니다.</p><br/>
      <p className="subPage_contents">강당을 제외한 행사장 내 모든 공간에서는 자유롭게 음식을 드실 수 있습니다.</p>

      <br/>
      <p className="subPage_contents">
          <a href="#">🔼위로 가기</a></p>
      <h1 id="bathrooms" className="subPage_title">화장실</h1>
      <p className="subPage_contents">행사장 로비내에 휠체어로 접근 가능한 화장실이 마련되어 있습니다. 휠체어 전용 화장실은 남녀 화장실에 각각 1칸씩 마련되어 있으며, 독립된 세면대가 설치되어 있습니다. 4층 행사장 외부에는 화장실이 마련되어 있지 않습니다. </p><br/>
      <p className="subPage_contents">휠체어 접근이 가능한 성 중립 1인용 화장실은 다소 멀리 3층 컨퍼런스룸 E 북쪽에 마련되어있습니다. 컨퍼런스장에서 가장 가까운 승강기나 에스컬레이터를 타고 3층으로 내려와 바로 우회전 한 뒤, 컨퍼런스룸 E를 향해 직진합니다. 컨퍼런스룸 E가 나오면 이를 좌측에 두고 그 둘레를 따라 걷습니다. 한 번 좌회전 후 직진하시면 복도 끝에 화장실이 있습니다.</p>

      <br/>
      <p className="subPage_contents">
          <a href="#">🔼위로 가기</a></p>
      <h1 id="prayerroom" className="subPage_title">기도실</h1>
      <p className="subPage_contents">코엑스 3층의 컨퍼런스룸 E 남쪽에 기도실이 마련되어있습니다. 기도실은 여성실과 남성실로 나누어져있고 행사 시간 내내 열려있습니다.</p>
      <div className="listing_block notice_list">
        <ul className="subPage_list">
          <li className="subPage_list_item">위치: 코엑스 3층 컨퍼런스룸 E 남쪽</li>
          <li className="subPage_list_item">오시는 길: 컨퍼런스장에서 가장 가까운 승강기나 에스컬레이터를 타고 3층으로 내려와 바로 우회전하여 직진한 뒤, 컨퍼런스룸E가 나오기 전 다시 한 번 우측으로 꺾으면 기도실이 있습니다.</li>
          <li className="subPage_list_item">운영시간: 오전 3시 ~ 오후 10시</li>
          <li className="subPage_list_item">비치품목: 키블라(Qibla), 나침반, 시계, 코란, 예배카페트, 수납장</li>
        </ul>
      </div>

      <br/>
      <p className="subPage_contents">
          <a href="#">🔼위로 가기</a></p>
      <h1 id="health" className="subPage_title">안전과 건강</h1>
      <h2 className="subPage_subTitle">위급상황</h2>
      <p className="subPage_contents">참가자분의 안전이 위협되거나 위급한 상황이 있다면 스태프에게 알려주시거나 다음 전화번호로 연락하세요.</p>
      <div className="listing_block beware_list">
        <ul className="subPage_list">
          <li className="subPage_list_item with_children">경찰: 112 (잠금해제나 SIM 카드 없이 연결 가능)
            <ul className="subPage_list ">
              <li className="subPage_list_item">삼성1파출소, <a href="https://goo.gl/maps/dAMBBQSAuHv" target="_blanc">서울 강남구 영동대로112길 4</a></li>
            </ul>
          </li>
          <li className="subPage_list_item">구급차: 119 (잠금해제나 SIM 카드 없이 연결 가능)</li>
          <li className="subPage_list_item">코엑스 안전상황실: 02-6000-0112</li>
        </ul>
      </div>
      <h2 className="subPage_subTitle">구급약품 및 응급처치</h2>
      <p className="subPage_contents">컨퍼런스 현장에 구급약품을 구비해둘 예정입니다. 단순 부상이나 통증이 있으실 경우에는 스태프를 통해 약을 부탁하실 수 있습니다. </p><br/>
      <p className="subPage_contents">응급처치가 필요하신 경우 스태프에게 문의하시거나 코엑스 1층 의무실을 이용하시기 바랍니다.</p>
      <h3 className="subPage_sectionTitle">코엑스 의무실</h3>
      <div className="listing_block notice_list">
        <ul className="subPage_list">
          <li className="subPage_list_item">오시는 길: 컨퍼런스장에 가장 가까이 위치한 승강기를 타고 1층으로 내려옵니다. 우측에 있는 넓은 복도를 따라 코엑스 서문을 향해 직진하다보면 서문 바로 왼쪽에 의무실이 있습니다.</li>
          <li className="subPage_list_item">전화: 02-6000-1119</li>
          <li className="subPage_list_item">운영시간: 오전 10시~ 오후 6시 30분</li>
        </ul>
      </div><br/>
      <p className="subPage_contents">구비된 구급약품 외의 약품이 필요하신 경우 가까운 약국에 방문하시거나 스태프에게 도움을 요청하시기 바랍니다.</p>
      <h3 className="subPage_sectionTitle">팜엑스약국</h3>
      <div className="listing_block notice_list">
        <ul className="subPage_list">
          <li className="subPage_list_item">오시는 길: 컨퍼런스장 바깥 복도의 서쪽 끝에 위치한 승강기를 타고 지하 1층으로 내려옵니다. 스타필드몰로 나와 우측의 스닉솔 매장을 끼고 우회전 한 후 직진합니다. 우체국이 나오면 좌회전하여 조금 걸으면 왼쪽에 위치해있습니다.</li>
          <li className="subPage_list_item">전화: 02-6002-3060</li>
          <li className="subPage_list_item">주소: 서울 강남구 삼성동 159 코엑스몰 H112</li>
        </ul>
      </div>
      <br/>
      <p className="subPage_contents">
          <a href="#">🔼위로 가기</a></p>
      <h1 id="from_coex" className="subPage_title">코엑스에서 오시는 길</h1>
      <p className="subPage_contents">스타필드 코엑스 몰에서 오시는 경우, 자라(ZARA) 매장 건너편 에스컬레이터나 승강기를 타고 4층으로 올라옵니다.</p><br/>
      <p className="subPage_contents">어떤 승강기나 에스컬레이터를 타든 "컨퍼런스룸(남)"이라고 써있는지 확인해주세요.</p>
      <h1 id="subway" className="subPage_title">지하철로 오시는 길</h1>
      <p className="subPage_contents">활동보조인 없이 휠체어 또는 거동 보조기구를 타고 오시는 경우 <a href="mailto:contact@jsconfkorea.com">contact@jsconfkorea.com</a>으로 연락처와 함께 미리 알려주시면 지하철역, 버스정류장, 주차장에서 컨퍼런스장까지 이동을 도와드리겠습니다.</p>

      <h2 className="subPage_subTitle">2호선 삼성역에서 오실 때</h2>
      
      <div className="listing_block notice_list">
        <ul className="subPage_list">
          <li className="subPage_list_item">5, 6번 출구를 따라 이동합니다.</li>
          <li className="subPage_list_item with_children">휠체어를 사용하신다면,
            <ul className="subPage_list ">
              <li className="subPage_list_item">5, 6번 출구 사이 계단에 휠체어 리프트가 설치되어 있습니다. 안전을 위해 리프트 이용시 삼성역 고객 안내센터의 도움을 요청해야합니다. (삼성역 고객안내센터: 02-6110-2191)</li>
              <li className="subPage_list_item">6번 출구 계단 좌측에 위치한 승강기를 이용합니다.</li>
            </ul>
          </li>
          <li className="subPage_list_item">삼성역 6번 출구 혹은 승강기로 나와 큰 전광판을 좌측에 두고 270m 직진합니다. </li>
          <li className="subPage_list_item">좌측 코엑스 동문으로 진입합니다.</li>
          <li className="subPage_list_item">입구에서 정면 좌측에 보이는 안내데스크와 에스컬레이터 하나를 지나 좌회전 하면 승강기가 있습니다.</li>
          <li className="subPage_list_item">승강기를 타고 4층으로 올라오신 후 복도로 나와 우회전 후 직진하시면 우측에  401-403호 컨퍼런스장을 찾으실 수 있습니다. </li>
        </ul>
      </div><br/>
      <h3 className="subPage_sectionTitle">교통약자를 위한 정보</h3>
      <div className="listing_block notice_list">
        <ul className="subPage_list">
          <li className="subPage_list_item">2호선 휠체어 및 거동 보조 기구 전용공간이 갖춰진 전동차: 1-4, 4-4, 7-1, 10-1 스크린 도어</li>
          <li className="subPage_list_item">삼성역 교통약자 전용 승강기: 선릉방향 8-3, 종합운동장방향 3-1 스크린 도어 앞</li>
        </ul>
      </div>
      <h2 className="subPage_subTitle">9호선 봉은사역에서 오실 때</h2>
      <div className="listing_block notice_list">
        <ul className="subPage_list">
          <li className="subPage_list_item with_children">봉은사역 7번 출구로 나와 좌측으로 꺾은 뒤 280m 가량 이동합니다. 
            <ul className="subPage_list ">
              <li className="subPage_list_item">휠체어를 사용하신다면 7번 출구 계단 좌측에 위치한 승강기를 이용하실 수 있습니다. 승강기에서 내린 후 좌측 보도를 따라 이동합니다.</li>
            </ul>
          </li>
          <li className="subPage_list_item">우측에 위치한 코엑스 동문으로 진입합니다.</li>
          <li className="subPage_list_item">입구에서 정면 좌측에 보이는 안내데스크와 에스컬레이터 하나를 지나 좌회전 하면 승강기가 있습니다.</li>
          <li className="subPage_list_item">승강기를 타고 4층으로 올라오신 후 복도로 나와 우회전 후 직진하시면 우측에  401-403호 컨퍼런스장을 찾으실 수 있습니다. </li>
        </ul>
      </div><br/>
      <h3 className="subPage_sectionTitle">교통약자를 위한 정보</h3>
      <div className="listing_block notice_list">
        <ul className="subPage_list">
          <li className="subPage_list_item with_children"> 9호선 휠체어 및 거동 보조 기구사용자 전용공간이 갖춰진 전동차
            <ul className="subPage_list ">
              <li className="subPage_list_item">4량 열차: 2-4, 5-1 스크린 도어</li>
              <li className="subPage_list_item">6량 열차: 1-4, 6-1 스크린 도어</li>
              <li className="subPage_list_item">봉은사역 교통약자 전용 승강기: 양방향 3-4 스크린 도어 앞</li>
            </ul>
          </li>
        </ul>
      </div>
      <br/>
      <p className="subPage_contents">코엑스 스타필드몰, 무역센터 등 지하를 통해서도 곧바로 행사장으로 이동하실 수 있습니다. 보다 자세한 사항은 코엑스의 <a href="http://www.coex.co.kr/services/traffic/location-1?type=organizer" target="_blanc">교통 안내 페이지</a>를 참고해주시기 바랍니다.</p>

      <h1 id="car" className="subPage_title">차로 오시는 길</h1>
      <p className="subPage_contents">코엑스 내 주차공간이 제한되어 최대한 대중교통을 이용해주시기 바랍니다. 거동이 불편하여 주차 공간이 필요하신 분은 <a href="mailto:contact@jsconfkorea.com">contact@jsconfkorea.com</a>으로 문의주시면 안내해드리겠습니다..</p>
      
      <h2 className="subPage_subTitle">코엑스 주차장</h2>
      <p className="subPage_contents">코엑스에 주차를 원하시는 경우 코엑스 주차장 B2층의 주차구역 C, D에 주차하시면 행사장까지 쉽게 오실 수 있습니다. 엘리베이터는 2, 3, 4, 5번 입구를 이용하시면 됩니다.</p>

      <div className="listing_block notice_list">
        <ul className="subPage_list">
          <li className="subPage_list_item">주소: 서울 강남구 봉은사로 524 (<a href="https://place.map.kakao.com/618406749" target="_blanc">지도</a>)</li>
          <li className="subPage_list_item with_children">요금: 1시간당 6,000원, 1일 주차시 48,000원
            <ul className="subPage_list ">
              <li className="subPage_list_item">장애인과 국가유공자는 50% 할인됩니다.</li>
              <li className="subPage_list_item">코엑스몰에서 5만원씩 구매하실 때마다 1시간씩 무료입니다. (최대 3시간 무료)</li>
            </ul>
          </li>
        </ul>
      </div>
      <p className="subPage_contents">더 자세한 사항은 코엑스의 <a href="http://www.coex.co.kr/services/traffic/parking-1?type=organizer" target="_blanc">주차 안내 페이지</a>를 참고해주세요.</p>

      <h2 className="subPage_subTitle">강남 탄천주차장</h2>
      <p className="subPage_contents">도보 15분거리의 강남 탄천 주차장을 이용하실 수 있습니다.</p>

      <div className="listing_block notice_list">
        <ul className="subPage_list">
          <li className="subPage_list_item">주소: 강남구 삼성동 171번지 (<a href="http://kko.to/OuPbLmCTB" target="_blanc">지도</a>)</li>
          <li className="subPage_list_item">전화: 02-2176-0900</li>
          <li className="subPage_list_item">코엑스로 <a href="http://kko.to/N-Lml4mTM" target="_blanc">걸어오시는 길</a></li>
          <li className="subPage_list_item">요금: 1시간당 1200원 (분당 500원)</li>
        </ul>
      </div>
      <p className="subPage_contents">더 자세한 안내는 코엑스의 <a href="http://www.coex.co.kr/services/traffic/parking-5?type=visitor" target="_blanc">주차장 안내</a>를 참고해주시기 바랍니다.</p>

      <h1 id="contact" className="subPage_title">문의</h1>
      <p className="subPage_contents">이 밖에도 컨퍼런스 접근성에 관련된 질문이나 요청사항이 있다면 <a href="mailto:contact@jsconfkorea.com">contact@jsconfkorea.com</a>으로 문의해 주세요.</p>
      <br/>
      <br/>
      <br/>
      <p className="subPage_contents">
          <a href="#">🔼위로 가기</a></p>
    </section>
  </PageWrapper>
);

export default Accessibility