import React from "react"
import PageWrapper from "../../components/wrapper/pageWrapper"
import ShowCase from "../../components/showcase"

const Ticket = () => (
  <PageWrapper locale="en" location="/ticket">
    <ShowCase type="ticket"/>
    <section className="subPage_contents_wrapper">
    <h1 className="subPage_title">Tickets</h1>
    <p className="subPage_contents">Conference tickets are now SOLD OUT! Thank you for the overwhelming support; we can't wait to see you all in September.</p><br/><br/>

    <a href="https://ti.to/jsconf-korea/2019/" target="_blanc" className="button_default button_default_ticket"><s>Buy with a NON-KOREAN payment option</s></a>

    <br/>
    <a href="https://event-us.kr/jsconf-korea/event/8739" target="_blanc" className="button_default button_default_ticket"><s>Buy with a KOREAN payment option</s></a>


    <h1 className="subPage_title">FAQ</h1>
    <br/>
    <h2 className="subPage_subTitle"> Q. How do I get a refund?</h2>
    <br/>
    <p className="subPage_contents">Unfortunately, all tickets are <b>non-refundable</b>, but you can transfer your ticket to someone else.</p>
    <br/>
    <h2 className="subPage_subTitle">Q. Can I transfer my ticket(s) to someone else?</h2>
    <br/>
    <p className="subPage_contents">Yes, all tickets are transferable. If you purchased your ticket through Tito (with non-Korean payment option), within the confirmation email titled “Your JSConf Korea 2019 Ticket”, click the “CHANGE DETAILS” button. This will lead you to your ticket’s details page. Click “Re-assign” and enter the full name and email address of the transferee.</p>
    <br/>
    <h2 className="subPage_subTitle">Q. What is an "After-party" ticket?</h2>
    <br/>
    <p className="subPage_contents">An After-party Ticket is for the after-party that will happen after the conference. </p>
    <br/>
    <h2 className="subPage_subTitle">Q. What is "Scholarship Support" and how does it work?</h2>
    <br/>
    <p className="subPage_contents">If you purchase one of the Scholarship Support options, you will be helping someone from our scholarship program to attend. More information coming soon. </p>
    <br/>
    <h2 className="subPage_subTitle">Q. What’s the schedule for the conference?</h2>
    
    <div className="listing_block notice_list">
        <ul className="subPage_list">
          <li className="subPage_list_item">Registrations are from 9 am until 10 am.</li>
          <li className="subPage_list_item">The main event is from 10 am until 6 pm.</li>
        </ul>
    </div>
    <br/>

    <p className="subPage_contents">For further questions, contact us at <a href="mailto:ticket@jsconfkorea.com">ticket@jsconfkorea.com</a>.</p>
    <br/>
    </section>
  </PageWrapper>
)

export default Ticket
