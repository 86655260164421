import PropTypes from "prop-types"
import React from "react"

const SpeakerLinks = ({ links, isDetail }) => {
    const socail_links = isDetail ? links : [links[0]];
    const social_line = () => {
        return (
            socail_links.map((link) => {
                const { id, url, type, name } = link;
                return (
                <div className="speaker_link_container" key={id}>
                    {isDetail && <img src={`/speaker_icon_${type}.svg`} className="speaker_link_logo" alt={type}></img> }
                    <a className="speaker_link" href={url} target="_blank" rel="noopener noreferrer" aria-label={ type }>{isDetail ? id : name }</a> 
                </div>
                );
            })
        );
    }
    return (
        social_line()
    ) 
};

SpeakerLinks.propTypes = {
    links: PropTypes.array,
    isDetail: PropTypes.bool
}

export default SpeakerLinks