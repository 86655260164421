export default [
    {
        id: "eun",
        name: "조은",
        image: "profile_eun.png",
        desc: "기술에 열정을 가지고 웹으로 세상을 바꾸고 있습니다. 웹 기술 분야의 Google Developer Expert이며, 사진가이자, 번역가이자, 여행자입니다.",
        links: [
            {
                id: "@apes01234",
                url: "https://twitter.com/apes01234"
            },
            {
                id: "website",
                url: "https://techhtml.github.io"
            }
        ]
    }
]