import React from "react"
import PageWrapper from '../../../components/wrapper/pageWrapper'
import ShowCase from "../../../components/showcase"

const BagelCode = () => (
    <PageWrapper locale="en" location="/sponsors/bagelcode">
        <ShowCase type="bagelcode"/>
        <section className="subPage_contents_wrapper">
            <div className="sponsor_list sponsor_list_detail">
                <div className="sponsor_item">
                    <img className={`sponsor_img bagelcode`} alt="bagelcode" src={`/sponsors/logo_bagelcode.svg`}/>
                </div>
            </div>
            <p className="subPage_contents">Bagelcode was established in 2012 in the heart of Seoul by a team of passionate industry veterans, all with a broad range of experience in creating games across all leading gaming platforms. <br/>
            Today, we have hundreds of partners working together around the world, including offices in London and Tel Aviv.<br/>
            We have a total of more than 3.3 million players and consistently rank among the top 20 casino games in the App Store.</p>
        </section>
    </PageWrapper>
)

export default BagelCode