import React from "react"
import PageWrapper from '../components/wrapper/pageWrapper'
import ShowCase from "../components/showcase"
import SeoulInfo from "../components/seoul"


const Seoul = () => (
    <PageWrapper locale="en" location="/seoul">
        <ShowCase type="seoul"/>
        <section className="subPage_contents_wrapper">
            <br/>
            <br/>
            <SeoulInfo/>
            <br/>
        </section>
    </PageWrapper>
)

export default Seoul