import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"

const ScheduleList = ({ schedule, roomTypes }) => {
    const refinedSchedule = schedule.filter((item => {
        const { type } = item;
        return roomTypes[`is${type}`] !== false;
    }))
    return (
        refinedSchedule.map((item) => {
            const { time, talk, room, speaker, link, type, id } = item;
            return (
                <div className="schdule_item" key={id}>
                    <div className="schedule_time_room">
                        <p className="schedule_time">{time}</p>
                        <p className={`schedule_room room_${type}`}>{room}</p>
                    </div>
                    <div className="schedule_talk_speaker">
                        <p className="schedule_time">{time}</p>
                        <p className="schedule_talk">{talk}</p>
                        { link && <Link className="schedule_speaker" to={link}>{speaker}</Link> }
                        <div className="schedule_time_room">
                            <p className={`schedule_room room_${type}`}>{room}</p>
                        </div>
                    </div>
                </div>
            );
        })
    );
}

ScheduleList.propTypes = {
    roomTypes: PropTypes.object,
    schedule: PropTypes.array
}

export default ScheduleList;