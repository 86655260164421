import React from "react"
import PageWrapper from '../../components/wrapper/pageWrapper'
import ShowCase from "../../components/showcase"

const Lunit = () => (
    <PageWrapper locale="ko" location="/sponsors/lunit">
        <ShowCase type="lunit"/>
        <section className="subPage_contents_wrapper">
            <div className="sponsor_list sponsor_list_detail">
                <div className="sponsor_item">
                    <img className={`sponsor_img lunit`} alt="lunit" src={`/sponsors/logo_lunit.svg`}/>
                </div>
            </div>
            <p className="subPage_contents">
            루닛은 인공지능 기반의 암 진단 및 치료 솔루션을 개발하는 의료 AI 소프트웨어 회사입니다. 루닛의 인공지능 솔루션은 처음 암을 발견하는 진단 단계서부터 바이오마커를 통한 치료 반응 예측까지의 전 과정을 돕습니다. 루닛은 인공지능을 활용해 의료비를 줄이고 생존율을 높이는 미래를 이뤄나갑니다.            </p>
        </section>
    </PageWrapper>
)

export default Lunit