import React from "react"
import PageWrapper from '../../../components/wrapper/pageWrapper'
import ShowCase from "../../../components/showcase"

const Line = () => (
    <PageWrapper locale="en" location="/sponsors/line">
        <ShowCase type="line"/>  
        <section className="subPage_contents_wrapper">
            <div className="sponsor_list sponsor_list_detail">
                <div className="sponsor_item">
                    <img className={`sponsor_img line`} alt="elastic" src={`/sponsors/logo_line.svg`}/>
                </div>
            </div>
            <p className="subPage_contents">Based in Japan, LINE Corporation is dedicated to the mission of “Closing the Distance,” bringing together information, services and people. The LINE messaging app launched in June 2011 and since then has grown into a diverse, global ecosystem that includes AI technology, Fintech and more.</p>
        </section>
    </PageWrapper>
)

export default Line