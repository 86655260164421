import React from "react"
import PageWrapper from '../../../components/wrapper/pageWrapper'
import ShowCase from "../../../components/showcase"

const Hyperconnect = () => (
    <PageWrapper locale="en" location="/sponsors/hyperconnect">
        <ShowCase type="hyperconnect"/>
        <section className="subPage_contents_wrapper">
            <div className="sponsor_list sponsor_list_detail">
                <div className="sponsor_item">
                    <img className={`sponsor_img hyperconnect`} alt="hyperconnect" src={`/sponsors/logo_hyperconnect.svg`}/>
                </div>
            </div>
            <p className="subPage_contents">
            Hyperconnect is a technology company based in Seoul, South Korea, that provides video and AI-powered products, aiming to generate cultural and social values by connecting people globally. <br/>
            With its flagship product, Azar, the company has pioneered a new era of social discovery through global video communications. As the world's first mobile app using WebRTC technology, Azar is revolutionizing the way people make new friends and experience new cultures using Hyperconnect's machine learning-powered technology. Now, it has grown into one of the largest video communication platforms in the world, generating over 300 million downloads and 60 billion new connections. <br/>
            Hyperconnect is an exciting and fast-growing Korean IT company, which has built an extensive growth of global user base of hundreds of millions from all around the world. With its strong team of 240+ employees from twenty nationalities, the company generates over 90% of its revenue in the global market outside of Korea. In 2018, the company marked its record-sales of 104.5 billion won in revenue and was ranked 9th place in the top grossing companies of all-time Google Play consumer spend.
            </p>
        </section>
    </PageWrapper>
)

export default Hyperconnect