export default class Donut {
    constructor(lineColor, p) {
        this.color = lineColor;
        this.points = [];
        this.p = p;
    }

    display() {
        this.color.setAlpha(this.points[0].life);
        this.p.stroke(this.color);
        this.p.noFill();
        const pointsLength = this.points.length;
        for(let i=0; i<pointsLength; i++){
            let j = i+1;
            if(i===pointsLength-1) j = 0;
            if(i%2 === 0)
            this.p.line(
            this.points[i].pos.x,this.points[i].pos.y,
            this.points[j].pos.x,this.points[j].pos.y
            );
        }
    }
}