export default {
    title: "Food",
    data: [
        {
            id: "vegetarian/vegan",
            items: [
                {
                    id: "food1",
                    desc: `It is surprisingly hard to find vegetarian and vegan food in Seoul but it is not impossible. Temple food is usually vegetarian, if not vegan, and delicious. For a good but a slightly pricey meal, check out <a href="http://eng.balwoo.or.kr/" target="_blanc">Balwoo Gongyang</a>. (Not sure if vegan)`
                },
                {
                    id: "food2",
                    desc: `For a dessert option, <a href="https://1964baekmidang.com/en/menu/homemade-soy-milk-ice-cream/" target="_blanc">Baekmidang’s soy milk ice cream</a> is delicious.`
                },
                {
                    id: "food3",
                    desc: `<a href="https://www.happycow.net/best-vegan-restaurants/seoul-south-korea" target="_blanc">Top 10 Vegan / Vegetarian Restaurants</a>`
                },
            ]
        },
        {
            id: "famous food",
            items: [
                {
                    id: "food1",
                    desc: `Korea is famous for a lot of different food nowadays. If you need any recommendations for korean fried chicken, Kimbab, tteokbokgi, kbbq or anything else, ask us and we can help you out!`
                }
            ]
        }
    ]
}