export const header = {
    about: "행사소개",
    speakers: "발표자",
    tutorials: "튜토리얼",
    scholarships: "장학제도",
    coc: "핵심원칙",
    accessibility: "접근성",
    sponsors: "후원",
    tickets: "티켓",
    schedule: "시간표",
    close: "닫기",
    more: "더보기"
}

export const showcase = {
    type: "showcase",
    copy: "2019년 9월 3일 화요일\n서울시 강남구 삼성역\n코엑스 4층 컨퍼런스룸\n",
    buttonText: "발표 구경하기!"
}

export const schedule = {
    type: "schedule",
    copy: "JSConf Korea 2019 시간표 🎉 ",
    room_401: "401호 메인 발표",
    room_402: "402호 라이트닝 토크",
    room_403: "403호 튜토리얼"
}

export const about = {
    type: "about",
    title: "ABOUT US",
    copy: "JSConf Korea에 오신 것을 환영합니다!",
    desc: "JSConf Korea는 자바스크립트 커뮤니티의 국내외 구성원과 함께 기술의 한계를 뛰어 넘고 긍정적인 에너지를 주고받는 축제의 장입니다. 우리는 보다 대담하고, 다양하며, 인간적인, 누구나 환영받는 기술 커뮤니티를 꿈꿉니다.\n\nJSConf Korea는 세계 각국에서 열리는 JSConf와 한 가족으로서 2019년 그 첫 행사에 여러분을 환영합니다.\n\n",
    buttonText: "더 알아보기",
    link: "/about"
}

export const speaker = {
    type: "speaker",
    copy: "JSConf Korea 2019를 빛낼 발표자분들",
    //desc: "",
    title: "SPEAKERS"
}

export const tutorial = {
    type: "tutorial",
    copy: "튜토리얼을 소개합니다!",
    //desc: "",
    title: "TUTORIALS"
}

export const coc = {
    type: "coc",
    title: "CODE OF CONDUCT",
    copy: "모두가 존중받는 컨퍼런스를 추구합니다.",
    desc: "JSConf Korea의 핵심 가치는 다양성의 존중입니다. 따라서 성별, 성 정체성 및 표현 방법, 성적 지향, 장애, 외모, 신체 사이즈, 인종, 연령, 종교, 출신 지역, 학력, 경력, 그리고 사용 기술과 상관 없이 모두가 존중받는 컨퍼런스 경험을 제공하기 위해 컨퍼런스 핵심원칙을 규정했습니다. \n\n우리가 서로에게 기대하는 바에 대해 핵심원칙에서 알아보세요!\n",
    buttonText: "더 알아보기",
    link: "/coc"
}

export const ticket = {
    type: "ticket",
    title: "TICKETS",
    copy: "티켓이 매진되었습니다.",
    desc: "JSConf Korea 2019를 향한 여러분의 관심과 성원에 감사드립니다.",
    buttonText: "더 알아보기",
    link: "/ticket"
}

export const subscribe = {
    type: "subscribe",
    title: "NEWSLETTER",
    copy: "새로운 소식을 놓치지 마세요.",
    desc: "뉴스레터를 통해 JSConf Korea의 새소식을 가장 먼저 받아보세요.\n티켓, 발표, 장학제도 등 다양한 소식을 전해드립니다.",
    buttonText: "구독하기",
    link: ""
}

export const sponsor = {
    type: "sponsor",
    copy: "JSConf Korea를 후원해주신 분들"
}

export const seoul = {
    type: "seoul",
    copy: "Places to enjoy when you’re in Seoul for JSConf Korea."
}

export const scholarships = {
    type: "scholarships",
    copy: "장학 제도를 소개합니다!",
    title: "장학제도",
    desc: "더 많은 분들이 JSConf Korea 2019에 함께하실 수 있도록 장학 제도를 준비했습니다.",
    buttonText: "더 알아보기",
    link: "/scholarships"
}

export const footer = {
    twitter: "트위터",
    facebook: "페이스북",
    accessibility: "접근성",
    scholarships: "장학제도",
    coc: "핵심원칙",
    faq: "FAQ",
    contact: "문의:"
}

export const cfp = {
    type: "call-for-proposals",
    copy: "발표자 모집",
    link: "/call-for-proposals",
}

export const accessibility = {
    type: "accessibility",
    copy: "참가자 모두를 환영합니다.",
    reference: "출처: 코엑스 회의실 시설 안내 (http://www.coex.co.kr/blog/coex-facility-info/conference_south_4f)"
}

export const faq = {
    type: "faq",
    title: "FAQ",
    copy: "자주 묻는 질문",
    link: "/faq",
}

export const elastic = {
    type: "elastic",
    copy: "Elastic"
}

export const nexmo = {
    type: "nexmo",
    copy: "Nexmo"
}

export const line = {
    type: "line",
    copy: "Line"
}

export const bagelcode = {
    type: "bagelcode",
    copy: "베이글코드"
}

export const hyperconnect = {
    type: "hyperconnect",
    copy: "Hyperconnect"
}

export const jetbrains = {
    type: "jetbrains",
    copy: "JetBrains"
}

export const lunit = {
    type: "lunit",
    copy: "Lunit"
}

export const woowahan = {
    type: "woowahan",
    copy: "우아한형제들"
}

export default { about, coc, ticket, subscribe, sponsor, showcase, scholarships, header, footer, accessibility, faq, seoul, speaker, tutorial, schedule, cfp, elastic, nexmo, line, bagelcode, hyperconnect, jetbrains, lunit, woowahan }
