import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"
import classnames from "classnames"
import { injectIntl } from "react-intl"

export class Header extends Component {
  constructor() {
    super();
    this._handleClickCloseBtn = this._handleClickCloseBtn.bind(this);
    this._handleClickMoreBtn = this._handleClickMoreBtn.bind(this);
  }
  
  render() {
    const { locale, intl, location } = this.props;
    const loc = locale === "ko" ? `/` : `/${locale}/`;
    const buttonWrapperClass = classnames({
      "button_language": true,
      "is_KR_activated": locale === "ko"
    });

    return (
        <header className="section_header">
          <div className="header_contents_wrapper">
            <Link to={loc}>
              <div className="jsconf_logo_2019"><span className="blind">JSCONF KOREA 2019</span></div>
            </Link>
            <div className="header_button_set" ref={ (el) => this._btnSet = el }> 
              <span className={buttonWrapperClass}>
                <Link className="language" to={`${location}`}> KR </Link> / <Link className="language" to={`/en${location}`}> EN </Link></span>
              <nav className="section_navigator" onClick={this._handleClickCloseBtn}>
                <Link to={ `${loc}about`} className="nav_item" activeClassName="is_activated"><span className="nav_text">{ intl.formatMessage({ id: "header.about"})}</span></Link>
                <Link to={ `${loc}schedule`} className="nav_item" activeClassName="is_activated"><span className="nav_text">{ intl.formatMessage({ id: "header.schedule"})}</span></Link>
                <Link to={ `${loc}speakers`} className="nav_item" activeClassName="is_activated"><span className="nav_text">{ intl.formatMessage({ id: "header.speakers"})}</span></Link>
                <Link to={ `${loc}tutorials`} className="nav_item" activeClassName="is_activated"><span className="nav_text">{ intl.formatMessage({ id: "header.tutorials"})}</span></Link>
                <Link to={ `${loc}sponsors`} className="nav_item" activeClassName="is_activated"><span className="nav_text">{ intl.formatMessage({ id: "header.sponsors"})}</span></Link>
              </nav>
              <button className="button_close" onClick={ this._handleClickCloseBtn }><span className="blind">{ intl.formatMessage({ id: "header.close"})}</span></button>
            </div>
            <button
              className="button_more"
              onClick={ this._handleClickMoreBtn }
              ref={ (el) => this._moreBtn = el }>
              <span className="blind">{ intl.formatMessage({ id: "header.more"})}</span>
            </button>
          </div>
        </header>
      )     
  }

  _handleClickMoreBtn() {
    const classList = this._btnSet.classList;

    if (!classList.contains("is_activated")) {
      classList.add("is_activated");
    }
  }

  _handleClickCloseBtn() {
    const classList = this._btnSet.classList;

    if (classList.contains("is_activated")) {
      classList.remove("is_activated");
      this._moreBtn.focus();
    }
  }     
}

Header.propTypes = {
  locale: PropTypes.string,
  intl: PropTypes.object,
  location: PropTypes.string
}

export default injectIntl(Header)
