import PropTypes from "prop-types"
import React, { useState } from "react"
import ScheduleList from "./scheduleList";
import { injectIntl } from "react-intl"

const Schedule = ({ schedule, intl }) => {
    const [ is401, set401 ] = useState(true);
    const [ is402, set402 ] = useState(true);
    const [ is403, set403 ] = useState(true);

    const onChangeRoomType = (e) => {
        const roomId = e.target.id
        if(roomId === "401") {
            set401(!is401)
        }else if(roomId === "402") {
            set402(!is402)
        }else if(roomId === "403") {
            set403(!is403)
        }
    }

    return (
        <>
            <div className="schedule_room_wrapper">
                <div className="schedule_room_btn" role="button">
                    <input type="checkbox" id="401" name="401" defaultChecked={is401} onChange={onChangeRoomType}/>
                    <label htmlFor="401"><span className="schedule_room room_401">{ intl.formatMessage({ id: "schedule.room_401"})}</span></label>
                </div>
                <div className="schedule_room_btn" role="button">
                    <input type="checkbox" id="402" name="402" defaultChecked={is402} onChange={onChangeRoomType}/>
                    <label htmlFor="402"><span className="schedule_room room_402">{ intl.formatMessage({ id: "schedule.room_402"})}</span></label>
                </div>
                <div className="schedule_room_btn"  role="button">
                    <input type="checkbox" id="403" name="403" defaultChecked={is403} onChange={onChangeRoomType}/>
                    <label htmlFor="403"><span className="schedule_room room_403">{ intl.formatMessage({ id: "schedule.room_403"})}</span></label>
                </div>
            </div>
            <ScheduleList schedule={schedule} roomTypes={ { is401, is402, is403 }}/>
        </>
    );
}

Schedule.propTypes = {
    schedule: PropTypes.array,
    intl: PropTypes.object
}

export default injectIntl(Schedule);