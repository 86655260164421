import React from "react"
import PageWrapper from '../../../components/wrapper/pageWrapper'
import ShowCase from "../../../components/showcase"

const Lunit = () => (
    <PageWrapper locale="en" location="/sponsors/lunit">
        <ShowCase type="lunit"/>
        <section className="subPage_contents_wrapper">
            <div className="sponsor_list sponsor_list_detail">
                <div className="sponsor_item">
                    <img className={`sponsor_img lunit`} alt="lunit" src={`/sponsors/logo_lunit.svg`}/>
                </div>
            </div>
            <p className="subPage_contents">
            Lunit is a medical AI software company devoted to providing AI-powered total cancer care. Lunit AI solutions help discover cancer and predict cancer treatment response, achieving timely and individually-tailored cancer treatment. With the help of AI, Lunit seeks to reduce medical cost and prolong survival.
            </p>
        </section>
    </PageWrapper>
)

export default Lunit