export default {
    title: "Shopping",
    data: [
        {
            id: "",
            items: [
                {
                    id: "shopping1",
                    desc: `If you want to do some shopping, Seoul has many many malls and shopping districts. For luxury shopping, check out <a href="https://www.lwt.co.kr/en/main.do" target="_blanc">Lotte World Mall</a> in Jamsil. For a more inexpensive option, the underground mall in the Express Bus Terminal has pretty much everything.`
                },
                {
                    id: "shopping2",
                    desc: `For k-pop goods, check out COEX Artium SMTOWN next to the Coex Mall. There are also many shops in the Myeongdong Underground Mall as well.`
                }
            ]
        }
    ]
}