import React from "react"
import PageWrapper from "../components/wrapper/pageWrapper"
import ShowCase from "../components/showcase"

const FAQ = () => (
    <PageWrapper locale="ko" location="/faq">
        <ShowCase type="faq" />
        <section className="subPage_contents_wrapper">
            <h1 className="subPage_title"></h1>
            <div className="listing_block">
                <ul className="subPage_list">
                    <li className="subPage_list_item"><a href="#tickets">티켓</a></li>
                    <li className="subPage_list_item"><a href="#schedule">일정</a></li>
                    <li className="subPage_list_item"><a href="#venue">장소</a></li>
                    <li className="subPage_list_item"><a href="#program">프로그램</a></li>
                    <li className="subPage_list_item"><a href="#get-involved">기여하기</a></li>
                    <li className="subPage_list_item"><a href="#policy">정책</a></li>
                </ul>
            </div>
            <h1 id="tickets" className="subPage_title">티켓</h1>
            <h2 className="subPage_subTitle">Q. 티켓은 양도하거나 환불할 수 있나요?</h2>
            <p className="subPage_contents">
                티켓 환불은 불가능하지만 자유롭게 양도하실 수 있습니다.</p><br/>
            <p className="subPage_contents">
                Ti.to를 통해 티켓을 구매하시거나 전달받으셨다면 “Your JSConf Korea 2019 Ticket”라는 제목의 이메일에서 “CHANGE DETAILS” 버튼을 클릭합니다. 티켓 상세 페이지가 뜨면 "Re-assign" 버튼을 눌러 양수인의 성함과 이메일을 입력합니다. 티켓을 전달 받은 양수인이 모든 정보를 입력하면 양도가 완료됩니다.</p><br/>
            <p className="subPage_contents">
                이벤터스로 구매하셨을 경우, <strong>2019년 8월 23일</strong>까지 양도인분의 성함과 이메일을 <a href="mailto:ticket@jsconfkorea.com">ticket@jsconfkorea.com</a> 로 전달주시면 양수인분을 위한 티켓 링크를 보내드립니다. 티켓 구매 플랫폼을 통한 환불이 불가하오니 거래 금액은 직접 전달받으셔야하는 점 양해 부탁드립니다.</p><br/>
            <p className="subPage_contents">
                양수인을 찾는데 도움이 필요하신 경우, 트위터나 페이스북에 JSConf Korea를 태그하고 티켓을 양도한다고 글을 올려주시면 리트윗/공유 해드리겠습니다.</p>

            <h2 className="subPage_subTitle">Q. 티켓 구매 영수증을 받을 수 있나요?</h2>
            <p className="subPage_contents">이벤터스의 <a href="https://event-us.kr/mypage/Certificate" target="_blanc">증빙서류 페이지</a>를 참고해주세요.</p>

            <h2 className="subPage_subTitle">Q. 세금계산서를 발급 받을 수 있나요?</h2>
            <p className="subPage_contents">
                JSConf Korea는 법인으로 보는 단체로서 세금계산서 발행이 불가하오니, 카드로 결제하고 영수증을 증빙 자료로 제출하실 것을 제안드립니다.</p>
            <h2 className="subPage_subTitle">Q. 티켓은 어디서 살 수 있나요?</h2>
            <p className="subPage_contents">
                모든 티켓이 매진되었습니다. 다만 양도인를 찾아 양도받으실 수 있습니다. 트위터나 페이스북에 JSConf Korea를 태그하고 티켓을 찾는다고 글을 올려주시면 리트윗/공유 해드리겠습니다.</p>


            <h1 id="schedule" className="subPage_title">일정</h1>
            <h2 className="subPage_subTitle">Q. 행사장은 언제부터 입장 가능한가요?</h2>
            <p className="subPage_contents">
                2019년 9월 3일 오전 8:30부터 등록하고 입장하실 수 있습니다. 9월 2일 저녁에 사전등록하신 경우 명찰만 확인되면 바로 입장하실 수 있습니다.</p>
            
            <h2 className="subPage_subTitle">Q. 등록은 언제, 어떻게 하나요?</h2>
            <p className="subPage_contents">
                행사 전날인 2019년 9월 2일 오후 7시부터 8시 반까지 코엑스 컨퍼런스룸(남) 401호 로비에서 사전등록이 가능합니다.</p><br/>
            <p className="subPage_contents">
                행사 당일 9월 3일은 로비에서 아침 8시 반부터 10시까지 등록하실 수 있습니다.</p><br/>
            <p className="subPage_contents">
                등록시 소지하고계신 티켓 이메일을 보여주시면 확인 후 컨퍼런스 명찰을 드립니다.</p>
            
            <h2 className="subPage_subTitle">Q. 컨퍼런스 당일 스케줄은 어떻게 되나요?</h2>
            <p className="subPage_contents">
                컨퍼런스 시간표는 <a href="/schedule">이곳</a>에서 확인하실 수 있습니다.</p>


            <h1 id="venue" className="subPage_title">장소</h1>
            <h2 className="subPage_subTitle">Q. 컨퍼런스는 어디서 열리나요?</h2>
            <p className="subPage_contents">
                코엑스 컨퍼런스룸(남) 4층에 위치한 401, 402, 403호 및 로비 공간에서 열립니다.</p>
                <div className="listing_block notice_list">
                <ul className="subPage_list">
                    <li className="subPage_list_item">주소: <a href="https://place.map.kakao.com/17573702">서울 강남구 영동대로 513 (삼성동 159)</a></li>
                    <li className="subPage_list_item">전화: 02-6000-0114</li>
                    <li className="subPage_list_item">웹사이트: <a href="www.coex.co.kr">www.coex.co.kr</a></li>
                </ul>
            </div>
            
            <h2 className="subPage_subTitle">Q. 대중교통, 자동차로 어떻게 가나요?</h2>
            <p className="subPage_contents">
                접근성 페이지의 <a href="/accessibility#subway" target="_blanc">지하철로 오시는 길</a> 혹은 <a href="/accessibility#car" target="_blanc">차로 오시는 길</a>을 참고해주세요.</p>
           
            <h2 className="subPage_subTitle">Q. 행사장은 휠체어나 거동 보조 기구를 통해 접근이 가능한가요?</h2>
            <p className="subPage_contents">
                행사장 내 모든 장소에 휠체어 및 거동 보조 기구로 이동이 가능합니다. 다만, 활동보조인 없이 오시는 경우, 코엑스 건물 입구에 자동문이 없어 출입이 어려우실 수 있으므로 <a href="mailto:contact@jsconfkorea.com">contact@jsconfkorea.com</a>으로 연락처와 함께 미리 알려주시면 코엑스 동문, 혹은 지하철역이나 주차장에서 컨퍼런스장까지 이동을 도와드리겠습니다.</p>
            

            <h1 id="program" className="subPage_title">프로그램</h1>
            <h2 className="subPage_subTitle">Q. 발표는 전부 영어/한국어로 진행되나요?</h2>
            <p className="subPage_contents">
                영어로 진행되는 발표와 한국어로 진행되는 발표가 섞여있습니다. 두 언어로 동시통역 서비스를 제공하니 걱정하지 마세요!</p>
            
            <h2 className="subPage_subTitle">Q. 발표 내용이 자막으로 제공되나요?</h2>
            <p className="subPage_contents">
                모질라재단의 후원으로 한국어 실시간 자막이 제공됩니다. <a href="http://sharetalk.co.kr/#download">쉐어톡 앱을 설치</a>하셔서 모바일 기기를 통해 확인하시거나, 무대에 설치된 화면을 통해 확인하실 수 있습니다.</p>
            
            <h2 className="subPage_subTitle">Q. 발표 내용은 녹화되나요?</h2>
            <p className="subPage_contents">
                401호에서 진행되는 모든 발표는 녹화되어 <a href="https://www.youtube.com/channel/UCzoVCacndDCfGDf41P-z0iA">JSConf 유튜브채널</a>에 공유될 예정입니다. 컨퍼런스에 참석하지 못한 분들도 함께 배울 수 있는 기회를 제공하기 위함입니다.</p>
            
            <h2 className="subPage_subTitle">Q. 식사가 제공되나요?</h2>
            <p className="subPage_contents">
                점심시간동안 코엑스 스타필드몰 일부 식당을 이용하실 수 있는 식권을 제공해드립니다.</p>
            
            <h2 className="subPage_subTitle">Q. 아이돌봄 서비스가 있나요?</h2>
            <p className="subPage_contents">
                JSConf Korea에서는 무료 아이돌봄 서비스를 제공합니다. 서비스를 이용하고싶으실 경우 8월 16일까지 <a href="https://docs.google.com/forms/d/e/1FAIpQLSeJGmHiHNRhSQINzibrIFJEp7nUsazJlqrpds5o3x3Yqw4_uw/viewform?usp=sf_link">이 신청서</a>를 꼭 작성해주세요.</p>
            

            <h1 id="get-involved" className="subPage_title">기여하기</h1>
            <h2 className="subPage_subTitle">Q. JSConf Korea 2019에서 발표하고싶습니다. 어떻게 할 수 있나요?</h2>
            <p className="subPage_contents">
                JSConf Korea는 오로지 공개모집을 통해 발표자를 모집합니다. 올해 발표자 공개모집은 4월 26일부로 마감되었습니다.</p> 

            <h2 className="subPage_subTitle">Q. JSConf Korea 2019를 후원하고싶습니다. 어떻게 할 수 있나요?</h2>
            <p className="subPage_contents">
                올해 기업 스폰서십은 마감되었습니다.</p>
            
            <h2 className="subPage_subTitle">Q. JSConf Korea 2019에 커뮤니티로서 참여하고싶습니다. 어떻게 할 수 있나요?</h2>
            <p className="subPage_contents">
                자바스크립트 및 기술 관련 커뮤니티를 위한 저렴한 스폰서십 패키지가 마련되어있습니다. <a href="mailto:contact@jsconfkorea.com">contact@jsconfkorea.com</a> 으로 연락 주세요!</p>
            
            <h2 className="subPage_subTitle">Q. JSConf Korea 2019에서 자원봉사자로 일하고싶습니다. 어떻게 할 수 있나요?</h2>
            <p className="subPage_contents">
                자원봉사에 관심있으신 분은 8월 19일까지 <a href="mailto:contact@jsconfkorea.com">contact@jsconfkorea.com</a> 으로 연락 주세요!</p>


            <h1 id="policy" className="subPage_title">정책</h1>
            <h2 className="subPage_subTitle">Q. JSConf Korea는 행동강령(Code of Conduct)이 있나요?</h2>
            <p className="subPage_contents">
                JSConf Korea의 핵심원칙(Code of Conduct)는 <a href="/coc">이곳</a>에서 확인하실 수 있습니다.</p>

            <h2 className="subPage_subTitle">Q. 발표는 어떻게 선정되었나요?</h2>
            <p className="subPage_contents">
                JSConf Korea의 발표는 공개모집을 통해 모집되었습니다. 선정 기준과 절차에 대해서는 <a href="/call-for-proposals">발표자 모집 페이지</a>를 참고해주세요.</p>
            
            <h2 className="subPage_subTitle">Q. 튜토리얼은 어떻게 선정되었나요?</h2>
            <p className="subPage_contents">
                <a href="/call-for-proposals">발표자 공개모집</a>을 통해 받은 좋은 발표 제안서중 튜토리얼로서 더 빛을 발휘할 수 있을만한 제안서 두 개를 선정하여 튜토리얼 세션을 만들게 되었습니다.</p>
            
            <h2 className="subPage_subTitle">Q. JSConf Korea는 장학금 제도가 있나요?</h2>
            <p className="subPage_contents">
                더 많은 분들이 더 다양한 생각을 보태주시며 JSConf Korea 2019 에 함께하셨으면 하는 바람으로 <a href="/scholarships">장학 제도</a>를 선보인 바 있습니다. 올해의 장학 제도는 마감되었습니다.</p>

            <h2 className="subPage_subTitle">Q. 티켓 구매 약관은 어디서 볼 수 있나요? 개인정보는 어떻게 사용되나요?</h2>
            <p className="subPage_contents">
                구매 약관은 <a href="https://smallprint.tito.io/jsconf-korea/2019" target="_blanc">이곳</a>에서 확인하실 수 있습니다. 티켓 구매시 수집된 정보는 컨퍼런스 이후 관련 정보 발송 및 통계 목적으로 2019년 12월 31일까지 보유한 뒤 즉각 파기됩니다.</p>

            <h2 className="subPage_subTitle">Q. JSConf Korea의 수입은 어디서 오고 어떻게 쓰이나요?</h2>
            <p className="subPage_contents">
                JSConf Korea의 수입은 스폰서십, 장학금 후원금, 그리고 티켓 판매금액으로 구성됩니다. 컨퍼런스가 끝난 후 요약된 지출 내역을 공유할 예정입니다.</p>
            <br/>
            <br/>
            <br/>
            <p className="subPage_contents">
                <a href="#">🔼위로 가기</a></p>
        </section>
    </PageWrapper>
);

export default FAQ
