import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { IntlProvider, addLocaleData } from "react-intl"
import flatten from "flat"

// Locale data
import enData from 'react-intl/locale-data/en'
import koData from 'react-intl/locale-data/ko'

// Messages
import en from '../../i18n/en/main'
import ko from '../../i18n/ko/main'

import styles from "../../static/css/styles.scss"

const messages = { en, ko }

addLocaleData([...enData, ...koData])

const IntlWrapper = ({ locale, children }) => (
    <IntlProvider locale={locale} messages={flatten(messages[locale])}>
      <Fragment>
        <div className={styles.content}>{children}</div>
      </Fragment>
    </IntlProvider>
)
  
IntlWrapper.propTypes = {
  locale: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired
}

export default IntlWrapper