import React from "react"
import PageWrapper from '../../components/wrapper/pageWrapper'
import ShowCase from "../../components/showcase"

const Line = () => (
    <PageWrapper locale="ko" location="/sponsors/line">
        <ShowCase type="line"/>
        <section className="subPage_contents_wrapper">
            <div className="sponsor_list sponsor_list_detail">
                <div className="sponsor_item">
                    <img className={`sponsor_img line`} alt="line" src={`/sponsors/logo_line.svg`}/>
                </div>
            </div>
            <p className="subPage_contents">전 세계 1억 8천 7백만 이상의 월간사용자를 보유한 라인은 지속적으로 성장하고 있는 글로벌 스마트 포털로서, 메신저 서비스를 시작한 이래 AI 기술 및 핀테크, 게임 등 다양한 콘텐츠 및 서비스를 제공하고 있습니다. 또한 라인은 지난 2016년 미국 뉴욕증권거래소와 일본 도쿄증권거래소에 동시 상장했습니다. 라인은 개발자가 끊임없이 배우며 성장할 수 있는 기회와 최고 수준의 근무환경을 제공합니다. 현재 전 세계에서 다양한 글로벌 인재와 함께 커나갈 개발자를 채용 중이니 많은 관심 바랍니다.개발자 관련 채용 공고: <a href="https://recruit.linepluscorp.com/lineplus/career/list?classId=148" target="_blanc">https://recruit.linepluscorp.com/lineplus/career/list?classId=148</a></p>
        </section>
    </PageWrapper>
)

export default Line