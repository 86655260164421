export default [
    {
        id: "sona",
        name: "이소은",
        image: "profile_sona.png",
        desc: "예술, 음악, 맥주, 글쓰기를 좋아하고, 이런 취미를 기술에 녹여내는 것도 좋아하는 프론트엔드 엔지니어입니다. 작년 JSConf EU의 인상적인 경험을 계기로, 자바스크립트 생태계의 다양성이 깃든 즐거운 컨퍼런스를 만들고 싶어 JSConf Korea를 시작하게 되었습니다.",
        links: [
            {
                id: "@mojosoeun",
                url: "https://twitter.com/mojosoeun"
            }
        ]
    },
    {
        id: "suzin",
        name: "유수진",
        image: "profile_suzin.png",
        desc: "더 많은 사람들이 기회를 가질 수 있는 기술 커뮤니티와 세상을 꿈꿉니다.",
        links: [
            {
                id: "@o0o000000000000",
                url: "https://twitter.com/o0o000000000000/"
            }
        ]
    },
    {
        id: "kukhwan",
        name: "박국환",
        image: "profile_kukhwan.png",
        desc: "기술을 통한 아름다움을 추구합니다.",
        links: [
            {
                id: "@kukhwan1",
                url: "https://twitter.com/kukhwan1/"
            }
        ]
    },
    {
        id: "seungkyu",
        name: "장승규",
        image: "profile_seongkyu.png",
        desc: "선한 영향력을 꿈꾸는 기획자입니다. 사이드 허슬로 비영리단체를 꾸리고 있어요. 낭만적 감성과 달리 전략적이고 분석적인 사고를 즐깁니다. 새해 목표로 책 100권 읽기를 실천 중이며, 로망은 싱글 메뉴 레스토랑 브랜드를 만드는 것입니다.",
        links: [
            {
                id: "@sk.jang",
                url: "https://www.instagram.com/sk.jang/"
            }
        ]
    }
]