import React from "react"
import PageWrapper from "../components/wrapper/pageWrapper"
import ShowCase from "../components/showcase"
import Team from "../components/team"
import { Link } from "gatsby"

import organizers from "../i18n/ko/organizers"
import staff from "../i18n/ko/staff";
import mcs from "../i18n/ko/mcs";


import alumni from "../i18n/ko/alumni"

import Venue from "../components/venue";

const About = () => (
  <PageWrapper locale="ko" location="/about">
    <ShowCase type="about"/>
    <section className="subPage_contents_wrapper">
      <h1 className="subPage_title">행사소개</h1>
      <p className="subPage_contents">JSConf Korea는 자바스크립트 커뮤니티를 이루는 국내외의 구성원과 함께 기술의 한계를 뛰어넘고 긍정적인 에너지를 주고받는 축제의 장입니다. 우리는 보다 대담하고, 다양하며, 인간적인, 누구나 환영받는 기술 커뮤니티를 꿈꿉니다.</p>
      <br />
      <p className="subPage_contents">JSConf Korea는 세계 각국에서 열리는 <a href="https://jsconf.com/" target="_blanc">JSConf</a>와 한 가족으로서 2019년 첫 행사에 여러분을 환영합니다.</p>
      <h1 className="subPage_title">프로그램</h1>
      <p className="subPage_contents">컨퍼런스는 2019년 9월 3일 화요일 코엑스 4층 컨퍼런스룸(남)에서, 오전 10시부터 오후 6시까지 열립니다.</p><br/>
      <p className="subPage_contents">자바스크립트 생태계의 다양성을 반영하는 <a href="/speakers">발표</a>, 쉽고 재밌게 자바스크립트를 배울 수 있는 <a href="/tutorials">튜토리얼</a>, JSConf Korea를 후원해준 <a href="/sponsors">기업 및 커뮤니티</a>들의 부스 프로그램과 라이트닝 토크가 진행됩니다.</p><br/>
      <p className="subPage_contents">컨퍼런스 당일 스케줄은 <a href="/schedule">시간표</a>를 확인해주세요!</p><br/>
      <Venue />
      <h1 className="subPage_title">컨퍼런스 접근성</h1>
      <p className="subPage_contents">
        JSConf Korea는 모든 참가자가 즐길 수 있는 컨퍼런스가 되기 위해 최선을 다하고 있습니다. <Link to="/accessibility">컨퍼런스 접근성 페이지</Link>에서 JSConf Korea가 제공하는 서비스와 컨퍼런스 안팎의 환경에 대해 알아보세요.
      </p>

      <h1 className="subPage_title">팀</h1>
      <p className="subPage_contents">JSConf Korea의 준비위원회는 모두 금전적 이득 없이 자바스크립트 커뮤니티를 위해 자원봉사로 운영되고 있습니다.</p>
      <br />
      <h2 className="subPage_subTitle">오거나이저</h2>
      <Team key="organizers" members={organizers} />

      <h2 className="subPage_subTitle">함께해주신 분</h2>
      <Team key="alumni" members={alumni} />

      <h2 id="mcs" className="subPage_subTitle">MCs</h2>
      <Team key="mcs" members={mcs} />

      <h2 className="subPage_subTitle">스태프</h2>
      <Team key="staff" members={staff} />
      
    </section>
  </PageWrapper>
)

export default About
