import React from "react"
import PageWrapper from '../../components/wrapper/pageWrapper'
import ShowCase from "../../components/showcase"

const Elastic = () => (
    <PageWrapper locale="ko" location="/sponsors/elastic">
        <ShowCase type="elastic"/>
        <section className="subPage_contents_wrapper">
            <div className="sponsor_list sponsor_list_detail">
                <div className="sponsor_item">
                    <img className={`sponsor_img elastic`} alt="elastic" src={`/sponsors/logo_elastic.svg`}/>
                </div>
            </div>
            <p className="subPage_contents">우리는 Elasticsearch, Kibana, Beats, Logstas로 구성된 Elastic Stack을 개발한 회사입니다. 주식 시세에서부터 트위터 스트림, Apache 로그, 워드프레스 블로그에 이르기까지, 우리는 사람들이 검색의 힘을 이용해 자신의 데이터를 다른 방식으로 탐색하고 분석하도록 도와드립니다.</p>
        </section>
    </PageWrapper>
)

export default Elastic