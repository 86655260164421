export default {
    title: "Coffee",
    desc: `Great coffee is everywhere in Seoul!  Korea is home to many award-winning baristas, including the <a href="https://bit.ly/2vnbTpY" target="_blanc">2019 World Barista Champion</a>. Here are our recommendations on some of the best coffee places in Seoul.`,
    data: [
        {
            id: "Fritz Coffee Company",
            name: `Fritz Coffee Company(<a href="http://fritz.co.kr/contact.html" target="_blanc">Link</a>)`,
            desc: `One of the best coffee shops in Seoul has a few locations. Coffee here is superb. The Yangjae location’s ambiance is a mix of traditional Korean tableware and office building while the Mapo location is in a traditional hanok building. Anything and everything on the menu is recommended.`
        },
        {
            id: "Café Namusairo",
            name: `Café Namusairo - Map(<a href="https://goo.gl/maps/XJhPCexZfT92" target="_blanc">Link</a>)`,
            desc: `A great coffee shop in a quaint little hanok. Quite famous among Seoulites for their solid selection of beans and good coffee. If you're near Gyeongbokgung and want to skip the chains, take a slight detour and visit! p.s. Watch your head when you go down to sit. I (Soeun) bumped my head on the door frame a bunch of times.`
        },
        {
            id: "Coffee Montage",
            name: `Coffee Montage - Map(<a href="https://goo.gl/maps/LKNgYpFnLw52" target="_blanc">Link</a>)`,
            desc: `Small, modern coffee shop with amazing summer drinks. Amazing hand-made sodas and their coffee is not bad either ;) They usually purchase the best (and sometimes quite expensive) green coffees and make it available to consumers at a reasonable price.`
        },
        {
            id: "Coffee Libre (Yeonnam)",
            name: `Coffee Libre (Yeonnam) - Map(<a href="https://goo.gl/maps/Gihs6sMtHzx" target="_blanc">Link</a>)`,
            desc: `A tiny cafe that had the best latte I have ever had in my life. They do have unconventional hours so check before you make the trip. There are other Coffee Libre locations, but this is the only one that blew my socks off.`
        },
        {
            id: "Anthracite Coffee Roasters (Seogyo)",
            name: `Anthracite Coffee Roasters (Seogyo) - Map(<a href="https://goo.gl/maps/xvkVNkja2W12" target="_blanc">Link</a>)`,
            desc: `Anthracite is the most hip cafe around in Seoul. Probably the best designed cafe that also serves single malt whiskey.`
        },
        {
            id: "Center Coffee",
            name: `Center Coffee(<a href="https://en.centercoffee.co.kr/encafe" target="_blanc">Link</a>)`,
            desc: `Great coffee in the lobby of a business building. Has two more locations, the original near Seoul Forest and the newest near Myeongdong. Close by to the conference venue so check it out!`
        },
        {
            id: "Osulloc",
            name: `Osulloc - Map(<a href="https://www.osulloc.com/" target="_blanc">Link</a>)`,
            desc: `A convenient place to try Korean green tea and green tea desserts. Many locations all around Seoul.`
        }
    ]
}