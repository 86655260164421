import React from 'react'
import PropTypes from "prop-types"
import InitWrapper from "./intlWrapper"
import Header from "../header"
import Footer from "../footer"
import SEO from "../seo"

const pageWrapper = ({ locale, children, location}) => {
  return (
    <InitWrapper locale={locale}>
      <SEO lang={locale} location={location} title="JSConf Korea 2019"/>
      <Header locale={locale} location={location}/>
        { children }
      <Footer locale={locale}/>
    </InitWrapper>
  )
}
  
pageWrapper.propTypes = {
  locale: PropTypes.string,
  location: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired
}

export default pageWrapper