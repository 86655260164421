import React from "react"
import PageWrapper from "../components/wrapper/pageWrapper"
import ShowCase from "../components/showcase"
import Speaker from "../components/speakers";

import tutorials from "../i18n/ko/tutorials";

const Tutorials = () => (
  <PageWrapper locale="ko" location="/tutorials">
    <ShowCase type="tutorial"/>
    <section className="subPage_contents_wrapper">
      <div className="speaker_wrapper">
          <Speaker type="detail" speakers={tutorials} locale="ko"/>
      </div>
      <br/>
    </section>
  </PageWrapper>
);

export default Tutorials