export default {
    platinum:[
        {
            id: "lunit",
            name: "lunit",
            image: "logo_lunit.svg",
            link: "/en/sponsors/lunit"
        },
        {
            id: "woowahan",
            name: "woowahan",
            image: "logo_woowahan.svg",
            link: "https://www.woowahan.com"
        },
        {
            id: "hyperconnect",
            name: "hyperconnect",
            image: "logo_hyperconnect.svg",
            link: "/en/sponsors/hyperconnect"
        },
    ],
    gold: [
        {
            id: "nexmo",
            name: "nexmo",
            image: "logo_nexmo.svg",
            link: "/en/sponsors/nexmo"
        },
        {
            id: "mozilla",
            name: "mozilla",
            image: "logo_mozilla.svg",
            link: "https://foundation.mozilla.org/en/"
        },
        {
            id: "kakao",
            name: "kakao",
            image: "logo_kakao.svg",
            link: "https://www.kakaocorp.com/?lang=en"
        },
        {
            id: "yanolja",
            name: "yanolja",
            image: "logo_yanolja.svg",
            link: "http://yanolja.in/en"
        },
        {
            id: "elastic",
            name: "elastic",
            image: "logo_elastic.svg",
            link: "/en/sponsors/elastic"
        },
        {
            id: "myrealtrip",
            name: "myrealtrip",
            image: "logo_myrealtrip.svg",
            link: "https://career.myrealtrip.com/"
        },
        {
            id: "line",
            name: "Line",
            image: "logo_line.svg",
            link: "/en/sponsors/line"
        },
        {
            id: "robertwalters",
            name: "robertwalters",
            image: "logo_robertwalters.svg",
            link: "https://www.robertwalters.co.kr/"
        },
        {
            id: "bagelcode",
            name: "baglecode",
            image: "logo_baglecode.svg",
            link: "/en/sponsors/bagelcode"
        },
        {
            id: "odkmedia",
            name: "odkmedia",
            image: "logo_odkmedia.svg",
            link: "https://www.odkmedia.net/"
        },
        {
            id: "gabia",
            name: "gabia",
            image: "logo_gabia.svg",
            link: "http://company.gabia.com/"
        },
        {
            id: "devsisters",
            name: "devsisters",
            image: "logo_devsisters.svg",
            link: "https://www.devsisters.com"
        },
        {
            id: "jetbrains",
            name: "jetbrains",
            image: "logo_jetbrains.svg",
            link: "/en/sponsors/jetbrains"
        },
        {
            id: "bixby",
            name: "bixby",
            image: "logo_bixby.svg",
            link: "https://bixby.developer.samsung.com/"
        }
    ],
    "lunch": [
        {
            id: "blockstack",
            name: "blockstack",
            image: "logo_blockstack.svg",
            link: "https://blockstack.org/"
        }
    ],
    "community": [
        {
            id: "ial",
            name: "Interactive Art of Lab",
            image: "logo_ial.svg",
            link: "#"
        },
        {
            id: "wwcseoul",
            name: "Woman Who Code Seoul",
            image: "logo_wwcseoul.svg",
            link: "https://www.womenwhocode.com/seoul"
        },
        {
            id: "gdg",
            name: "gdg webtech",
            image: "logo_gdg.svg",
            link: "https://www.facebook.com/groups/webTechKR/"
        }
    ]
}
