export default [
    {
        id: "ysm",
        name: "Chris Yang",
        image: "profile_ysm.png",
        desc: "Chris is a Software Engineer who's passionate about JavaScript and UX/UI design. He really loves travel, photography, movies and animals. 🐶",
        links: [
            {
                id: "website",
                url: "https://ysm.sh/"
            }
        ]
    },
    {
        id: "godori",
        name: "Eunjeong Ko (Godori)",
        image: "profile_godori.png",
        desc: "Godori is a software engineer who loves web developing because she can rapidly make a prototype of service with it. She’s  interested in making and using productivity-enhancing tools. Godori believes that technology can help people and make them happy.",
        links: [
            {
                id: "github",
                url: "https://github.com/godori"
            }
        ]
    },
    {
        id: "jyhwang",
        name: "Jiyoung Hwang",
        image: "profile_jyhwang.png",
        desc: "Frontend/web developer. JSConf EU 2018 was the most fun, festive, and diversity respecting conference I’ve ever attended. It is so exciting to finally see it happen in Korea",
        links: [
            {
                id: "@jyhwng",
                url: "https://twitter.com/jyhwng/"
            }
        ]
    }
]