export default [
    {
        id: "jenn",
        name: "Jenn Creighton",
        firstname: "Jenn",
        lastname: "Creighton",
        desc: "Jenn Creighton is a frontend engineer and conference speaker. She lives in New York with her two cats, one dog, and maintains a Home for Abandoned Succulents, Mismanaged Plants and Otherwise Ailing Flora. You can find her online @gurlcode.",
        title: "What Tamagotchis can teach you about generators",
        talk: "What do Tamagotchis — everyone’s favorite digital pets from the 90s — and generators — pausible functions for async tasks — have in common? Both are really fun to play with. As a recent side project, I built a web-version of a Tamagotchi. But I struggled with one key element: how to handle a series of animations. What do you do when animations need to know that other animations are complete? What do you do when you’ve queued animations but a user event needs to be handle instead? This talk explores where I went wrong (hint: promises) and what worked (hint: generators). You’ll leave this talk with a solid grasp of how to combine promises, generators, and coroutines for async work — whether it’s for a Tamagotchi or your day-to-day work.",
        links: [
            {
                id: "twitter.com/gurlcode",
                url: "https://twitter.com/gurlcode",
                type: "twitter",
                name: "@gurlcode"
            }
        ]
    },
    {
        id: "jeonghoon",
        name: "Jeonghoon Byun aka Outsider",
        firstname: "Jeonghoon",
        lastname: "Byun",
        desc: "Jeonghoon works as an SRE at ODK Media. He is passionate about open source, and realizes his passion as a maintainer for Mocha, an open source JavaScript test framework. When he's not working, he likes to write about software development.",
        title: "Lessons from maintaining Mocha, an open source project",
        talk: "I learned a lot running a personal open source project and occasionally contributing to other ones. I learned even more maintaining a popular open source project used by many users across the globe. In this talk, I'd like to share with you the challenges I faced and the lessons I learned working on Mocha as a maintainer over the past year. I'll also talk about how maintainers of big open source projects manage them and collaborate with each other.",
        links: [
            {
                id: "twitter.com/outsideris",
                url: "https://twitter.com/outsideris",
                type: "twitter",
                name: "@outsideris"
            },
            {
                id: "github.com/outsideris",
                url: "https://github.com/outsideris",
                type: "github"
            },
            {
                id: "blog.outsider.ne.kr",
                url: "https://blog.outsider.ne.kr",
                type: "homepage"
            }
        ]
    },
    {
        id: "wendy",
        name: "Wendy Gwo",
        firstname: "Wendy",
        lastname: "Gwo",
        desc: "Wendy is a software engineer in Los Angeles. She loves all things tech, and started off as an electrical engineer before transitioning into front end web development 4 years ago. Outside of work, she loves traveling, exploring the great outdoors, and chasing around her rambunctious toddler.",
        title: "Congrats! You’re the first person to have a baby at your startup. Now what?",
        talk: "Having a baby is a big life change. Being the first person to have a baby at a startup makes it feel even more daunting. This talk walks you through the steps I took when I was the first person to go on family leave at my previous job (a startup), including educating my company about what leave I was entitled to, and shows how you can own the situation and take control. </br></br> This talk is for anyone who works in startups who is thinking about having a baby (or adopting one!), is currently expecting a child, or just wants to be an ally in the workplace for soon-to-be parents.",
        links: [
            {
                id: "twitter.com/wendygwo",
                url: "https://twitter.com/wendygwo",
                type: "twitter",
                name: "@wendygwo"
            },
            {
                id: "github.com/wendygwo",
                url: "https://github.com/wendygwo",
                type: "github"
            }
        ]
    },
    {
        id: "jaeman",
        name: "Jaeman An",
        firstname: "Jaeman",
        lastname: "An",
        desc: "Jaeman works at a medical AI startup, AITRICS, and is on a mission to transform people's lives for the better with cutting-edge technology. He's dabbled in many fields as a software engineer, from developing mobile game server for 10 million DAUs to designing VR and physics engines.",
        title: "Lifesaving AI and JavaScript",
        talk: "JavaScript, artificial intelligence, and software development, a combination with myriad possibilities. This presentation tells one such story, a life-saving software for predicting severe acute illness.</br></br>The AI system required not just back-end and front-end but also mobile apps, AI models, and data collection and analysis components. I’ll show you how I used JavaScript to create this solution, with tools like Vue.js, React Native, Electron, TypeScript, and server-side JavaScript. I’ll also explain issues of safety, privacy, and explainability that comes with using AI in medicine and how to deal with them. Finally, I'd like to share with you how software made out of JS can bring tangible contributions and innovations to the forefront of medicine.",
        links: [
            {
                id: "github.com/kuss",
                url: "https://github.com/kuss",
                type: "github",
                name: "@kuss"
            }
        ]
    },
    {
        id: "vitalii",
        name: "Vitalii Bobrov",
        firstname: "Vitalii",
        lastname: "Bobrov",
        desc: "Vitalii is a Lead JavaScript Engineer with more than six years of professional experience. He's a co-organizer of the Angular Wroclaw meetup. Vitalii keeps up-to-date with the latest Web Platform features and does great experiments with it. This guy is not just a nerd, but also a tech speaker, ngGirls mentor, and a father of an excellent little girl.",
        title: "<a href='https://www.youtube.com/watch?v=fregObNcHC8' target='_blanc'>The Man who Sold the Amp</a>: How to Process Music with JS",
        talk: "Take your old scratched Les Paul or Stratocaster from the case and fill all the space around with warm riffs. I’m going to show how to transform the code into Kirk Hammett’s wah-wah, Stevie Ray Vaughan’s overdrive and Kurt Cobain’s distortion. You’ll learn how to parse audio input in real-time using JavaScript and the Web Audio API. <br/></br> I’ll be jamming live on stage with my guitar to demo every code example, and we’ll also use WebRTC to jam with friends across the world! After this talk, you will be familiar with the principles behind pedal sound effects and how to create them in code. Let's rock the Web 🤘!",
        links: [
            {
                id: "twitter.com/bobrov1989",
                url: "https://twitter.com/bobrov1989",
                type: "twitter",
                name: "@bobrov1989"
            },
            {
                id: "github.com/vitaliy-bobrov",
                url: "https://github.com/vitaliy-bobrov",
                type: "github"
            },
            {
                id: "bobrov.dev",
                url: "https://bobrov.dev",
                type: "homepage"
            }
        ]
    },
    {
        id: "gyujin",
        name: "Gyujin Cho",
        firstname: "Gyujin",
        lastname: "Cho",
        desc: "Gyujin is a front-end engineer for a medical AI service at Lunit. Fascinated by clean and declarative code, she dived head first into the world of functional programming. Since then, she's faced all kinds of difficulties and made spectacular mistakes, but is constantly growing because of that. She wants to create interfaces that enables truthful, effective, and elegant delivery of information.",
        title: "Functional Programming: Learning by Application to Redux and Canvas",
        talk: "What's so great about functional programming? Textbooks tell you it's \"declarative, reusable, and without side effects,\" but don't explain how or when to use it.<br/><br/>In this talk, I will go step-by-step through the \"how and when\" of functional programming as applied to Redux and Canvas. You'll also learn about my own experiences with FP, why Ramda.js works better than lodash.js in this context, and the ups and downs of app development in FP.",
        links: [
            {
                id: "github.com/gyujincho",
                url: "https://github.com/gyujincho",
                type: "github",
                name: "@gyujincho"
            }
        ]
    },
    {
        id: "jaeseok",
        name: "Jaeseok Kang",
        firstname: "Jaeseok",
        lastname: "Kang",
        desc: "Jaeseok is a web front-end engineer looking for ways to improve user experience. He's also an advocate for great software development culture that encourages productivity, efficient communication, and mutual growth.",
        title: "Don't block the event loop! JavaScript Async for Effortless UX",
        talk: "Page freeze, glitchy animation, and slow scrolling. They follow us everywhere, and we'd like them to stop. This talk will get to the bottom of these issues by delving into the Javascript engine and concepts like task, call-stack, and event-loops. I will also introduce several ways to tackle these problems and walk you through a demo to help you understand.",
        links: [
            {
                id: "twitter.com/kang89kr",
                url: "https://twitter.com/kang89kr",
                type: "twitter",
                name: "@kang89kr"
            },
            {
                id: "github.com/jaeseokk",
                url: "https://github.com/jaeseokk",
                type: "github"
            },
            {
                id: "jaeseokk.dev",
                url: "https://jaeseokk.dev",
                type: "homepage"
            }
        ]
    },
    {
        id: "willian",
        name: "Willian Martins",
        firstname: "Willian",
        lastname: "Martins",
        desc: "Willian is a JS formatter/CSS tweaker at eBay. He's from São Paulo Brazil, but lives in Berlin. He's also a Sim racer gamer and a soccer fan. #TeamPS4 #mozTechSpeaker 🏎⚽️🕹🇧🇷",
        title: "Back to the future of JS II: Beyond what we can foresee",
        talk: "Learn about the upcoming JavaScript features you never knew you needed: Pattern Matching, Pipeline Operator, and Binary AST. I'll show you how these proposals may change the way you write code forever, as the spread operator once did. After that, I'd like to invite you to help TC39 push them forward.<br/><br/>What would you like to see implemented in JavaScript next?",
        links: [
            {
                id: "twitter.com/wmsbill",
                url: "https://twitter.com/wmsbill",
                type: "twitter",
                name: "@wmsbill"
            }
        ]
    },
    {
        id: "istvan",
        name: "Istvan Szmozsanszky aka Flaki",
        firstname: "Istvan",
        lastname: "Szmozsanszky",
        desc: "Istvan Szmozsanszky (or Flaki, for short) is a JavaScript engineer, trainer & Mozilla Tech Speaker. Flaki works with Mozilla's DevRel team on promoting the open web platform & making it accessible to all. He loves JavaScript internals & is often found playing (quite literally) with microcontrollers.",
        title: "A WebAssembly Field Guide easily worth like 70 bottle caps",
        talk: "Life underground has become trying lately. Following many distressing incidents and a recent trouble with your Vault's water supply, you are appointed Chief Vault Technician to take matters into your own hands. Documentation is scarce on these decade-old protective underground habitats, so when you finally manage to barter a bulky operating manual you are astounded by the rich historical accord of this one technology, called \"WebAssembly\", having seemingly evolved from its unsuspecting niche—a common dialect of inter-connected computers—to become the universal operational underpinning of every machine on Earth. Including those that have kept you & your compatriots safe over the past decades...",
        links: [
            {
                id: "twitter.com/slsoftworks",
                url: "https://twitter.com/slsoftworks",
                type: "twitter",
                name: "@slsoftworks"
            },
            {
                id: "github.com/flaki",
                url: "https://github.com/flaki",
                type: "github"
            },
            {
                id: "musings.flak.is",
                url: "https://musings.flak.is",
                type: "homepage"
            }
        ]
    },
    {
        id: "isha",
        name: "Isha Kasliwal",
        firstname: "Isha",
        lastname: "Kasliwal",
        desc: "Isha is a Senior UI/UX Designer/Developer on the Design Systems team at Twitch. She's a creative thinker who focuses on code, communication, and accessibility. On weekends, you can find her somewhere outdoors, eating a lot and looking for adventure.",
        title: "How Typescript Can Power Design Systems",
        talk: "Design Systems are beneficial in bridging the gap between design and development. The inclusion of React.js in Design Systems helped introduce the concept of functional programming as it relates to building components for reuse and scale. What happens if we take it a step further? With Typescript, a superset of JS that can be used in conjunction with React.js, your Design System can reach a whole new level of efficiency and clarity. This talk will explore how UI engineers, from beginner to experienced, can harness the power of Typescript to help enforce better component standards in the code and allow large-scale JS projects to succeed. By the end of this talk, you will walk away with actionable ideas to implement in your codebase!",
        links: [
            {
                id: "twitter.com/ikasliwal",
                url: "https://twitter.com/ikasliwal",
                type: "twitter",
                name: "@ikasliwal"
            },
            {
                id: "github.com/ishakasliwal",
                url: "https://github.com/ishakasliwal",
                type: "github"
            },
            {
                id: "www.ikasliwal.com",
                url: "https://www.ikasliwal.com",
                type: "homepage"
            }
        ]
    },
]