import React from "react"
import PageWrapper from "../components/wrapper/pageWrapper"
import ShowCase from "../components/showcase"
import ScheduleInfo from "../components/schedule"
import schedule from "../i18n/ko/schedule"

const Schedule = () => (
  <PageWrapper locale="ko" location="/schedule">
    <ShowCase type="schedule"/>
    <section className="subPage_contents_wrapper">
      <ScheduleInfo schedule={schedule}/>
    </section>
  </PageWrapper>
)

export default Schedule