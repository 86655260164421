import React from "react"
import PageWrapper from "../../components/wrapper/pageWrapper"
import ShowCase from "../../components/showcase"

const FAQ = () => (
    <PageWrapper locale="en" location="/faq">
        <ShowCase type="faq" />
        <section className="subPage_contents_wrapper">
            <h1 className="subPage_title"></h1>
            <div className="listing_block">
                <ul className="subPage_list">
                    <li className="subPage_list_item"><a href="#tickets">Tickets</a></li>
                    <li className="subPage_list_item"><a href="#schedule">Schedule</a></li>
                    <li className="subPage_list_item"><a href="#venue">Venue</a></li>
                    <li className="subPage_list_item"><a href="#program">Programs</a></li>
                    <li className="subPage_list_item"><a href="#get-involved">Get Involved</a></li>
                    <li className="subPage_list_item"><a href="#policy">Policy</a></li>
                </ul>
            </div>
            <h1 id="tickets" className="subPage_title">Tickets</h1>
            <h2 className="subPage_subTitle">Q. Where can I buy the tickets?</h2>
            <p className="subPage_contents">
                All tickets are sold out. You can get a ticket by finding someone who’s looking to transfer their ticket. If you tweet or post about it on facebook and tag us, we’ll retweet/share.</p>
            <h2 className="subPage_subTitle">Q. Can I get a refund for my ticket?</h2>
            <p className="subPage_contents">
                Unfortunately, all tickets are non-refundable, but you can transfer your ticket to someone else.</p>
            <h2 className="subPage_subTitle">Q. Can I transfer my ticket(s) to someone else?</h2>
            <p className="subPage_contents">
                Yes, all tickets are transferable. If you purchased your ticket through Tito (with non-Korean payment option), within the confirmation email titled “Your JSConf Korea 2019 Ticket”, click the “CHANGE DETAILS” button. This will lead you to your ticket’s details page. Click “Re-assign” and enter the full name and email address of the transferee.</p>
            <p className="subPage_contents">
                If you'd like to find a transferee, post about it on twitter or facebook and tag us, then we’ll re-tweet or share.</p>

            <h1 id="schedule" className="subPage_title">Schedule</h1>
            <h2 className="subPage_subTitle">Q. When does the conference space open?</h2>
            <p className="subPage_contents">
                The conference space will be open from 8:30am on September 3.</p>

            <h2 className="subPage_subTitle">Q. When can I register and how?</h2>
            <div className="listing_block notice_list">
                <ul className="subPage_list">
                    <li className="subPage_list_item">September 2, 7:00pm - 8:30pm (pre-registeration)</li>
                    <li className="subPage_list_item">September 3, 8:30am and on</li>
                </ul>
            </div>
            <p className="subPage_contents">
                Come to the lobby of COEX Conference Room 4F and show us your email with the ticket to register.</p>

            <h2 className="subPage_subTitle">Q. What’s the schedule like for the conference day?</h2>
            <p className="subPage_contents">
                Check out the full schedule <a href="/en/schedule">here</a>.</p>


            <h1 id="venue" className="subPage_title">Venue</h1>
            <h2 className="subPage_subTitle">Q. Where is the conference?</h2>
            <p className="subPage_contents">
                The conference will take place at rooms 401, 402, 403, and the lobby at the COEX Conference Rooms 4F.</p>
            <div className="listing_block notice_list">
                <ul className="subPage_list">
                    <li className="subPage_list_item">Address: 513 Yeongdongdae-ro, Gangnam-gu, Seoul</li>
                    <li className="subPage_list_item">Phone: 02-6000-0114 (country code +82)</li>
                    <li className="subPage_list_item">Website: <a href="https://www.coexcenter.com/">www.coexcenter.com</a></li>
                </ul>
            </div>
            <br/>
            <p className="subPage_contents">
                Please download a <a href="https://therealseoul.com/tag/naver-map/" target="_blanc">Korean map application</a> to navigate, as Google maps is not reliable in Korea.</p>

            <h2 className="subPage_subTitle">Q. How do I get to the conference by subway or car?</h2>
            <p className="subPage_contents">
                Please refer to the <a href="/en/accessibility#subway" target="_blanc">“Getting here by subway”</a> or <a href="/en/accessibility#car" target="_blanc">“Getting here by car”</a> section in our accessibility page.</p>

            <h2 className="subPage_subTitle">Q. Is the conference space accessible to wheelchair users or people with reduced mobility?</h2>
            <p className="subPage_contents">
                You’ll be able to explore all conference space with a wheelchair, including the auditorium. However, there is no automatic entrance for the building, so if you are coming without a support person, please let us know in advance with your contact information at <a href="mailto:contact@jsconfkorea.com">contact@jsconfkorea.com</a> and our staff will escort you from the door or from the subway station, bus stop, or parking lot.</p>


            <h1 id="program" className="subPage_title">Program</h1>
            <h2 className="subPage_subTitle">Q. Are all talks in Korean/English?</h2>
            <p className="subPage_contents">
                The talks are in either English or Korea, but don’t worry! Live interpretation into both languages will be provided.</p>

            <h2 className="subPage_subTitle">Q. Will the talks be captioned?</h2>
            <p className="subPage_contents">
                <b>There won’t be any English captions</b>. However, thanks to Mozilla Foundation, there will be Korean captions. You can view Korean captioning on the screens on stage or through the <a href="http://sharetalk.co.kr/#download">ShareTalk application</a>.</p>

            <h2 className="subPage_subTitle">Q. Will the talks be recorded and shared?</h2>
            <p className="subPage_contents">
                All talks given in Room 401 will be recorded and shared on <a href="https://www.youtube.com/channel/UCzoVCacndDCfGDf41P-z0iA">JSConf YouTube channel</a>.</p>

            <h2 className="subPage_subTitle">Q. Do you provide lunch?</h2>
            <p className="subPage_contents">
                Yes, we provide you with a voucher to be used at select restaurants in the Starfield COEX Mall.</p>

            <h2 className="subPage_subTitle">Q. Is there a childcare service?</h2>
            <p className="subPage_contents">
                JSConf Korea will provide a complimentary childcare service for any attendees. Please fill out <a href="https://docs.google.com/forms/d/e/1FAIpQLSeJGmHiHNRhSQINzibrIFJEp7nUsazJlqrpds5o3x3Yqw4_uw/viewform?usp=sf_link">this form</a> by August 16 to use the service.</p>


            <h1 id="get-involved" className="subPage_title">Get Involved</h1>
            <h2 className="subPage_subTitle">Q. How can I give a talk at JSConf Korea 2019?</h2>
            <p className="subPage_contents">
                Our talks are selected solely through our call for proposals process. This year's call for proposals has been closed since April 26, 2019.</p> 

            <h2 className="subPage_subTitle">Q. How can I become a sponsor for JSConf Korea 2019?</h2>
            <p className="subPage_contents">
                This year’s corporate sponsorship packages have been sold out.</p>

            <h2 className="subPage_subTitle">Q. Can I participate in JSConf Korea 2019 as a community?</h2>
            <p className="subPage_contents">
                We have very affordable sponsorship packages for JavaScript or tech-related communities! Please contact us at <a href="mailto:contact@jsconfkorea.com">contact@jsconfkorea.com</a> for inquiries.</p>


            <h1 id="policy" className="subPage_title">Policy</h1>
            <h2 className="subPage_subTitle">Q. Do you have a Code of Conduct?</h2>
            <p className="subPage_contents">
                Yes, you can check it out <a href="/en/coc">here</a>.</p>

            <h2 className="subPage_subTitle">Q. How were the talks selected?</h2>
            <p className="subPage_contents">
                Our talks were selected through our <a href="2019.jsconfkorea.com/en/call-for-proposals">Call for Proposals</a> process. Check out the Selection Process and Criteria section for how we selected our talks.</p>

            <h2 className="subPage_subTitle">Q. How were the tutorials selected?</h2>
            <p className="subPage_contents">
                We picked two of the top talk proposals from our <a href="http://2019.jsconfkorea.com/en/call-for-proposals">Call for Proposals</a> that could work very well as tutorials and decided to create the tutorial sessions.</p>

            <h2 className="subPage_subTitle">Q. Do you have a scholarship program?</h2>
            <p className="subPage_contents">
                Yes, we do have <a href="https://2019.jsconfkorea.com/en/scholarships">a scholarship program</a> designed to help members of underrepresented groups in the Korean tech community to attend JSConf Korea 2019. Applications for this year have ended.</p>

            <h2 className="subPage_subTitle">Q. Do you have terms and conditions for ticket holders? What happens to my data?</h2>
            <p className="subPage_contents">
                Our terms and conditions for the tickets can be found <a href="https://smallprint.tito.io/jsconf-korea/2019">here</a>. The collected data will be retained to email you information regarding the conference and for anonymized statistics until Dec 31, 2019 (KST).</p>

            <h2 className="subPage_subTitle">Q. What is the budget breakdown of JSConf Korea?</h2>
            <p className="subPage_contents">
                Our revenues come from sponsorships, scholarship funds, and ticket sales. We’ll release a summarized breakdown of our expenditures after the conference.</p>
            <br/>
            <br/>
            <p className="subPage_contents">
                <a href="#">🔼Back to top</a></p>
        </section>
    </PageWrapper>
);

export default FAQ
