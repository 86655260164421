import React from "react"
import PageWrapper from '../../../components/wrapper/pageWrapper'
import ShowCase from "../../../components/showcase"

const Elastic = () => (
    <PageWrapper locale="en" location="/sponsors/elastic">
        <ShowCase type="elastic"/>  
        <section className="subPage_contents_wrapper">
            <div className="sponsor_list sponsor_list_detail">
                <div className="sponsor_item">
                    <img className={`sponsor_img elastic`} alt="elastic" src={`/sponsors/logo_elastic.svg`}/>
                </div>
            </div>
            <p className="subPage_contents">Elastic is a search company. As the creators of the Elastic Stack (Elasticsearch, Kibana, Beats, and Logstash), Elastic builds self-managed and SaaS offerings that make data usable in real time and at scale for search, logging, security, and analytics use cases. Since its founding in 2012, there have been more than 350 million cumulative downloads of Elastic software. The Elastic community is 100,000 members strong. Thousands of organizations worldwide, including Cisco, eBay, Goldman Sachs, Microsoft, The Mayo Clinic, NASA, The New York Times, Wikipedia, and Verizon, use Elastic to power mission-critical systems. Elastic is a distributed company with more than 1,000 Elasticians working in 30 countries. Learn more at <a href="https://elastic.co" target="_blanc">elastic.co</a>.</p>
        </section>
    </PageWrapper>
)

export default Elastic