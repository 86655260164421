export default [
    {
        title: "Data/SIM",
        desc: `You can pick up a prepaid sim card with unlimited data for about 25 USD for 5 days at Incheon Airport at KT, SKT or LGU+ stalls. They’re also sometimes available at convenience stores in the airport as well. <a href="https://blog.trazy.com/survival-tip-all-about-sim-card-prepaid-sim-card-for-foreigners/" target="_blanc">More Info</a>`
    },
    {
        title: "Power (Socket)",
        desc: `Korea uses 220v/60Hz 2-pin type-C or type F plug. This is similar to what is used in Europe.`
    },
    {
        title: "Tipping",
        desc: `There is no need to tip anywhere in Korea.`
    },
    {
        title: "Communication",
        desc: `Everyone uses Korean in Korea but almost all the important signage in Korea also has English and usually Chinese and Japanese. At tourist attractions, there will be tourist information helpers that can always help you out.`
    },
    {
        title: "What to Bring Back Home",
        desc: `Usually, people like to buy face masks and Korean cosmetics to bring back home to their friends. Red ginseng and K-pop goods are also very popular.`
    },
    {
        title: "Getting Around",
        desc: `Seoul has great public transportation. The subways are clean and quick. Taxis are fairly inexpensive. Since Google Maps is mostly useless in Korea, get the Kakao Maps or Naver Maps applications to figure out directions.`
    },
    {
        title: "Taxi",
        desc: `Taxis are fairly inexpensive in Seoul and easy to find. Most taxi drivers will not understand english but you should be able to communicate with them using your phone. Try <a href="https://www.kakaocorp.com/service/KakaoT?lang=en" target="_blanc">Kakao Taxi</a> to hail cabs if you can’t find a cab on the street. Uber is not commonly used in Seoul. They just got un-banned recently so their service is not as widespread as other countries but you can give it a shot.`
    },
    {
        title: "Subway",
        desc: `There are many subway lines in Seoul. Most people orient themselves by the closest subway station. Try the <a href="https://www.kakaocorp.com/service/KakaoMetro?lang=en" target="_blanc">Kakao Metro</a> or Naver Map apps to view subway maps in English. The subway stations have numbered exits as well for easier navigation. You can view the map on your computer <a href="http://www.seoulmetro.co.kr/en/cyberStation.do" target="_blanc">here</a>.`
    },
    {
        title: "Currency",
        desc: `Korea is moving towards a mostly cashless society. Besides some street vendors, most places take credit cards. There are global ATMs available at most banks. Make sure they say “Global” somewhere on the ATM as not all ATMs can handle foreign cards.`
    },
    {
        title: "Coworking Spaces",
        desc: `If you need to do some work, Seoul has many coworking spaces such as WeWork and the local version, Fast Five. There are many more if you search online.`
    }
]