import PropTypes from "prop-types"
import React from "react"
import SpeakerDetail from "./speakerDetail"

import { StaticQuery, graphql } from "gatsby"
// eslint-disable-next-line
import { fluidImage } from "../graphql/image"

const Speaker = ({ type, speakers, locale, category }) => {
    const isDetail = type === "detail";
    const className = isDetail ? "speaker speaker_detail" :  "speaker";
    return (
        <StaticQuery query={graphql`
        query {
          jenn: file(relativePath: { eq: "speakers/profile_jenn.png" }) {
            ...fluidImage
          }
          jaeman: file(relativePath: { eq: "speakers/profile_jaeman.png" }) {
            ...fluidImage
          }
          jaeseok: file(relativePath: { eq: "speakers/profile_jaeseok.png" }) {
            ...fluidImage
          }
          istvan: file(relativePath: { eq: "speakers/prfoile_istvan.png" }) {
            ...fluidImage
          }
          vitalii: file(relativePath: { eq: "speakers/profile_vitalli.png" }) {
            ...fluidImage
          }
          wendy: file(relativePath: { eq: "speakers/profile_wendy.png" }) {
            ...fluidImage
          }
          jeonghoon: file(relativePath: { eq: "speakers/profile_jeonghoon.png" }) {
            ...fluidImage
          }
          gyujin: file(relativePath: { eq: "speakers/profile_gyujin.png" }) {
            ...fluidImage
          },
          willian: file(relativePath: { eq: "speakers/profile_willan.png" }) {
            ...fluidImage
          },
          isha: file(relativePath: { eq: "speakers/profile_isha.png" }) {
            ...fluidImage
          },
          jeonghyo: file(relativePath: { eq: "profiles/profile_jeonghyo.png" }) {
            ...fluidImage
          },
          aaron: file(relativePath: { eq: "speakers/profile_aaron.png" }) {
            ...fluidImage
          }
        }
      `}
      render={data => {
        return (
            speakers.map((speaker) => {
                const { id } = speaker;
                const imageData = data[id].childImageSharp.fluid;
                return (
                    <div className={className} key={id} id={id}>
                      <SpeakerDetail category={category} speaker={speaker} imageData={imageData} isDetail={isDetail} locale={locale}/>
                    </div>
                );
            })
        )
      }}/>
    );
}

Speaker.defaultProps = {
  category: "speaker"
};

Speaker.propTypes = {
    type: PropTypes.string,
    category: PropTypes.string,
    speakers: PropTypes.array,
    locale: PropTypes.string
}

export default Speaker