export default {
    title: "Attractions",
    desc: "",
    data: [
        {
            id: "MMCA",
            name: `<a href="https://www.mmca.go.kr/eng/" target="_blanc">MMCA</a> -Map(<a href="https://goo.gl/maps/sTbsbptpHgN2" target="_blanc">Link</a>)`,
            desc: `"The National Museum of Modern and Contemporary Art, Korea (MMCA) is a contemporary art museum" - <a href="https://en.wikipedia.org/wiki/National_Museum_of_Modern_and_Contemporary_Art" target="_blanc">Wiki</a>`
        },
        {
            id: "Gyeongbokgung",
            name: `<a href="https://bit.ly/2GuOec6" target="_blanc">Gyeongbokgung</a> - Map(<a href="https://goo.gl/maps/AnxdfiGNpJ72" target="_blanc">Link</a>)`,
            desc: `Gyeongbokgung also known as Gyeongbokgung Palace or Gyeongbok Palace, was the main royal palace of the Joseon dynasty. Built in 1395, it is located in the old city center of Seoul, South Korea. The largest of the Five Grand Palaces built during the Joseon dynasty, Gyeongbokgung served as the home of Joeson’s kings, the Kings' households, as well as the government of Joseon." - <a href="https://en.wikipedia.org/wiki/Gyeongbokgung" target="_blanc">Wiki</a>`
        },
        {
            id: "National Museum of Korea",
            name: `<a href="https://www.museum.go.kr/site/eng/home" target="_blanc">National Museum of Korea</a> - Map(<a href="https://goo.gl/maps/YCqd7S3owQ92" target="_blanc">Link</a>)`,
            desc: `"The National Museum of Korea is the flagship museum of Korean history and art in South Korea and is the cultural organization that represents Korea. Since its establishment in 1945, the museum has been committed to various studies and research activities in the fields of archaeology, history, and art, continuously developing a variety of exhibitions and education programs." - <a href="https://en.wikipedia.org/wiki/National_Museum_of_Korea" target="_blanc">Wiki</a>`
        },
        {
            id: "Bongunsa Temple",
            name: `Bongunsa Temple - Map(<a href="https://goo.gl/maps/fUAShUyAX8y" target="_blanc">Link</a>)`,
            desc: `Buddist temple in the middle of the city. Right outside Coex. Great place to get away from the city a little bit.`
        },
        {
            id: "Seoul Forest Park",
            name: `<a href="http://seoulforest.or.kr/english" target="_blanc">Seoul Forest Park</a> - Map(<a href="https://goo.gl/maps/hGL6zj3EAmw" target="_blanc">Link</a>)`,
            desc: `Seoul’s central park. A large green space with sculptures and even a deer park.`
        },
        {
            id: "Gwangjang Market",
            name: `<a href="https://bit.ly/2UJibKS" target="_blanc">Gwangjang Market</a> - Map(<a href="https://goo.gl/maps/K5ydXmW3oCv" target="_blanc">Link</a>)`,
            desc: `"Gwangjang Market, previously Dongdaemun Market, is a traditional street market in Jongno-gu, Seoul, South Korea. The market is one of the oldest and largest traditional markets in South Korea" - <a href="https://en.wikipedia.org/wiki/Gwangjang_Market" target="_blanc">Wiki</a>`
        },
        {
            id: "Hongdae",
            name: `Hongdae - Map(<a href="https://goo.gl/maps/b4sZYRQcjsr" target="_blanc">Link</a>)`,
            desc: `"Hongdae is a region in Seoul, South Korea near Hongik University, after which it is named. It is known for its urban arts and indie music culture, clubs and entertainments. Recommendation for K-POP fans" - <a href="https://en.wikipedia.org/wiki/Hongdae,_Seoul" target="_blanc">Wiki</a>`
        },
        {
            id: "Lotte Sky",
            name: `Lotte Sky - Map(<a href="https://goo.gl/maps/UJaTdMo8kWkWqHxdA" target="_blanc">Link</a>)`,
            desc: `An observation deck on the 6th tallest building in the world`
        },
        {
            id: "Gwanghwamun square",
            name: `Gwanghwamun square - Map(<a href="https://goo.gl/maps/EKHcMVed7G6cjXQq7" target="_blanc">Link</a>)`,
            desc: `"In 2009, the square was redeveloped into a space of historical revival, culture, and relaxation. The focal point of the square is the statue of King Sejong the Great. During the day, the square is always busy with people but at night it is a tranquil and beautiful tourist attraction. Gwanghwamun Square, with Inwangsan Mountain just behind it, is not just a beautiful tourist locale. The square has over 600 years of history and it is a place where the spirit of the Korean nation can be felt." - <a href="http://english.visitseoul.net/index" target="_blanc">Visit Seoul</a>`
        },
        {
            id: "Bukchon Hanok Village",
            name: `Bukchon Hanok Village - Map(<a href="https://goo.gl/maps/vsSq3wy7oHKpdtnZ9" target="_blanc">Link</a>)`,
            desc: `"Bukchon Hanok Village is a Korean traditional village in Seoul with a long history located on the top of a hill between Gyeongbok Palace, Changdeok Palaceand Jongmyo Royal Shrine. The traditional village is composed of lots of alleys, hanok and is preserved to show a 600-year-old urban environment." - <a href="https://en.wikipedia.org/wiki/Bukchon_Hanok_Village" target="_blanc">Wiki</a>`
        }
    ]
}