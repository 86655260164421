export default [
    {
        id: "jeonghyo",
        name: "정효",
        firstname: "정효",
        lastname: "이",
        desc: "프론트앤드 개발자로 프로그래밍을 이용한 시각예술을 꾸준히 해오고 있습니다. @doodlefingers 에서 작업물들을 보실 수 있습니다.  아트코딩에 입문하는 문턱을 낮추는데에 관심이 많습니다. 모두의 연구소 소속으로 인터랙티브 아트 랩을 운영하며 함께할 사람들을 찾아 깃발을 열심히 흔들고 있습니다.",
        title: "p5.js 맛보기: 함께 만들어보는 인터랙티브 뮤직비디오",
        talk: "p5.js는 아티스트, 디자이너, 교육자 및 초보자가 코딩할 수 있도록 개발된 자바스크립트 라이브러리입니다. HTML Canvas API에 대한 이해 없이도 p5.js에서 제공하는 간단한 함수들을 통하여 원하는 이미지를 그려낼 수 있습니다. 이 튜토리얼에선 p5.js 가 기본적으로 제공하는 기능들을 함께 살펴보고 이를 활용하여 어떻게 애니메이션과 인터랙션을 구현할 수 있는지 알아봅니다. 그리고 튜토리얼을 바탕으로 그린 그래픽들을 엮어 인터랙티브한 뮤직비디오를 함께 만들어보는 시간을 가집니다.<br/><br/><i>* 이 튜토리얼은 한국어로 진행됩니다.</i>",
        links: [
            {
                id: "https://turtlefingers.com/",
                type: "homepage",
                name: "@turtlefingers",
                url: "https://turtlefingers.com/"
            },
            {
                id: "@doodlefingers",
                type: "homepage",
                name: "@doodlefingers",
                url: "https://www.instagram.com/doodlefingers/"
            }
        ]
    },
    {
        id: "aaron",
        name: "에런 스노버거",
        firstname: "에런",
        lastname: "스노버거",
        desc: "프론트엔드 디자이너이자 개발자로서 백엔드나 모바일 기술도 가끔 건드립니다. 요즘은 Reat, React Native, Node, WP Rest API에 관심이 많습니다. 구글 인증 트레이너로서 2013년부터 고등학생들을 대상으로 컴퓨터 과학과 그래픽 디자인을 가르치고 있습니다. 자바스크립트의 세계에 새로이 입문하시는 분들이 그 재미를 발견하도록 돕는 일에 열정을 갖고 임하고 있습니다.",
        title: "코드펜에 쇼핑 앱 만들며 자바스크립트 배우기!",
        talk: "무엇이든 배우는 최고의 방법은 직접 해보는 것이죠. 자바스크립트를 배우는 최고의 방법도 프로젝트를 통해서입니다. 이 튜토리얼에서는 코드펜에서 쇼핑 앱을 만들어보며, 어떻게 자바스크립트를 통해 데이터와 DOM을 조작하여 동적인 멀티스크린 웹 앱을 만들 수 있는지 알아봅니다. HTML과 CSS로 앱 화면을 만드는 것 부터 시작하여 버튼 등 기본적인 자바스크립트 기능을 추가하고 데이터를 처리하기까지, 앱 제작 과정을 하나 하나 함께 살펴봅니다. 이 튜토리얼은 초보자를 대상으로 준비했습니다.<br/><br/><i>* 이 튜토리얼은 동시통역 없이 영어로 진행되지만 중급 수준의 한국어 소통도 가능합니다.</i>",
        links: [
            {
                id: "https://twitter.com/jekkilekki",
                type: "twitter",
                name: "@jekkilekki",
                url: "https://twitter.com/jekkilekki"
            },
            {
                id: "https://aaron.kr",
                type: "homepage",
                name: "aaron.kr",
                url: "https://aaron.kr"
            },
            {
                id: "https://github.com/jekkilekki",
                type: "github",
                name: "github.com/jekkilekki",
                url: "https://github.com/jekkilekki"
            }
        ]
    },
]