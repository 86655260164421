import React from "react"
import PageWrapper from '../../components/wrapper/pageWrapper'
import ShowCase from "../../components/showcase"

const BagelCode = () => (
    <PageWrapper locale="ko" location="/sponsors/bagelcode">
        <ShowCase type="bagelcode"/>
        <section className="subPage_contents_wrapper">
            <div className="sponsor_list sponsor_list_detail">
                <div className="sponsor_item">
                    <img className={`sponsor_img bagelcode`} alt="bagelcode" src={`/sponsors/logo_bagelcode.svg`}/>
                </div>
            </div>
            <p className="subPage_contents">베이글코드는 2012년 서울에서 시작해서 현재는 런던, 텔아비브 등의 지사를 포함, 전세계에서 100여명의 파트너들이 함께 일하고 있는 글로벌 게임회사입니다. <br/> 
            베이글코드의 게임은 앱스토어를 통해 전세계 200여개국에서 서비스 중이며 대표작은 클럽베가스로 누적가입자수 330만, 앱스토어 CASINO 장르 순위 TOP20등의 성과를 이루고 있습니다.</p>
        </section>
    </PageWrapper>
)

export default BagelCode