import React from "react"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
// eslint-disable-next-line
import { fluidImage } from "../graphql/image"
import { injectIntl } from "react-intl"


const Venue = (props) => {
    return (
        <StaticQuery query={graphql`
        query {
          coex: file(relativePath: { eq: "coex.png" }) {
            ...fluidImage
          }
        }
      `}
      render={data => {
        const imageData = data["coex"].childImageSharp.fluid;
        const { intl } = props;
        return (
            <>
                <Img fluid={imageData} />
                <p className="venue_comment">{intl.formatMessage({ id: "accessibility.reference"})}</p>
            </>
        )
      }}/>
    );
}

Venue.propTypes = {
  intl: PropTypes.object
}

export default injectIntl(Venue);