import React, {useState, useEffect} from "react"
import classnames from "classnames"
import { injectIntl } from "react-intl"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import Speakers from "./speakers";

export const Section = ({type, locale, intl, handleOpenModal}) => {
  const [speakers, setSpeakers] = useState([]);

  useEffect(() => {
    if(type === "speaker" || type === "tutorial") {
      const speakers = locale === "en" ? import(`../i18n/en/${type}s`) : import(`../i18n/ko/${type}s`);
      speakers.then(items => {
        setSpeakers(items.default);
      });
    }
  });
  
  const className = classnames({
    "section_contents": true,
    [`section_${type}`]: true
  });

  const _handleClick = () => {
    handleOpenModal();
  }

  const renderMoreBtn = () => {
    const loc = locale === "en" ? `/en/` : `/`;
    if(type === "subscribe") {
      return (
        <button className="button_default" onClick={_handleClick}>{ intl.formatMessage({ id: `${type}.buttonText`}) }</button>
      );
    } else if (type === "ticket") {
      return (<Link to={`${loc}ticket` } className="button_default">{ intl.formatMessage({ id: `${type}.buttonText`}) }</Link>);
    } else {
      return (
        <Link to={ intl.formatMessage({ id: `${type}.link`}) } className="button_default">{ intl.formatMessage({ id: `${type}.buttonText`}) }</Link>
      );
    }
  }

  const renderDetail= () => {
    if (type === "speaker" || type === "tutorial") {      
      return (
        <div className="speaker_wrapper main">
          <Speakers speakers={speakers} category={type}/>
        </div>
      )
    } else {
      return (
        <>
        <strong className="section_copy" dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: `${type}.copy`}) }}></strong>
        <p className="section_desc">
          { intl.formatMessage({ id: `${type}.desc`}) }
        </p>
        { renderMoreBtn() }
        </> 
      )
    }
  }

  return (
    <section className={ className }>
      <div className="contents_wrapper">
        <h2 className="section_title">{ intl.formatMessage({ id: `${type}.title`}) }</h2>
        { renderDetail() }
      </div>
    </section>
  );
}

Section.propTypes = {
  type: PropTypes.string,
  locale: PropTypes.string,
  intl: PropTypes.object,
  handleOpenModal: PropTypes.func
}

export default injectIntl(Section);
