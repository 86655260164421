import React from "react"
import PropTypes from "prop-types"
class P5Wrapper extends React.Component {
	componentDidMount() {
		const p5 = require("../../lib/p5");
		window.sketchComponent = new p5(this.props.sketch);
	}

	componentWillUnmount() {
		window.sketchComponent.remove();
	}

	render() {
		return <></>
	}
}

P5Wrapper.propTypes = {
	sketch: PropTypes.func
}
export default P5Wrapper