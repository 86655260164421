export default [
    {
        id: "ysm",
        name: "Chris Yang",
        image: "profile_ysm.png",
        desc: "Chris is a Software Engineer who's passionate about JavaScript and UX/UI design. He really loves travel, photography, movies and animals. 🐶",
        links: [
            {
                id: "website",
                url: "https://ysm.sh/"
            }
        ]
    },
    {
        id: "godori",
        name: "Eunjeong Ko (Godori)",
        image: "profile_godori.png",
        desc: "Godori is a software engineer who loves web developing because she can rapidly make a prototype of service with it. She’s  interested in making and using productivity-enhancing tools. Godori believes that technology can help people and make them happy.",
        links: [
            {
                id: "github",
                url: "https://github.com/godori"
            }
        ]
    },
    {
        id: "holden",
        name: "Holden Choi",
        image: "profile_holden.png",
        desc: "Holden brings years of experience as an American English user to his role as copy desk chief. In spite of an unorthodox background (Strunk & White remains unread), Holden has edited everything from his brother's history papers to internal memos at a prestigious Washington <strike>lobbying</strike> government relations firm.",
        links: [
            {
                id: "blog",
                url: "https://medium.com/@chylirk"
            }
        ]
    },
    {
        id: "jane",
        name: "Jane (Jeong Eun) Lee",
        image: "profile_jane.png",
        desc: "Jane is a front-end engineer who’s always looking to grow. Jane likes algorithms, travelling and finding great restaurants. Ever since she attended JSConf EU last year, Jane has wished for a conference like it in Korea. She joined the JSConf Korea team to do just that.",
        links: [
            {
                id: "@snooje",
                url: "https://twitter.com/snooje/"
            },
            {
                id: "instagram",
                url: "https://www.instagram.com/snooje/"
            }
        ]
    },
    {
        id: "jeonghyo",
        name: "Jeonghyo",
        image: "profile_jeonghyo.png",
        desc: "",
        links: [
            {
                id: "website",
                url: "https://turtlefingers.com/"
            }
        ]
    },
    {
        id: "jyhwang",
        name: "Jiyoung Hwang",
        image: "profile_jyhwang.png",
        desc: "Frontend/web developer. JSConf EU 2018 was the most fun, festive, and diversity respecting conference I’ve ever attended. It is so exciting to finally see it happen in Korea",
        links: [
            {
                id: "@jyhwng",
                url: "https://twitter.com/jyhwng/"
            }
        ]
    },
    {
        id: "jin",
        name: "Jin Park",
        image: "profile_sona.png",
        desc: "Software engineer working with web and mobile.",
        links: [
            {
                id: "website",
                url: "https://www.jinpark.net/"
            }
        ]
    },
    {
        id: "seol",
        name: "Seol Jeon",
        image: "profile_seol.png",
        desc: "Seol is a senior front-end engineer with many hobbies, a love of travelling, and an expertise in HTML/CSS. Seol is delighted to help JSConf Korea take its first big step.",
        links: []
    },
    {
        id: "seungwoo",
        name: "Seungwoo Lee",
        image: "profile_seungwoo.png",
        desc: "Site Reliability Engineer who loves programming and designing insfrastructure.",
        links: [
            {
                id: "blog",
                url: "https://blog.2dal.com/"
            }
        ]
    },
    {
        id: "yoonji",
        name: "Yoonji Lee",
        image: "profile_yoonji.png",
        desc: "Yoonji creates space for a living and creates things on the side. She’s excited to be part of JSConf Korea’s big first step!",
        links: [
            {
                id: "@yjayl33",
                url: "https://www.instagram.com/yjayl33"
            }
        ]
    }
]