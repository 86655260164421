export default [
    {
        id: "jenn",
        name: "젠 크레이튼",
        firstname: "젠",
        lastname: "크레이튼",
        desc: "프론트엔드 엔지니어이자 컨퍼런스 연사입니다. 고양이 둘, 강아지 한 마리와 뉴욕에서 살며 버려진 다육이, 외면당한 식물 등 대체로 생기를 잃어가는 초록이들의 집을 가꾸고 있습니다. 젠은 @gurlcode에서 만나보실 수 있습니다.",
        title: "다마고치로 배우는 제너레이터",
        talk: "90년대 최애템 다마고치, 그리고 비동기 태스크를 위한 일시중지가 가능한 함수인 제너레이터. 이 두가지가 어떤 연관이 있을까요? 바로 둘다 가지고 놀기 정말 재밌다는 점입니다. 최근 개인 프로젝트로 웹 다마고치를 만드는 중 중요한 지점에서 계속 막혔습니다. 바로 연쇄적인 애니메이션을 처리하는 부분이었습니다. 다른 애니메이션이 완료된 사실을 현재 애니메이션에게 어떻게 알릴까요? 애니메이션을 큐잉해뒀는데 사용자 이벤트가 처리되어야 할 때, 어떡할까요? 이 발표에서는 제가 잘못 들은 길(힌트: 프로미스)과 제대로 간 길(힌트: 제너레이터)을 살펴봅니다. 이 발표를 통해 어떻게 프로미스, 제너레이터, 코루틴을 조합하여 비동기 태스크를 수행할 수 있는지 확실히 이해하고 가실 수 있습니다.",
        links: [
            {
                id: "twitter.com/gurlcode",
                url: "https://twitter.com/gurlcode",
                type: "twitter",
                name: "@gurlcode"
            }
        ]
    },
    {
        id: "jeonghoon",
        name: "변정훈 aka Outsider",
        firstname: "정훈",
        lastname: "변",
        desc: "현재 ODK Media에서 SRE로 일하고 있으며 오픈소스를 좋아하는 개발자로 현재 JavaScript 테스트 프레임워크인 mocha의 메인테이너로 참여하고 있습니다. 종종 개발 관련 글도 쓰고 있습니다.   ",
        title: "오픈소스 프로젝트 mocha에서 배운 것들",
        talk: "개인 오픈소스 프로젝트도 운영해 보고 다른 프로젝트에 기여도 해봤지만 사용자가 많은 JavaScript 테스트 프레임워크인 mocha의 메인테이너로 1년간 참여하면서 새롭게 배운점을 들려드립니다. 작지 않은 오픈소스 프로젝트에서 메인테이너들이 프로젝트를 어떻게 관리하고 협업하는지에 대해 설명하고 mocha를 유지보수하면서 어려웠던 부분과 배운 것들을 소개하려고 합니다.",
        links: [
            {
                id: "twitter.com/outsideris",
                url: "https://twitter.com/outsideris",
                type: "twitter",
                name: "@outsideris"
            },
            {
                id: "github.com/outsideris",
                url: "https://github.com/outsideris",
                type: "github"
            },
            {
                id: "blog.outsider.ne.kr",
                url: "https://blog.outsider.ne.kr",
                type: "homepage"
            }
        ]
    },
    {
        id: "wendy",
        name: "웬디 궈",
        firstname: "웬디",
        lastname: "궈",
        desc: "LA에 살고있는 소프트웨어 엔지니어입니다. 전자공학도로서 시작해 4년 전 프론트엔드 웹 개발자로 커리어를 전환했고, 기술이라면 뭐든 관심이 많습니다. 개발 외에도 여행을 다니거나 위대한 자연을 탐방하거나 장난꾸러기 아들을 쫓아다니는 일을 즐겨 합니다.",
        title: "축하합니다! 당신의 스타트업에서 처음으로 아이를 갖게 되셨습니다. 이제 어떡하죠?",
        talk: "임신과 출산은 삶을 송두리째 바꿉니다. 게다가 우리 회사에 아이를 가진 직원이 나밖에 없다면 그 변화가 더 어렵게 느껴질 수 있습니다. 이 발표에서는 전 직장(스타트업)에서 처음으로 육아휴직을 신청하는 직원으로서 제가 어떤 노력을 했는지, 어떻게 제 권리를 위해 목소리를 높였는지 들려드리고, 이 어려운 상황을 어떻게 내 것으로 만들 수 있는지 보여드립니다.</br></br>스타트업에 다니면서 아이를 갖고싶으시거나 (입양도 포함!) 출산을 앞두신 분, 또는 그런 동료의 든든한 지지자가 되고싶으신 분들께 바칩니다. ",
        links: [
            {
                id: "twitter.com/wendygwo",
                url: "https://twitter.com/wendygwo",
                type: "twitter",
                name: "@wendygwo"
            },
            {
                id: "github.com/wendygwo",
                url: "https://github.com/wendygwo",
                type: "github"
            }
        ]
    },
    {
        id: "jaeman",
        name: "안재만",
        firstname: "재만",
        lastname: "안",
        desc: "현재 AITRICS라는 의료 인공지능 회사에 재직하고 있으며, 최신 기술을 통해 사람들의 삶을 실제로 혁신하는 소프트웨어를 만드는 것에 관심이 많습니다. 이전에는 1000만 DAU의 모바일 게임 서버 개발, VR 및 물리엔진 개발 등 잡다한 분야에서 개발자로 일을 하였습니다.",
        title: "생명을 살리는 자바스크립트와 인공지능",
        talk: "자바스크립트, 인공지능, 그리고 소프트웨어 개발. 세 기술의 조합은 수많은 가능성을 제안합니다. 이 발표에서는 이를 이용해 환자들의 급성 질환을 예측하여 생명을 살리는 소프트웨어를 만든 이야기를 들려드립니다.</br></br>인공지능 솔루션의 컴포넌트는 백엔드, 프론트엔드, 모바일앱은 물론, 인공지능 모델과 데이터 수집 및 분석 기능도 포함합니다. 이를 구성하기 위해 어떻게 Vue.js, React Native, Electron, TypeScript, 서버사이드 자바스크립트 등 다양한 자바스크립트 기술을 이용했는지 소개합니다. 또한, 인공지능이 적용된 의료 솔루션을 구성하면서 발생하는 안전, 프라이버시, 설명가능성 등의 문제와 그 해결 방법에 대해 설명합니다. 끝으로 자바스크립트로 만든 솔루션이 의료현장에 가져오는 실질적인 기여와 혁신에 대해 들려드리고자 합니다.",
        links: [
            {
                id: "github.com/kuss",
                url: "https://github.com/kuss",
                type: "github",
                name: "@kuss"
            }
        ]
    },
    {
        id: "vitalii",
        name: "비탈리 보브로프",
        firstname: "비탈리",
        lastname: "보브로프",
        desc: "6년 이상의 경험을 가진 자바스크립트 엔지니어 입니다. Angular Wroclaw meetup의 오거나이저이기도 합니다. 최신 웹 플랫폼 기능을 갖고 실험적인 시도를 하고있습니다. 테크스피커이자 ngGirls의 멘토이자, 훌륭한 딸의 아버지이기도 합니다.",
        title: "<a href='https://www.youtube.com/watch?v=fregObNcHC8' target='_blanc'>The Man who Sold the Amp</a>: 자바스크립트로 음악 처리하기",
        talk: "먼지 뒤덮힌 케이스에서 레스폴이나 스트라토캐스터를 꺼내 따끈한 리프로 이곳을 가득 채워보세요. 커크 해밋의 와우와우, 스티비 레이 본의 오버드라이브, 그리고 커트 코베인의 디스토션을 전부 코드로 만들어내는 법을 보여드립니다. 또한 자바스크립트와 웹 오디오 API를 이용해 실시간으로 오디오 인풋을 파싱하는 법도 알려드립니다.</br></br>이 발표에서는 제가 직접 연주하며 모든 예제 코드를 데모합니다. WebRTC를 통해 세계 곳곳의 친구들과 함께 연주하기도 합니다! 페달 음향 효과의 기초와 어떻게 이를 코드로 생성하는지 배워가실 수 있습니다. Let's rock the Web 🤘!",
        links: [
            {
                id: "twitter.com/bobrov1989",
                url: "https://twitter.com/bobrov1989",
                type: "twitter",
                name: "@bobrov1989"
            },
            {
                id: "github.com/vitaliy-bobrov",
                url: "https://github.com/vitaliy-bobrov",
                type: "github"
            },
            {
                id: "bobrov.dev",
                url: "https://bobrov.dev",
                type: "homepage"
            }
        ]
    },
    {
        id: "gyujin",
        name: "조규진",
        firstname: "규진",
        lastname: "조",
        desc: "루닛에서 인공지능을 활용한 의료 서비스의 프론트엔드 개발을 맡고 있습니다. 깔끔하고 선언적인 코드에 매료되어 멋모르고 함수형 프로그래밍의 세계에 발을 담갔다가, 갖가지 어려움에 부딪히고 마구 틀리면서 성장하고 있습니다. 필요한 정보를 정확하고 효과적이며 우아하게 전달하는 인터페이스를 만들고 싶습니다.",
        title: "함수형, Redux와 Canvas에 적용하면서 배워나가기",
        talk: "함수형 프로그래밍은 어떤 걸까요? 좋다는 소문은 많은데 대체 어떤 점이 좋은 걸까요? 책을 읽거나 스터디를 해보면 '선언적이고, 부수 효과가 없고, 재사용성이 좋다'고 합니다. 하지만 ‘그래서 이걸 언제 어떻게 쓰지?'에 대한 답은 알기 어렵습니다. <br/><br/>함수형에 대한 막연한 동경을 가진 채로 Redux와 Canvas에 적용해 보면서 '언제 어떻게 쓰는지' 조금씩 알게 된 경험을 나누려고 합니다. Ramda.js가 왜 lodash.js에 비해 함수 조합에 더 유리한지, 구현할 때는 이렇게까지 할 필요가 있나 싶지만 앱의 많은 부분을 함수형으로 짜 보니 어떤 점이 좋은지, 구현 과정에서 어떤 어려움을 겪었고, 이를 어떻게 헤쳐나갔는지 이야기합니다.",
        links: [
            {
                id: "github.com/gyujincho",
                url: "https://github.com/gyujincho",
                type: "github",
                name: "@gyujincho"
            }
        ]
    },
    {
        id: "jaeseok",
        name: "강재석",
        firstname: "재석",
        lastname: "강",
        desc: "좋은 사용자 경험을 만들기 위해 고민하는 웹 프론트엔드 개발자입니다. 생산성, 효율적인 커뮤니케이션, 함께 성장할 수 있는 환경 등 성숙한 개발 문화 만들기에도 관심이 많습니다.",
        title: "Don't block the event loop! 매끄러운 경험을 위한 자바스크립트 비동기 처리",
        talk: "화면이 멈추거나, 애니메이션이 버벅거리거나, 클릭 혹은 스크롤 등이 제때 반응하지 않는다거나하는 현상은 서비스의 사용성, 사용자 경험을 해치는 요소들이 될 수 있습니다. 이러한 현상들과 그 원인을 자바스크립트 엔진의 구조, task, call-stack, event-loop 개념 중심으로 알아보고 이를 해결하기 위한 몇가지 방법들을 소개합니다. 이해를 돕기 위해, 이슈가 발생하는 상황을 가정한 데모를 활용해 소개한 해결방법들이 어떻게 개선시켜 나가는지도 함께 설명합니다.",
        links: [
            {
                id: "twitter.com/kang89kr",
                url: "https://twitter.com/kang89kr",
                type: "twitter",
                name: "@kang89kr"
            },
            {
                id: "github.com/jaeseokk",
                url: "https://github.com/jaeseokk",
                type: "github"
            },
            {
                id: "jaeseokk.dev",
                url: "https://jaeseokk.dev",
                type: "homepage"
            }
        ]
    },
    {
        id: "willian",
        name: "윌리언 마르틴쉬",
        firstname: "윌리언",
        lastname: "마르틴쉬",
        desc: "eBay에서 JS 포맷팅과 CSS를 만지작 거리는 일을 담당합니다. 브라질의 상파올로에서 왔지만 베를린에서 살고있습니다. 심레이싱 게이머이자 축구 팬이기도 합니다. #TeamPS4 #mozTechSpeaker 🏎⚽️🕹🇧🇷",
        title: "백 투 더 퓨처 오브 자바스크립트 II: 미래를 넘어",
        talk: "여러분이 필요한지도 몰랐을 새 자바스크립트 기능을 소개합니다. 바로 패턴 매칭과 파이프라인 연산자, 그리고 바이너리 AST(추상구문트리)입니다. 스프레드 연산자가 그랬듯, 이 기능들이 어떻게 우리의 코딩 방식을 바꿔놓을지 보여드린 후, TC39가 이를 추진할 수 있도록 여러분이 도울 수 있는 방법을 알려드립니다.<br/><br/>여러분은 자바스크립트에서 어떤 기능이 보고싶으신가요?",
        links: [
            {
                id: "twitter.com/wmsbill",
                url: "https://twitter.com/wmsbill",
                type: "twitter",
                name: "@wmsbill"
            }
        ]
    },
    {
        id: "istvan",
        name: "이슈트반 스모잔스키 aka Flaki",
        firstname: "이슈트반",
        lastname: "스모잔스키 aka Flaki",
        desc: "자바스크립트 엔지니어이이자 교육가이며 모질라 테크 스피커입니다. 모질라의 DevRel 팀과 함께 오픈 웹 플랫폼을 홍보하고 이를 모두에게 접근 가능하도록 만드는 일을 하고 있습니다. 자바스크립트의 내부 구조에 관심이 많고, 마이크로컨트롤러를 (정말 말 그대로) 갖고 노는 것을 즐깁니다.",
        title: "적어도 캡 70개 어치는 하는 웹어셈블리 지침서",
        talk: "요즘 따라 지하 세계의 삶이 유난히 힘들어지고 있습니다. 온갖 수난이 잇따르고 최근에는 볼트의 상수도에서 문제가 터진 이후, 당신은 볼트 최고기술자로 임명됩니다. 그러나 10년된 이 지하 서식지에서 문서란 희귀한 존재입니다. 마침내 어느 묵직한 사용 설명서를 당신의 손에 쥐었을 때, 당신은 놀랄 수 밖에 없습니다. 이 문서는 웹어셈블리라는 기술이 상호 연결된 컴퓨터들간의 일종의 언어로 시작해 지구상 모든 기기의 보편적인 운영 토대로 진화한 유서깊은 역사를 담고 있기 때문입니다. 지난 수십년간 당신과 당신의 동지들을 위협으로부터 보호해 온 기계들도 이 기술을 기반으로 한다는 것을 알게되는 당신. 이제 볼트에서 삶을 당신의 손으로 바꿀 수 있을지...",
        links: [
            {
                id: "twitter.com/slsoftworks",
                url: "https://twitter.com/slsoftworks",
                type: "twitter",
                name: "@slsoftworks"
            },
            {
                id: "github.com/flaki",
                url: "https://github.com/flaki",
                type: "github"
            },
            {
                id: "musings.flak.is",
                url: "https://musings.flak.is",
                type: "homepage"
            }
        ]
    },
    {
        id: "isha",
        name: "이샤 카슬리왈",
        firstname: "이샤",
        lastname: "카슬리왈",
        desc: "트위치의  디자인 시스템 팀에서 시니어 UI/UX 디자이너 및 개발자로 일하고 있습니다. 창의적인 사고를 좋아하고 코드, 소통, 접근성을 중요시 여깁니다. 주말에는 어딘가로 모험을 떠나거나 맛있는걸 먹으러 다니기를 좋아합니다.",
        title: "Typescript로 디자인 시스템에 날개 달기",
        talk: "디자인 시스템은 디자인과 개발 사이의 격차를 줄이는 데 아주 유용합니다. React.js는 디자인 시스템에 함수형 프로그래밍의 개념을 도입하며 재사용성과 확장성 있는 컴포넌트를 만드는데 도움을 주었는데요, 여기서 한 발 더 나아간다면 어떻게 될까요? 자바스크립트를 확장한 언어로서 React.js와도 함께 사용할 수 있는 Typescript는 여러분의 디자인 시스템에 새로운 차원의 효율성과 명확성을 가져다 줄 수 있습니다. 이 발표에서는 UI 엔지니어링 초보자부터 경력자까지 Typescript의 힘으로 어떻게 보다 나은 컴포넌트 표준을 구현하고, 어떻게 대규모 자바스크립트 프로젝트를 성공으로 이끌 수 있는지 알아봅니다. 나아가 여러분의 코드베이스에 바로 적용할 수 있는 아이디어도 얻어가실 것입니다.",
        links: [
            {
                id: "twitter.com/ikasliwal",
                url: "https://twitter.com/ikasliwal",
                type: "twitter",
                name: "@ikasliwal"
            },
            {
                id: "github.com/ishakasliwal",
                url: "https://github.com/ishakasliwal",
                type: "github"
            },
            {
                id: "www.ikasliwal.com",
                url: "https://www.ikasliwal.com",
                type: "homepage"
            }
        ]
    },
]