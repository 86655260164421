import React from "react"
import PageWrapper from "../../components/wrapper/pageWrapper"
import ShowCase from "../../components/showcase"
import Speaker from "../../components/speakers";

import speakers from "../../i18n/en/speakers";

const Speakers = () => (
  <PageWrapper locale="en" location="/speakers">
    <ShowCase type="speaker"/>
    <section className="subPage_contents_wrapper">
      <div className="speaker_wrapper">
          <Speaker type="detail" speakers={speakers} locale="en"/>
      </div>
      <br/>
    </section>
  </PageWrapper>
);

export default Speakers