import React from "react"
import PageWrapper from '../../components/wrapper/pageWrapper'
import ShowCase from "../../components/showcase"

const Jetbrains = () => (
    <PageWrapper locale="ko" location="/sponsors/jetbrains">
        <ShowCase type="jetbrains"/>
        <section className="subPage_contents_wrapper">
            <div className="sponsor_list sponsor_list_detail">
                <div className="sponsor_item">
                    <img className={`sponsor_img jetbrains`} alt="jetbrains" src={`/sponsors/logo_jetbrains.svg`}/>
                </div>
            </div>
            <p className="subPage_contents">
            JetBrains는 JavaScript, Java, Kotlin, C#, C++, Ruby, Python, PHP 를 포함한 많은 언어의 코딩을 위한 전문 소프트웨어 개발 도구와 고급 팀 협업 도구를 개발 및 제공하고 있습니다. <br/>
            JetBrains에게 있어, 코드는 우리의 열정입니다. 지난 18년이 넘는 시간동안, 우리는 지구상에서 가장 강력하고 효과적인 개발 도구를 만들기 위해 노력해 오고 있습니다. JetBrains 도구는 일상적인 점검과 수정을 자동화함으로써 생산선을 높이고 개발자들이 성장하고, 발견하고, 창조할 수 있도록 합니다. <br/>
            현재 1,000명 이상의 JetBrains 직원이 프라하, 뮌헨, 상트페테부르크, 모스크바, 보스턴 및 노보시비르스크에서 근무하고 있습ㅈ니다. 225개국의 5백만 명 이상의 고객이 매일 우리의 도구를 신뢰하고 사용하고 있습니다. JetBrains가 개발한 프로그래밍 언어인 Kotlin은 2017 년에 안드로이드 개발의 공식 언어가 되었습니다.
            </p>
        </section>
    </PageWrapper>
)

export default Jetbrains