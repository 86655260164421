import PropTypes from "prop-types"
import React from "react"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"
// eslint-disable-next-line
import { fluidImage } from "../graphql/image"

const Team = ({ members }) => {
    const intro_url = (links) => {
        const info_urls = links.map((link) => {
            const { id, url } = link;
            return ( 
                <a className="profile_link" key={link.id} href={url} target="_blanc">{id}</a>
            );
        });

        return info_urls;
    };

    return (
        <StaticQuery query={graphql`
        query {
          sona: file(relativePath: { eq: "profiles/profile_sona.png" }) {
            ...fluidImage
          }
          suzin: file(relativePath: { eq: "profiles/profile_suzin.png" }) {
            ...fluidImage
          }
          kukhwan: file(relativePath: { eq: "profiles/profile_kukhwan.png" }) {
            ...fluidImage
          }
          eun: file(relativePath: { eq: "profiles/profile_eun.png" }) {
            ...fluidImage
          }
          jane: file(relativePath: { eq: "profiles/profile_jane.png" }) {
            ...fluidImage
          }
          seol: file(relativePath: { eq: "profiles/profile_seol.png" }) {
            ...fluidImage
          }
          holden: file(relativePath: { eq: "profiles/profile_holden.png" }) {
            ...fluidImage
          }
          jyhwang: file(relativePath: { eq: "profiles/profile_jyhwang.png" }) {
            ...fluidImage
          }
          jin: file(relativePath: { eq: "profiles/profile_jin.png" }) {
            ...fluidImage
          }
          seungwoo: file(relativePath: { eq: "profiles/profile_seungwoo.png" }) {
            ...fluidImage
          }
          jeonghyo: file(relativePath: { eq: "profiles/profile_jeonghyo.png" }) {
            ...fluidImage
          }
          seungkyu: file(relativePath: { eq: "profiles/profile_seungkyu.png" }) {
            ...fluidImage
          }
          yoonji: file(relativePath: { eq: "profiles/profile_yoonji.png" }) {
            ...fluidImage
          }
          ysm: file(relativePath: { eq: "profiles/profile_ysm.png" }) {
            ...fluidImage
          }
          godori: file(relativePath: { eq: "profiles/profile_godori.png" }) {
            ...fluidImage
          }
        }
      `}
      render={data => {
        return (
            members.map((member) => {
                const { id, name, desc, links } = member;
                const imageData = data[id].childImageSharp.fluid;
                return (
                    <div id={id} className="profile" key={id}>
                        <Img className="profile_thumbnail" fluid={imageData} />
                        <div className="profile_desc">
                            <span className="profile_name">{name}&nbsp;&nbsp;</span>
                            { intro_url(links) }
                            <span className="profile_introduce" dangerouslySetInnerHTML={{ __html: desc }}></span>
                        </div>
                    </div>
                );
            })
        )
      }}/>
    );
}

Team.propTypes = {
    members: PropTypes.array
}

export default Team