import React from "react"
import PageWrapper from '../../components/wrapper/pageWrapper'
import ShowCase from "../../components/showcase"

const Hyperconnect = () => (
    <PageWrapper locale="ko" location="/sponsors/hyperconnect">
        <ShowCase type="hyperconnect"/>
        <section className="subPage_contents_wrapper">
            <div className="sponsor_list sponsor_list_detail">
                <div className="sponsor_item">
                    <img className={`sponsor_img hyperconnect`} alt="hyperconnect" src={`/sponsors/logo_hyperconnect.svg`}/>
                </div>
            </div>
            <p className="subPage_contents">
            하이퍼커넥트는 비디오와 AI기술을 바탕으로 전 세계 사람들을 연결해 새로운 사회적·문화적 가치를 창출한다는 미션을 이뤄가고 있는 기술 회사입니다. 설립 5년만에 1,045억의 매출을 달성하며 50배가 넘는 성장을 기록했고, 매출의 90% 이상을 해외에서 거둬들이는 국내 스타트업의 대표적인 글로벌 성공 사례로 꼽힙니다. 대표 서비스인 아자르는 3억 다운로드, 전 세계 비게임앱 매출 5위를 기록한 비디오 기반 소셜 디스커버리 앱으로, 현재 230개 국가에서 19개 언어로 서비스되며 매일 7천만 건의 새로운 연결을 만들어내고 있습니다. <br/>
            하이퍼커넥트는 자체 기술 개발을 중시하고, 혁신 기술을 기반으로 제품을 만들어가는 Technology-Driven Company 입니다. WebRTC를 모바일 플랫폼에 최초로 적용해 탄생한 '아자르'를 시작으로, 머신러닝 기반의 이미지·영상분석 등 다양한 최신 기술을 빠르게 도입 및 자체 개발하고 있습니다. 또한, 차세대 프로덕트 개발 스튜디오인 하이퍼X(Hyper-X)를 통해 기술기반 아이디어를 끊임없이 테스트하며 글로벌 시장을 향한 과감한 도전을 이어나가고 있습니다.
            </p>
        </section>
    </PageWrapper>
)

export default Hyperconnect