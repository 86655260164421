/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// let isClickedAlready = false;
exports.onRouteUpdate = (data) => {
    const { location } = data;
    if (location.hash) {
      setTimeout(() => {
        document.querySelector(`${location.hash}`).scrollIntoView({ block: "start",  behavior: "smooth" });
      }, 0);
    }
    // const userLocale = navigator.language || navigator.userLanguage || navigator.browserLanguage;
    // if (!isClickedAlready && !userLocale.toLowerCase().startsWith("ko")) {
    //     document.querySelector(".language[href='/en/']").click();
    //     isClickedAlready = true;
    // }
};
