import React from "react"
import PageWrapper from "../../components/wrapper/pageWrapper"
import ShowCase from "../../components/showcase";
import Team from "../../components/team";
import { Link } from "gatsby"

import organizers from "../../i18n/en/organizers";
import staff from "../../i18n/en/staff";
import mcs from "../../i18n/en/mcs";

import alumni from "../../i18n/en/alumni"

import Venue from "../../components/venue";


const About = () => (
  <PageWrapper locale="en" location="/about">
    <ShowCase type="about"/>
    <section className="subPage_contents_wrapper">
      <h1 className="subPage_title">About</h1>
      <p className="subPage_contents">Welcome to the very first Korean edition of JSConf!</p>
      <br />
      <p className="subPage_contents">JSConf Korea is a community conference that pushes the boundaries of what can be created using JavaScript, and an opportunity for people with diverse experiences to share their stories and positive energy with each other.</p>
      <br />
      <p className="subPage_contents">Our mission is to make the Korean technology community more daring, more diverse, and more human; in short, we just want to make things better.</p>
      
      <h1 className="subPage_title">Our Program</h1>
      <p className="subPage_contents">The conference takes place on <b>Tuesday, September 3, 2019 from 10am to 6pm</b> in <b>Seoul, Korea</b>, at the international convention center COEX.</p><br/>
      <p className="subPage_contents">The day will be full of <a href="/en/speakers">talks</a> as diverse as the JavaScript ecosystem, <a href="/en/tutorials">tutorials</a> for a fun and hands-on learning experience, as well as booth programs and lightning talks by our <a href="/en/sponsors">sponsor companies and communities</a>.</p><br/>
      <p className="subPage_contents">Check out the complete schedule <a href="/en/schedule">here</a>!</p><br/>
      <Venue />


      <h1 className="subPage_title">Conference Accessibility</h1>
      <p className="subPage_contents">
        JSConf Korea strives to be an accessible and inclusive conference. See our <Link to="/en/accessibility">accessibility page</Link> to learn about the services we provide and the environment in and around the conference space.
      </p>

      <h1 className="subPage_title">Seoul</h1>
      <p className="subPage_contents">Seoul is a vast and vibrant city of art, technology, and two thousand years of history. When you’re not attending the conference, explore the city with its ultra-efficient subway system that will get you nearly anywhere, including:</p>

      <div className="listing_block notice_list">
        <ul className="subPage_list">
          <li className="subPage_list_item">Cafes and pubs in Hanoks with a contemporary spin,</li>
          <li className="subPage_list_item">K-beauty stores with an endless variety of facemasks,</li>
          <li className="subPage_list_item">Muk-bang-worthy food destinations,</li>
          <li className="subPage_list_item">Hiking trails for a half day of hiding away in the nature,</li>
          <li className="subPage_list_item">Well-curated history and art museums</li>
        </ul>
      </div>
      <p className="subPage_contents">Here is a <Link to="/en/seoul"><b>list of the best attractions, cafes, and pubs in Seoul</b></Link>, personally curated by Sona and Jin, the coffee, beer, and fun connoisseurs. </p>

      <h1 className="subPage_title">Our Team</h1>
      <p className="subPage_contents">JSConf Korea is a not-for-profit conference made possible by a team of volunteers.</p>
      <br/>
      <h2 className="subPage_subTitle">Organizers</h2>
      <Team members={organizers} />  
    
      <h2 className="subPage_subTitle">Alumni</h2>
      <Team key="alumni" members={alumni} />

      <h2 id="mcs" className="subPage_subTitle">MCs</h2>
      <Team key="mcs" members={mcs} />

      <h2 className="subPage_subTitle">Staff</h2>
      <Team key="staff" members={staff} />
      
    </section>
  </PageWrapper>
)

export default About