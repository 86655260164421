export default [
    {
        id: "godori",
        name: "고은정(Godori)",
        image: "profile_godori.png",
        desc: "빠른 프로토타이핑이 가능한 웹 개발을 좋아하고 생산성 도구나 이를 만드는 데 관심이 많은 소프트웨어 엔지니어입니다. 기술로 누군가를 도와주고 즐겁게 만들 수 있다고 믿습니다.",
        links: [
            {
                id: "github",
                url: "https://github.com/godori"
            }
        ]
    },
    {
        id: "ysm",
        name: "양성민",
        image: "profile_ysm.png",
        desc: "자바스크립트와 UX/UI 디자인에 관심이 많은 엔지니어입니다. 여행, 사진, 영화와 동물 🐶을 사랑합니다.",
        links: [
            {
                id: "website",
                url: "https://ysm.sh/"
            }
        ]
    },
    {
        id: "jyhwang",
        name: "황지영",
        image: "profile_jyhwang.png",
        desc: "프론트엔드 개발자입니다. 웹 기술, 퍼포먼스, 타입스크립트, UX 등에 관심이 많습니다. 작년 JSConf EU에 다녀오고 나서 서울에도 이렇게 발랄하고, 다양성을 존중하고, 축제와 같은 컨퍼런스가 있으면 좋겠다고 생각했는데 이렇게 멋진 첫걸음을 내딛게 된다고 하니 너무 기쁩니다!",
        links: [
            {
                id: "@jyhwng",
                url: "https://twitter.com/jyhwng/"
            }
        ]
    }
]