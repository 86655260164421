import React from "react"
import PageWrapper from "../../components/wrapper/pageWrapper"
import ShowCase from "../../components/showcase";

import { scholarships } from "../../i18n/en/main";

const Scholarships = () => (
    <PageWrapper locale="en" location="/scholarships">
        <ShowCase {...scholarships}/>
        <section className="subPage_contents_wrapper">
            <h1 className="subPage_title">Scholarships</h1>
            <p className="subPage_contents">
            We at JSConf Korea believe diverse perspectives are essential to the kind of thriving JavaScript community that we all want. That's why, for JSConf Korea 2019, we're introducing the Scholarship Program for underrepresented groups in the South Korean tech industry.</p>
            <br/>
            <p className="subPage_contents">
                Our Scholarship Program is made possible by amazing individuals who purchased our Scholarship Support tickets. For questions about our Scholarship funds, contact us at <a href="mailto:scholarships@jsconfkorea.com">scholarships@jsconfkorea.com</a>.</p>
            <br/>
            <a href="" target="_blanc" className="button_default">Applications are closed</a>
            <br/>
            <div className="listing_block beware_list">
                <ul className="subPage_list">
                <li className="subPage_list_item"><b>Application is now closed.</b></li>
                <li className="subPage_list_item">Application deadline: <b>Friday, July 19, 11:59 pm</b></li>
                <li className="subPage_list_item">Results announced until Sunday, July 28</li>
                </ul>
            </div>
            <h1 className="subPage_title">Who can apply?</h1>
            <p className="subPage_contents">
            If you’ve been interested in JavaScript or related technology, if you’ve been curious to participate in JavaScript communities or events in Korea, but for social or economic reasons couldn’t or didn't feel comfortable to attend, we'd like you to apply. We especially welcome members of underrepresented groups in the South Korean tech industry, including women, LGBTQI+, people with disabilities, people living outside of the Seoul Metropolitan Area, and people facing social or economic hardships.</p>
            <br/>
            <p className="subPage_contents">
                This year, the Scholarship Program only supports those living/staying in South Korea.</p>
            <h1 className="subPage_title">What’s in the Scholarship?</h1>
            <p className="subPage_contents">If selected, you will receive:</p>
            <div className="listing_block notice_list">
                <ul className="subPage_list">
                <li className="subPage_list_item">A ticket to the conference (which includes lunch)</li>
                <li className="subPage_list_item">Your travel expenses covered, within South Korea</li>
                <li className="subPage_list_item">One night of stay before the conference, if coming from outside the Seoul Metropolitan Area</li>
                </ul>
            </div>
            <h1 className="subPage_title">Selection Criteria and Process</h1>
            <p className="subPage_contents">
                We’re prioritizing individuals from underrepresented groups in the South Korean tech industry and individuals who are expected to benefit the most from attending JSConf Korea 2019.</p>
            <br/>
            <p className="subPage_contents">
                The selection committee will consist of JSConf Korea’s organizers.</p>
            <br/>
            <p className="subPage_contents">
            In the first round of the selection process, all applications are anonymized and prioritized solely based on the answers to “Why do you want to attend JSConf Korea 2019?”. In the second round, the prioritization is adjusted based on our selection criteria and scholarship funds.</p>
            <br/>
            <p className="subPage_contents"></p><br/>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSco86PkEmB_T7XojjE7XRMc25NVycxHUTU6G9Wn1pJrOOkpeQ/viewform?usp=sf_link" target="_blanc" className="button_default">Applications are closed</a>
            <h1 className="subPage_title">Contact</h1>
            <p className="subPage_contents">
                For any inquiries regarding the Scholarship Program, contact us at <a href="mailto:scholarships@jsconfkorea.com">scholarships@jsconfkorea.com</a>.</p>
        </section>
    </PageWrapper>
)

export default Scholarships