import React from "react"
import PageWrapper from "../components/wrapper/pageWrapper"
import ShowCase from "../components/showcase";

const CallForProposals = () => (
    <PageWrapper locale="ko" location="/call-for-proposals">
        <ShowCase type="cfp"/>
        <section className="subPage_contents_wrapper">
            <h1 className="subPage_title"></h1>
            <div className="listing_block beware_list">
                <ul className="subPage_list">
                <li className="subPage_list_item"><b>발표자 모집이 마감되었습니다.</b></li>
                <li className="subPage_list_item">모집 마감: 4월 26일 금요일 오후 11:59</li>
                </ul>
            </div>
            <br/>
            <p className="subPage_contents">
            JSConf Korea는 자바스크립트 커뮤니티를 이루는 다양한 사람들과 함께 기술의 한계를 뛰어 넘고 긍정적인 에너지를 주고받는 축제의 장입니다.
            자바스크립트를 이용한 재미있고 기발한 아이디어, 자바스크립트 커뮤니티와 나누고 싶었던 이야기가 있다면 주저없이 발표를 제안해주세요!
            우리의 <a href="/coc" target="_blanc">핵심원칙</a>만 잘 지킨다면 조금 독특해도 괜찮습니다. 발표 경험이 없어도 <a href="#support">도와드릴테니</a> 걱정마세요. 발표 주제는 아래 목록에서 골라도 되고 다른 반짝이는 주제를 선택해도 좋습니다.</p>
            <br/>
            <div className="listing_block notice_list">
                <ul className="subPage_list">
                    <li className="subPage_list_item">데이터 관리</li>
                    <li className="subPage_list_item">인공지능/머신러닝</li>
                    <li className="subPage_list_item">클라우드 컴퓨팅</li>
                    <li className="subPage_list_item">웹 어셈블리</li>
                    <li className="subPage_list_item">서버사이드 자바스크립트</li>
                    <li className="subPage_list_item">모바일 웹</li>
                    <li className="subPage_list_item">자바스크립트의 미래</li>
                    <li className="subPage_list_item">성능 및 최적화</li>
                    <li className="subPage_list_item">실시간, P2P 통신</li>
                    <li className="subPage_list_item">IoT, 하드웨어, 엠베디드 자바스크립트</li>
                    <li className="subPage_list_item">프로그래밍 교육</li>
                    <li className="subPage_list_item">자바스크립트로 구현한 예술</li>
                    <li className="subPage_list_item">자바스크립트와 OO (아무도 기대하지 않은 어떤 것)</li>
                </ul>
            </div>
            <h1 className="subPage_title">발표를 해야하는 이유!</h1>
            <h2 className="subPage_subTitle">발표자를 위한 혜택이 있습니다.</h2>
            <p className="subPage_contents">모든 발표자께 JSConf Korea 2019를 즐길 수 있는 티켓을 드립니다. 발표자 확정이 끝나기 전에 티켓이 매진될 수 있으니 꼭 참여하고 싶다면 미리 티켓을 구매해주세요. 발표자로 선정될 경우 환급해드리겠습니다. </p><br/>
            <p className="subPage_contents">행사장까지의 교통비도 지원해드립니다.</p><br/>
            <p className="subPage_contents">수도권 밖에서부터 발걸음 하시는 발표자께는 컨퍼런스 전날과 당일 호텔 숙박을 지원해드립니다. 접근성에 관한 요청은 최대한 반영해드릴 테니 알려주세요. </p>

            <h2 className="subPage_subTitle" id="support">발표 준비를 도와드립니다.</h2>
            <p className="subPage_contents">JSConf Korea는 <b>처음으로 발표해보는 분들도 환영합니다.</b> 많은 사람들 앞에서 이야기할 자신이 없어서, 경험이 없어서, 얘기하고픈 아이디어는 있는데 어떻게 발표로 풀어나갈지 모르겠어서 고민이라면 걱정마세요! 저희가 도와드리겠습니다. </p> 
                
            <div className="listing_block notice_list">
                <ul className="subPage_list">
                    <li className="subPage_list_item">발표 슬라이드는 함께 리뷰해드립니다.</li>
                    <li className="subPage_list_item">발표 내용도 오프라인이나 영상 통화로 함께 리뷰해드립니다. </li>
                    <li className="subPage_list_item">발표 경험이 풍부한 코치와 연결해드릴 수도 있습니다.</li>
                </ul>
            </div>
            <br/>
            <p className="subPage_contents">그 밖에 어떤 도움이든 필요하시다면 <a href="mailto:contact@jsconfkorea.com" target="_blanc">contact@jsconfkorea.com</a>로 알려주세요. </p>
            <br/>
            <h1 className="subPage_title">발표 형식</h1>
            <h2 className="subPage_subTitle">길이</h2>
            <p className="subPage_contents">각 발표는 준비시간을 포함하여 30분입니다. 절대 주어진 시간을 넘어갈 수 없으니 안전하게 <b>25분</b> 길이로 준비해주세요.</p>
            <p className="subPage_contents">질의응답시간은 따로 없습니다. 참가자들과 대화를 위해 마련한 별도의 장소에서 함께 이야기를 나눠주세요. </p>
            <h2 className="subPage_subTitle">발표 언어</h2>
            <p className="subPage_contents">발표 언어는 한국어 혹은 영어입니다. 한국어와 영어에 한해 동시통역이 진행될 예정입니다. 발표 슬라이드는 직접 두 언어로 준비해주실 수 있지 않는 한 번역해드립니다. </p>
            <h1 className="subPage_title">발표 자료 및 동영상</h1>
            <p className="subPage_contents">발표 슬라이드 및 발표 내용 동영상은 컨퍼런스 종료 후 JSConf Korea 웹사이트 및 <a href="https://www.youtube.com/channel/UCzoVCacndDCfGDf41P-z0iA" target="_blanc">JSConf 유튜브채널</a>에 공유될 예정입니다. 컨퍼런스에 참석하지 못한 사람들도 함께 배울 수 있는 기회를 제공하기 위함입니다. 저희 취지에 공감해주시면 좋겠지만 공개를 원치 않으시다면 미리 알려주세요. </p>
            
            <h1 className="subPage_title" id="#criteria">심사 절차와 기준</h1>
            <p className="subPage_contents">심사위원단은 JSConf Korea 준비위원회와 봉사자로 이루어집니다. </p><br/>
            <p className="subPage_contents">발표 심사는 발표자 모집이 마감된 후 시작됩니다. </p><br/>
            <p className="subPage_contents">1차 심사는 오로지 발표 내용을 기준으로 진행됩니다. 철저히 익명 처리된 발표 제안서에 심사위원들은 1-10까지의 점수를 매깁니다. 이 때, 다음과 같은 기준으로 심사합니다.</p>
            <div className="listing_block notice_list">
                <ul className="subPage_list">
                    <li className="subPage_list_item"><b>발표 내용의 명확성과 통일성</b></li>
                    <li className="subPage_list_item">자바스크립트 및 자바스크립트 커뮤니티와의 연관성</li>
                    <li className="subPage_list_item">반짝이고 독창적인 주제</li>
                    <li className="subPage_list_item">주어진 시간 안에 전달할 수 있는 만큼의 내용</li>
                </ul>
            </div>
            <p className="subPage_contents">최종 심사에서는 상위 제안서를 실명화하여 최종 발표자를 선정합니다. 이 때 개인 정보는 배경도 경험치도 최대한 다양한 발표자 목록을 꾸리기 위해, 또 컨퍼런스 사정에 따라 교통 및 숙박비용을 고려하기 위해 참고합니다.</p>
            <h1 className="subPage_title">발표 제안서 작성 방법</h1>
            <div className="listing_block notice_list">
                <ul className="subPage_list">
                    <li className="subPage_list_item">발표 주제는 충분히 구체적이어야합니다. 예를 들어, “리액트란 무엇인가?” 등의 주제는 너무 광범위합니다. <b>최대한 참신한 아이디어를 명확하게 전달해주세요.</b></li>
                    <li className="subPage_list_item">여러 아이디어가 있다면 별개의 제안서로 작성해주세요.</li>
                    <li className="subPage_list_item">발표 <a href="#criteria">심사 기준</a>을 고려해주세요.</li>
                    <li className="subPage_list_item">자바스크립트에 대한 열정을 제안서에 깊이 녹여주세요.</li>
                    <li className="subPage_list_item">아이디어가 있다면 우선 제안서를 제출해주세요! 모든 제안은 언제나 환영합니다.</li>
                </ul>
            </div>
            <br/>
            <p className="subPage_contents"></p><br/>
            <a href="https://goo.gl/forms/2MGfXmyVhTnAAkvx1" target="_blanc" className="button_default"><s>발표 제안서 모집 마감</s></a>
        </section>
    </PageWrapper>
)

export default CallForProposals
