import React from "react"
import Layout from "../components/layout"
import PageWrapper from '../components/wrapper/pageWrapper'

const IndexPage = () => (
  <PageWrapper locale="ko" location="/">
    <Layout locale="ko"/>
  </PageWrapper>
)

export default IndexPage
