/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Section from "./section";
import ShowCase from "./showcase";
import Subscriber from "./subscriber";
import PropTypes from "prop-types"
class Layout extends React.Component {
  constructor() {
    super();
    this.state = {
      showModal: false
    }

    this._handleOpenModal = this._handleOpenModal.bind(this);
    this._handleCloseModal = this._handleCloseModal.bind(this);
  }

  render() {
    const renderModal = this._renderModal();
    const locale = this.props.locale;
    return (
      <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
  
      // eslint-disable-next-line
      render={data => (
        <main>
          <ShowCase locale={locale} type="showcase"/>
          <div className="section_horizontal_wrapper">
            <Section type="about" />
            <Section type="coc"/>
          </div>
          <Section locale={locale} type="speaker"/>
          <Section locale={locale} type="tutorial" category="tutorials"/>
          <Section locale={locale} type="ticket"/>
          <Section type="subscribe" handleOpenModal={ this._handleOpenModal}/>
          { renderModal }
        </main>
      )}
    />
    )
  }

  _renderModal() {
    return <Subscriber isOpen={ this.state.showModal } handleCloseModal={ this._handleCloseModal }/>
  }

  _handleOpenModal() {
    this.setState({
      showModal: true
    })
  }

  _handleCloseModal() {
    this.setState({
      showModal: false
    })
  }
}

Layout.propTypes = {
  locale: PropTypes.string,
  data: PropTypes.object
}


export default Layout
