class Leaf {
    constructor(pos,acc, color, p, setting, donut) {
        this.p5 = require("../lib/p5");
        this.pos = pos.copy();
        this.pPos = this.pos.copy();
        this.acc = acc.copy();
        this.vel = new this.p5.Vector();
        this.vel.add(this.acc);
        this.acc.mult(0);
        this.life = 255;
        this.color = color;
        this.setting = setting;
        this.p = p;
        this.donut = donut;
    }

    applyForce(force) {
        this.acc.add(force);
    }

    update() {
        if(this.setting.mode) this.life -=0.85;
        else this.life -=1.5;
        
        let force;
        if(this.setting.mode) force = new this.p5.Vector(0.01,0);
        else force = new this.p5.Vector(0.05,0);
        
        let theta = this.p.noise(
            this.pos.x*this.setting.fieldDefinition+this.setting.xOffset, 
            this.pos.y*this.setting.fieldDefinition
        )*(this.p.PI*4);
        force.rotate(theta);
        
        this.applyForce(force);
        
        this.vel.add(this.acc);
        this.pPos = this.pos.copy();
        this.pos.add(this.vel);
        this.acc.mult(0);
        
        const move = this.p5.Vector.sub(this.pos,this.pPos);
        this.angle = move.heading();
    }

    display() {
        this.color.setAlpha(this.life);
        this.p.stroke(this.color);
        this.p.noFill();
        this.p.rectMode(this.p.CENTER);
        this.p.push();
        this.p.translate(this.pos.x,this.pos.y);
        this.p.rotate(this.angle);
        this.p.line(-5,0,5,0);
        this.p.pop();
    }

    isOut() {
        if(this.pos.x < -this.setting.margin || this.pos.x > this.p.width + this.setting.margin 
            || this.pos.y < -this.setting.margin || this.pos.y > this.p.height+ this.setting.margin)
         return true;
         else return false;
    }
}

export default Leaf;