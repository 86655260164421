export const header = {
    about: "About",
    speakers: "Speakers",
    tutorials: "Tutorials",
    scholarships: "Scholarships",
    coc: "Code of Conduct",
    accessibility: "Accessibility",
    sponsors: "Sponsors",
    schedule: "Schedule",
    tickets: "Tickets",
    close: "close",
    more: "more"
}

export const showcase = {
    type: "showcase",
    copy: "September 3, 2019\nCoex Conference Room 4F\nSeoul, Korea\n",
    buttonText: "Browse our talks"
}

export const schedule = {
    type: "schedule",
    copy: "Schedule for JSConf Korea 2019 🎉",
    room_401: "401 Main Talks",
    room_402: "402 Lightning Talks",
    room_403: "403 Tutorials"
}

export const about = {
    type: "about",
    title: "ABOUT US",
    copy: "Welcome to JSConf Korea!",
    desc: "JSConf Korea is a community conference that pushes the boundaries of what can be created using JavaScript, and an opportunity for people with diverse experiences to share their stories and positive energy with each other.\n\nOur mission is to make the Korean technology community more daring, more diverse, and more human; in short, we just want to make things better.\n\n",
    buttonText: "Read more",
    link: "/en/about"
}

export const coc = {
    type: "coc",
    title: "CODE OF CONDUCT",
    copy: "We are all in this together.",
    desc: "At JSConf Korea, we are dedicated to ensuring a harassment-free conference experience for everyone, regardless of gender, gender identity and expression, sexual orientation, disability, physical appearance, body size, race, age, religion, hometown or educational background.\n\nRead our Code of Conduct to learn what to expect from each other.",
    buttonText: "Read more",
    link: "/en/coc"
}

export const ticket = {
    type: "ticket",
    title: "TICKETS",
    copy: "Tickets are SOLD OUT!",
    desc: "Thank you for your love and support for JSConf Korea 2019.",
    buttonText: "Read more",
    link: "https://ti.to/jsconf-korea/2019"
}

export const subscribe = {
    type: "subscribe",
    title: "NEWSLETTER",
    copy: "Subscribe to our newsletter!",
    desc: "Sign up to get important updates on tickets, talks, scholarships, and more.",
    buttonText: "Subscribe",
    link: ""
}

export const sponsor = {
    type: "sponsor",
    copy: "Special thanks to our sponsors!"
}

export const seoul = {
    type: "seoul",
    copy: "Places to enjoy when you’re in Seoul for JSConf Korea."
}

export const scholarships = {
    type: "scholarships",
    copy: "Introducing our Scholarship Program!",
    desc: "We'd like more of you to be able to join us at JSConf Korea 2019.",
    buttonText: "Read more",
    link: "/en/scholarships",
    title: "SCHOLARSHIPS"
}

export const cfp = {
    type: "call-for-proposals",
    copy: "Call for Proposals!",
    link: "/en/call-for-proposals",
}

export const speaker = {
    type: "speaker",
    copy: "Our speakers to shine JSConf Korea 2019",
    title: "SPEAKERS"
}

export const tutorial = {
    type: "tutorial",
    copy: "Introducing JSConf Korea 2019 Tutorials!",
    title: "TUTORIALS"
}

export const footer = {
    twitter: "twitter",
    facebook: "facebook",
    accessibility: "Accessibility",
    scholarships: "Scholarships",
    coc: "Code of Conduct",
    faq: "FAQ",
    contact: "Contact us at"
}

export const accessibility = {
    type: "accessibility",
    copy: "Everyone deserves a good time.",
    reference: "from Coex facility information (http://www.coex.co.kr/blog/coex-facility-info/conference_south_4f)"
}

export const faq = {
    type: "faq",
    title: "FAQ",
    copy: "JSConf Korea FAQ",
    link: "/en/faq",
}

export const elastic = {
    type: "elastic",
    copy: "Elastic"
}

export const nexmo = {
    type: "nexmo",
    copy: "Nexmo"
}

export const line = {
    type: "line",
    copy: "Line"
}

export const bagelcode = {
    type: "bagelcode",
    copy: "Bagelcode"
}

export const hyperconnect = {
    type: "hyperconnect",
    copy: "Hyperconnect"
}

export const jetbrains = {
    type: "jetbrains",
    copy: "JetBrains"
}

export const lunit = {
    type: "lunit",
    copy: "Lunit"
}

export const woowahan = {
    type: "woowahan",
    copy: "Woowa Brothers Corp"
}

export default { about, coc, ticket, subscribe, sponsor, showcase, scholarships, cfp, header, footer, accessibility, faq, seoul, speaker, tutorial, schedule, elastic, nexmo, line, bagelcode, hyperconnect, jetbrains, lunit, woowahan }
