import React from "react"
import PageWrapper from '../../../components/wrapper/pageWrapper'
import ShowCase from "../../../components/showcase"

const Jetbrains = () => (
    <PageWrapper locale="en" location="/sponsors/jetbrains">
        <ShowCase type="jetbrains"/>
        <section className="subPage_contents_wrapper">
            <div className="sponsor_list sponsor_list_detail">
                <div className="sponsor_item">
                    <img className={`sponsor_img jetbrains`} alt="jetbrains" src={`/sponsors/logo_jetbrains.svg`}/>
                </div>
            </div>
            <p className="subPage_contents">
            JetBrains creates professional software development tools for coding in Java, Kotlin, C#, C++, Ruby, Python, PHP, JavaScript and more languages, as well as advanced team collaboration tools. <br/>
            At JetBrains, code is our passion. For over 18 years we have strived to make the strongest, most effective developer tools on Earth. By automating routine checks and corrections, our products speed up production, freeing developers to grow, discover and create. <br/>
            Today, more than 1000 JetBrains colleagues work out of Prague, Munich, St. Petersburg, Moscow, Boston, and Novosibirsk. Five million customers in 225 countries trust our tools daily. Kotlin, our own programming language, became an official language for Android development in 2017.
            </p>
        </section>
    </PageWrapper>
)

export default Jetbrains