import React from "react"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
// eslint-disable-next-line
import { fluidImage } from "../graphql/image"
import { injectIntl } from "react-intl"
import attractions from "../i18n/en/attractions"
import coffees from "../i18n/en/coffees"
import beers from "../i18n/en/beers"
import seoulInfo from "../i18n/en/seoulInfo"
import food from "../i18n/en/food"
import shopping from "../i18n/en/shopping"


const Seoul = () => {
    const renderImage = (imageData) => {
      return ( <Img fluid={imageData} /> ) 
    };

    const renderSeoulInfo = () => {
      return (
        seoulInfo.map(item => {
          const { title, desc } = item;
          return (
            <React.Fragment key={title}>
              <h1 className="subPage_title">{title}</h1>
              <p className="subPage_contents" dangerouslySetInnerHTML={{ __html: desc }}></p>
            </React.Fragment>
          );
        })
      )
    }

    const renderShoppingFood = (item) => {
      const { title, data } = item;
      return (
        <>
          <h1 className="subPage_title">{title}</h1>
          {
            data.map((datum) => {
              const { id, items } = datum;
              return (
                <React.Fragment key={id}>
                { id && <h2 className="subPage_subTitle">{id}</h2> }
                <div className="listing_block notice_list">
                  <ul className="subPage_list">
                  {
                    items.map(f => {
                      const { id, desc} = f;
                      return (
                        <li key={id} className="subPage_list_item" dangerouslySetInnerHTML={{ __html: desc }} />
                      )
                    })
                  }
                  </ul>
                </div>
                </React.Fragment>
              )
            })
          }
        </>
      )
    }

    const renderInfoItems = (items) => {
      return (
          <ul className="subPage_list">
            {
              items.map((item) => {
                const { name, desc, id }= item;
                return (
                  <li key={id} className="subPage_list_item with_children">
                    <p dangerouslySetInnerHTML={{ __html: name }} />
                    <ul className="subPage_list ">
                      <li className="subPage_list_item" dangerouslySetInnerHTML={{ __html: desc }}></li>
                    </ul>
                  </li>
                )
              })
            }
          </ul>
      )
    };

    const renderAttractions = (info) => {
      const { title, subTitle, desc, data } = info;

      return (
        <>
          <h1 className="subPage_title">{title}</h1>
          { subTitle && <p className="subPage_subTitle" dangerouslySetInnerHTML={{ __html: subTitle }}/> }
          { desc && <p className="subPage_contents" dangerouslySetInnerHTML={{ __html: desc }}/> }
          <div className="listing_block notice_list">
             { renderInfoItems(data) }
          </div>
        </>
      )
    };
    
    return (
        <StaticQuery query={graphql`
        query {
          korea: file(relativePath: { eq: "korea.jpg" }) {
            ...fluidImage
          }
        }
      `}
      render={data => {
        const imageData = data["korea"].childImageSharp.fluid;
        return (
          <>
          { renderImage(imageData) }
          { renderAttractions(attractions) }
          { renderAttractions(coffees) }
          { renderAttractions(beers) }
          { renderShoppingFood(food) }
          { renderShoppingFood(shopping) }
          { renderSeoulInfo() }
          </>
        );
      }}/>
    );
}

Seoul.propTypes = {
  intl: PropTypes.object
}

export default injectIntl(Seoul);