import React from "react"
import PageWrapper from '../../components/wrapper/pageWrapper'
import ShowCase from "../../components/showcase"

const COC = () => (
  <PageWrapper locale="en" location="/coc">
    <ShowCase type="coc"/>
    <section className="subPage_contents_wrapper">
      <h1 className="subPage_title">PURPOSE</h1>
      <p className="subPage_contents">JSConf Korea is dedicated to providing a harassment-free conference experience for everyone, regardless of gender, gender identity and expression, sexual orientation, disability, physical appearance, body size, race, age, religion, hometown, educational background, seniority level, choice of technology, language, or <a href="https://en.wikiversity.org/wiki/The_Neurodiversity_Movement/Section_1:_The_Basics" target="_blanc">neurotype</a>. We do not tolerate harassment of conference participants in any form. Sexual language and imagery is not appropriate for any conference venue, including talks. Conference participants violating these rules may be sanctioned or expelled from the conference without a refund at the discretion of the conference organisers.</p>

      <h1 id="antiharassment" className="subPage_title">ANTI-HARASSMENT</h1>
      <p className="subPage_contents">Harassment includes, but is not limited to:</p>

      <div className="listing_block beware_list">
        <ul className="subPage_list">
          <li className="subPage_list_item">Verbal comments that reinforce social structures of domination related to gender, gender identity and expression, sexual orientation, disability, physical appearance, body size, race, age, religion, hometown, educational background, language or neurotype.</li>
          <li className="subPage_list_item">Sexual images in public spaces</li>
          <li className="subPage_list_item">Deliberate intimidation, stalking, or following</li>
          <li className="subPage_list_item">Harassing photography or recording</li>
          <li className="subPage_list_item">Sustained disruption of talks or other events</li>
          <li className="subPage_list_item">Inappropriate physical contact</li>
          <li className="subPage_list_item">Invasion of personal space</li>
          <li className="subPage_list_item">Unwelcome sexual attention</li>
          <li className="subPage_list_item">Advocating for, or encouraging, any of the above behaviour</li>
        </ul>
      </div>

      <h1 className="subPage_title">ENFORCEMENT</h1>
      <p className="subPage_contents">If a participant engages in harassing behavior, event organisers retain the right to take any actions to keep the event a welcoming environment for all participants. This includes warning the offender or expulsion from the conference with no refund.</p><br/>
      <p className="subPage_contents">Event organisers may take action to address anything designed to, or with the clear impact of, disrupting the event or making the environment hostile for any participants.</p><br/>
      <p className="subPage_contents">We expect participants to follow these rules at all event venues and event-related social activities</p>

      <h1 id="report" className="subPage_title">REPORTING</h1>
      <p className="subPage_contents">If someone makes you or anyone else feel unsafe or unwelcome, please report it as soon as possible. Harassment and other Code of Conduct violations reduce the value of our event for everyone. We want you to be happy at our event. People like you make our event a better place. You can make a report either personally or anonymously.</p>
      <h2 className="subPage_subTitle">Anonymous report</h2>
      <p className="subPage_contents"><a href="https://bit.ly/2VLG1Ha" target="_blanc">You can file an anonymous report here.</a> We can’t follow up an anonymous report with you directly, but we will fully investigate it and take whatever action is necessary to prevent a recurrence.</p>

      <h2 className="subPage_subTitle">Personal report</h2>
      <p className="subPage_contents">You can make a personal report in two ways:</p>

      <div className="listing_block beware_list">
        <ul className="subPage_list">
          <li className="subPage_list_item">Talk to a staff member directly. (They're wearing yellow tshirts.)</li>
          <li className="subPage_list_item">Email: <a href="mailto:contact@jsconfkorea.com">contact@jsconfkorea.com</a></li>
        </ul>
      </div>
      <br/>
      <p className="subPage_contents">When taking a personal report, our staff will ensure you are safe and cannot be overheard. They may involve other event staff to ensure your report is managed properly. Once safe, we’ll ask you to tell us about what happened. This can be upsetting, but we’ll handle it as respectfully as possible. You won’t be asked to confront anyone and we won’t tell anyone who you are.</p>
      
      <h2 className="subPage_subTitle">Important contacts</h2>
      <div className="listing_block beware_list">
        <ul className="subPage_list">
          <li className="subPage_list_item with_children">Police: 112 (no need for a SIM card or to unlock your phone)
            <ul className="subPage_list ">
              <li className="subPage_list_item">Samsung 1 Police Station: <a href="https://goo.gl/maps/dAMBBQSAuHv" target="_blanc">107-3 Samseong 1(il)-dong, Gangnam-gu, Seoul</a></li>
            </ul>
          </li>
          <li className="subPage_list_item">Medical emergency: 119 (no need for a SIM card or to unlock your phone)</li>
          <li className="subPage_list_item">Coex Safety Center: 02-6000-0112</li>
        </ul>
      </div>
      <br/>
      <p className="subPage_contents">Our staff can assist you in contacting any of the above numbers or escorting you to where you will feel safe.</p>

      <h1 className="subPage_title" id="photo">PHOTO POLICY</h1>
      <p className="subPage_contents">Photographs and videos taken during the conference will be released through JSConf Korea's official channels. </p><br/> 
      <p className="subPage_contents">Whether you agree to be photographed will be indicated through your lanyard.</p>
      <div className="listing_block notice_list">
        <ul className="subPage_list">
          <li className="subPage_list_item">Black: "It's okay to photograph/film me. "</li>
          <li className="subPage_list_item">Yellow: "Please do not photograph/film me."</li>
        </ul>
      </div>
      <p className="subPage_contents">All participants, including attendees, sponsor representatives, and staff members, shall do their best to respect the lanyards. If you must take a photo of someone wearing a yellow lanyard, please ask first. If someone in a yellow lanyard is in a photo you've taken, please blur them out or delete the photo.</p><br/>
      <p className="subPage_contents">Our photographers and videographers will take extra caution to respect the lanyards. However, in crowded situations, they may not be able to follow completely. If a photograph or a recording includes participants wearing yellow lanyards, we will blur them out or not publish it. Furthermore, once the photographs and videos are published, you can ask us to take down certain photographs that you do not want to be shared.</p><br/>
      <p className="subPage_contents">Note that for our group photo, the lanyards will be ignored, so if you do not want to be photographed here we ask you to step outside of the crowd. </p><br/>

      <h1 className="subPage_title">INCLUSIVE LANGUAGE</h1>
      <p className="subPage_contents">Any speech that reinforce harmful stereotypes is unwelcome. Examples include but are not limited to:</p>
      <div className="listing_block beware_list">
        <ul className="subPage_list">
          <li className="subPage_list_item">Making undue assumptions and/or sweeping generalizations about a group of people.</li>
          <li className="subPage_list_item">Any language that deems disability a defect.</li>
          <li className="subPage_list_item">Any unwelcome comments on one’s physical appearance.</li>
        </ul>
      </div>

      <h1 className="subPage_title">ATTRIBUTION</h1>
      <p className="subPage_contents">This Code of Conduct was adapted from <a href="https://2019.jsconf.eu/code-of-conduct/" target="_blanc">JSConf EU’s Code of Conduct</a> to fit Korean culture and language. JSConf EU’s Code of Conduct was in turn based on <a href="http://jsconfau.com/" target="_blanc">CSSConf / JSConf AU, The Geek Feminism Wiki</a>, the work of <a href="https://frameshiftconsulting.com/code-of-conduct-training/" target="_blanc">Valerie Aurora</a> and <a href="http://confcodeofconduct.com/" target="_blanc">Conference Code of Conduct</a>. </p>
      <br/>
      <br/>
      <p className="subPage_contents">Last updated on September 26, 2019</p>
      <p className="subPage_contents">
          <a href="#">🔼Back to top</a></p>

      <h1 className="subPage_title">TRANSPARENCY REPORT</h1>
      <h2 className="subPage_subTitle" id="report_20191005">10/05/2019</h2>
      <p className="subPage_contents">During an event at the conference on September 3, Attendee A saw their face appearing in the selfie view of Attendee B's phone multiple times, which made Attendee A uncomfortable. Attendee A was wearing a yellow lanyard which meant no filming, but Attendee B was not aware of <a href="/en/coc#photo" target="_blanc">our photo policy</a>, including what each color of the lanyard meant. The JSConf Korea Organizing Committee could not confirm if the photographs were taken, but explained to Attendee B 1) what each color meant, 2) that they must not photograph attendees who did not agree to be photographed, and 3) should they (mistakenly) take such a photo, the photo must be immediately deleted. Attendee B acknowledged their mistake and promised to be careful going forward.</p><br/>
      <p className="subPage_contents">Until the day of the conference, the photo policy on our Code of Conduct did not clearly state that the rules and boundaries apply not only to the photography and filming staff, but also to our attendees. Moreover, we never officially announced which color of lanyards meant photography consent and which one did not. These mistakes have been corrected on the latest version of our Code of Conduct. We apologize for our mistake and for any confusion or distress we may have caused.</p>

    </section>
</PageWrapper>
)

export default COC