export default [
    {
        id: "godori",
        name: "고은정(Godori)",
        image: "profile_godori.png",
        desc: "빠른 프로토타이핑이 가능한 웹 개발을 좋아하고 생산성 도구나 이를 만드는 데 관심이 많은 소프트웨어 엔지니어입니다. 기술로 누군가를 도와주고 즐겁게 만들 수 있다고 믿습니다.",
        links: [
            {
                id: "github",
                url: "https://github.com/godori"
            }
        ]
    },
    {
        id: "ysm",
        name: "양성민",
        image: "profile_ysm.png",
        desc: "자바스크립트와 UX/UI 디자인에 관심이 많은 엔지니어입니다. 여행, 사진, 영화와 동물 🐶을 사랑합니다.",
        links: [
            {
                id: "website",
                url: "https://ysm.sh/"
            }
        ]
    },
    {
        id: "yoonji",
        name: "이윤지",
        image: "profile_yoonji.png",
        desc: "본업으로는 공간을 만들고 부업으로는 물건을 만듭니다. JSConf Korea의 첫 도약을 응원합니다!",
        links: [
            {
                id: "@yjayl33",
                url: "https://www.instagram.com/yjayl33"
            }
        ]
    },
    {
        id: "seungwoo",
        name: "이승우",
        image: "profile_seungwoo.png",
        desc: "개발과 인프라를 좋아하는 개발자 입니다.",
        links: [
            {
                id: "blog",
                url: "https://blog.2dal.com/"
            }
        ]
    },
    {
        id: "jane",
        name: "이정은",
        image: "profile_jane.png",
        desc: "성장하고 싶은 프론트엔드 개발자입니다. 수학을 전공하여 알고리즘에 흥미가 많습니다. 여행과 맛집 탐방을 좋아합니다. JSConf EU를 경험하고 한국에도 이런 컨퍼런스가 있었으면 좋겠다고 생각하여 함께하게 되었습니다.",
        links: [
            {
                id: "@snooje",
                url: "https://twitter.com/snooje/"
            },
            {
                id: "Instagram",
                url: "https://www.instagram.com/snooje/"
            }
        ]
    },
    {
        id: "jeonghyo",
        name: "정효",
        image: "profile_jeonghyo.png",
        desc: "프론트앤드 개발자로 프로그래밍을 이용한 시각예술 작업을 꾸준히 해오고 있습니다. 인터랙티브 아트 연구실을 운영중이고 아트코딩을 즐기고 입문하는 문턱을 낮추는데에 관심이 많습니다.",
        links: [
            {
                id: "website",
                url: "https://turtlefingers.com/"
            }
        ]
    },
    {
        id: "seol",
        name: "전설",
        image: "profile_seol.png",
        desc: "다양한 취미 생활과 여행을 좋아하고 마크업을 전문적으로 다루는 시니어 프론트엔드 개발자입니다. JSConf Korea의 뜻 깊은 첫 걸음을 도울 수 있어서 기뻐요.",
        links: []
    },
    {
        id: "jin",
        name: "박진우",
        image: "profile_jin.png",
        desc: "웹과 모바일을 주로 개발하는 소프트웨어 개발자입니다.",
        links: [
            {
                id: "website",
                url: "https://www.jinpark.net/"
            }
        ]
    },
    {
        id: "holden",
        name: "홀든 최",
        image: "profile_holden.png",
        desc: "영어 원어민으로서 수년간의 경험을 바탕으로 편집 직무를 수행하고 있습니다. 비전통적인 배경(스트렁크와 화이트*를 아직 읽지 않음)에도 불구하고, 그는 동생의 역사 논문부터 워싱턴의 권위 있는 <strike>로비</strike> 회사 정부 관계 기관의 내부문건까지 다양한 편집 경력을 자랑합니다. <p class='subPage_remark'> *역주: 스트렁크(William Strunk, Jr.)와 화이트(E. B. White)의 『글쓰기의 요소(The Elements of Style)』는 영작 필독서로 여겨진다. </p>",
        links: [
            {
                id: "blog",
                url: "https://medium.com/@chylirk"
            }
        ]
    },
    {
        id: "jyhwang",
        name: "황지영",
        image: "profile_jyhwang.png",
        desc: "프론트엔드 개발자입니다. 웹 기술, 퍼포먼스, 타입스크립트, UX 등에 관심이 많습니다. 작년 JSConf EU에 다녀오고 나서 서울에도 이렇게 발랄하고, 다양성을 존중하고, 축제와 같은 컨퍼런스가 있으면 좋겠다고 생각했는데 이렇게 멋진 첫걸음을 내딛게 된다고 하니 너무 기쁩니다!",
        links: [
            {
                id: "@jyhwng",
                url: "https://twitter.com/jyhwng/"
            }
        ]
    }
]