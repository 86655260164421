import React from "react"
import Layout from "../../components/layout"
import PageWrapper from '../../components/wrapper/pageWrapper'

const IndexPage = () => (
  <PageWrapper locale="en" location="/">
    <Layout locale="en"/>
  </PageWrapper>
)

export default IndexPage