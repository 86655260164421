import React from "react"
import PageWrapper from "../../components/wrapper/pageWrapper"
import ShowCase from "../../components/showcase";


const CallForProposals = () => (
    <PageWrapper locale="en" location="/call-for-proposals">
        <ShowCase type="cfp"/>
        <section className="subPage_contents_wrapper">
            <h1 className="subPage_title"></h1>
            <div className="listing_block beware_list">
                <ul className="subPage_list">
                <li className="subPage_list_item"><b>Applications are now losed.</b></li>
                <li className="subPage_list_item">Application deadline: Friday, April 26, 11:59 pm KST</li>
                </ul>
            </div>
            <br/>
            <p className="subPage_contents">
            JSConf is a world-wide family of conferences for the JavaScript community. As part of the JSConf family, we believe that progress happens when everyone has a chance to share their perspective. We're thrilled to be hosting our very first conference in 2019. </p><br/>
            <p className="subPage_contents">JSConf Korea is a festival, a place to push the boundaries of what can be done with JavaScript and share positive energy with one another. If you've used JavaScript on an exciting project, or have ideas, stories, or even a song to share with us, please submit a proposal! As long as it fits our <a href="/en/coc" target="_blanc">Code of Conduct</a>, anything’s on the table. Even if you don’t have any experience in giving talks, <a href="#support">we’re here to help</a>. You can pick a topic from the list below or come up with your own: </p>
            <br/>
            <div className="listing_block notice_list">
                <ul className="subPage_list">  
                    <li className="subPage_list_item">Data Management</li>
                    <li className="subPage_list_item">Web Security</li>
                    <li className="subPage_list_item">Artificial Intelligence / Machine Learning</li>
                    <li className="subPage_list_item">Cloud Computing</li>
                    <li className="subPage_list_item">Web Assembly</li>
                    <li className="subPage_list_item">Server-side JavaScript</li>
                    <li className="subPage_list_item">Mobile Web</li>
                    <li className="subPage_list_item">The Future of JavaScript</li>
                    <li className="subPage_list_item">Performance and Optimization</li>
                    <li className="subPage_list_item">Realtime, Peer-to-peer Communications</li>
                    <li className="subPage_list_item">IoT, Hardware, Embedded JavaScript</li>
                    <li className="subPage_list_item">Education and Outreach of Computer Programming</li>
                    <li className="subPage_list_item">Life of a Developer</li>
                    <li className="subPage_list_item">Artistic JavaScript Creations</li>
                    <li className="subPage_list_item">Putting JavaScript into places no one expected</li>
                </ul>
            </div>
            <h1 className="subPage_title">Why should you give a talk?</h1>
            <h2 className="subPage_subTitle">Perks</h2>
            <p className="subPage_contents">You will get a ticket to enjoy the conference. Please note that tickets may sell out before the list of speakers is finalized. So if you want to make sure you can attend, please purchase a ticket first and we’ll gladly reimburse you once you get selected.</p><br/>
            <p className="subPage_contents">We will also pay for your reasonable amount of travel expenses to and from the venue.</p><br/>
            <p className="subPage_contents">For speakers travelling from outside the Seoul Capital Area, we will arrange your accommodations at a hotel for the night before and the night of the conference. Let us know about any accessibility needs you have and we’ll do our best to meet them all.</p>
            <h2 className="subPage_subTitle" id="support">Speaker Support</h2>
            <p className="subPage_contents"><b>We welcome new speakers.</b> If you want to give a talk but are scared of public speaking, or think you are too inexperienced, or are worried about how to put your stories together into a talk, we are here to help.</p>
            <div className="listing_block notice_list">
                <ul className="subPage_list">
                    <li className="subPage_list_item">We’re happy to review and advise you on your slide deck.</li>
                    <li className="subPage_list_item">We’re happy to review your talk in person or via video chat.</li>
                    <li className="subPage_list_item">We’ll help you connect with an experienced speaker</li>
                </ul>
            </div>
            <br/>
            <p className="subPage_contents">Again, whatever else you might need, we’re here to help. Get in touch with us at <a href="mailto:contact@jsconfkorea.com" target="_blanc">contact@jsconfkorea.com</a>.</p>
            <br/>
            <h1 className="subPage_title">Talk Format</h1>
            <h2 className="subPage_subTitle">Duration</h2>
            <p className="subPage_contents">Each talk is 30 minutes long including transition and preparation time. The time limit is strict, so please prepare your talk to be <b>less than 25 minutes</b> long, to be safe.</p><br/>
            <p className="subPage_contents">There is no Q&A time, but you are free to talk with other attendees outside the auditorium after your talk. </p>
            <h2 className="subPage_subTitle">Language</h2>
            <p className="subPage_contents">All talks must be in either English or Korean. Live translation from English to Korean and from Korean to English will be provided for all talks. We will also translate your slide deck for you, unless you can translate them yourself.</p>
            
            <h1 className="subPage_title">Slides and Video Recording</h1>
            <p className="subPage_contents">All slides and video recordings of the talks will be shared on our website and the <a href="https://www.youtube.com/channel/UCzoVCacndDCfGDf41P-z0iA" target="_blanc">JSConf YouTube channel</a>. This is for the benefit of the greater JavaScript community and for those who couldn’t make it. We hope you agree, but if you don’t feel comfortable sharing your slides or getting the video published, let us know.</p>
            
            <h1 className="subPage_title" id="#criteria">Selection Process and Criteria</h1>
            <p className="subPage_contents">The speaker selection committee will consist of JSConf Korea organizers and volunteers.</p><br/>
            <p className="subPage_contents">The selection process will begin after the submission deadline.</p><br/>
            <p className="subPage_contents">In the first round, the committee evaluates completely anonymized proposals and rate them from 1 to 10, while considering the following criteria:</p>
            <div className="listing_block notice_list">
                <ul className="subPage_list">
                    <li className="subPage_list_item"><b>Coherence and clarity of proposal</b></li>
                    <li className="subPage_list_item">Relevance of the topic to JavaScript and the JavaScript community</li>
                    <li className="subPage_list_item">Novelty and originality of the topic</li>
                    <li className="subPage_list_item">Deliverable in given time</li>
                </ul>
            </div>
            <p className="subPage_contents">The top proposals are then de-anonymized for the committee to finalize the list of speakers. The committee will take speaker details into account in order to ensure a diverse lineup of speakers and to consider travel expenses depending on our finances.</p>
            <h1 className="subPage_title">How to write a proposal</h1>
            <div className="listing_block notice_list">
                <ul className="subPage_list">
                    <li className="subPage_list_item">Please be specific. A topic like “React 101” is way too broad. <b>Try to deliver an original idea as clearly as possible.</b></li>
                    <li className="subPage_list_item">If you have many ideas to talk about, please submit them as separate proposals.</li>
                    <li className="subPage_list_item">Consider our <a href="#criteria">selection criteria</a>.</li>
                    <li className="subPage_list_item">Try to show us your passion!</li>
                    <li className="subPage_list_item">If you have something you want to talk about, don’t be discouraged and write that proposal!</li>
                </ul>
            </div>
            <br/>
            <p className="subPage_contents"></p><br/>
            <a href="https://goo.gl/forms/ahzY52eStWsDpiYf2" target="_blanc" className="button_default"><s>Application closed</s></a>
        </section>
    </PageWrapper>
)

export default CallForProposals