import React from "react"
import PageWrapper from '../../components/wrapper/pageWrapper'
import ShowCase from "../../components/showcase"
import Sponsor from "../../components/sponsors";

import sponsors from "../../i18n/en/sponsors";
const { platinum, gold, community, lunch } = sponsors;
const Sponsors = () => (
    <PageWrapper locale="en" location="/sponsors">
        <ShowCase type="sponsor"/>
        <section className="subPage_contents_wrapper">
            <p id="platinum" className="subPage_title">Platinum Sponsors</p>
            <div className="sponsor_list">
                <Sponsor sponsors={platinum}/>
            </div>

            <p id="gold" className="subPage_title">Gold Sponsors</p>
            <div className="sponsor_list">
                <Sponsor sponsors={gold}/>
            </div>

            <p id="lunch" className="subPage_title">Lunch Sponsor</p>
            <div className="sponsor_list">
                <Sponsor sponsors={lunch}/>
            </div>

            <p id="community" className="subPage_title">Community Sponsors</p>
            <div className="sponsor_list">
                <Sponsor sponsors={community}/>
            </div>
            <br/>
        </section>
    </PageWrapper>
)

export default Sponsors