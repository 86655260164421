export default [
    {
        id: "preregistration",
        talk: "Pre-registration",
        time: "September 2 19:00 ~ 20:30",
        room: "",
        type: "all"
    },
    {
        id: "registration",
        talk: "Registration",
        time: "09:00 ~ 10:00",
        room: "",
        type: "all"
    },
    {
        id: "opening",
        speaker: "Chris Yang, Eunjeong Ko, Jiyoung Hwang",
        link: "/en/about#mcs",
        talk: "Opening",
        time: "10:00 ~ 10:10",
        room: "401 Main Talks",
        type: "401"
    },
    {

        id: "jenn",
        speaker: "Jenn Creighton",
        link: "/en/speakers#jenn",
        talk: "What Tamagotchis can teach you about generators",
        time: "10:10 ~ 10:40",
        room: "401 Main Talks",
        type: "401"
    },
    {
        id: "jaeseok",
        speaker: "Jaeseok Kang",
        link:"/en/speakers#jaeseok",
        talk: "Don't block the event loop! JavaScript Async for Effortless UX",
        time: "10:40 ~ 11:10",
        room: "401 Main Talks",
        type: "401"
    },
    {
        id: "break_1",
        talk: "Break",
        time: "11:10 ~ 11:30",
        room: "",
        type: "all"
    },
    {
        id: "lightning_talk_sponsor_1",
        speaker: "Lunit",
        talk: "NPM Package를 활용한 사내 공용 라이브러리와 컴포넌트 관리",
        time: "11:10 ~ 11:30",
        room: "402 Lightning Talks",
        type: "402",
        link: "/en/sponsors/lunit",
    },
    {
        id: "aaron",
        speaker: "Aaron Snowberger",
        link:"/en/tutorials#aaron",
        talk: "Learn JavaScript by Building a Shopping App in CodePen",
        time: "11:10 ~ 12:40",
        room: "403 Tutorials",
        type: "403"
    },
    {
        id: "wendy",
        speaker: "Wendy Gwo",
        link:"/en/speakers#wendy",
        talk: "Congrats! You’re the first person to have a baby at your startup. Now what?",
        time: "11:30 ~ 12:00",
        room: "401 Main Talks",
        type: "401"
    },
    {
        id: "jeonghoon",
        speaker: "Jeonghoon Byun aka Outsider",
        link:"/en/speakers#jeonghoon",
        talk: "Lessons from maintaining Mocha, an open source project",
        time: "12:00 ~ 12:30",
        room: "401 Main Talks",
        type: "401"
    },
    {
        id: "lunch",
        link: "/en/accessibility#food",
        talk: "Lunch",
        speaker: "COEX Starfield Mall",
        time: "12:30 ~ 14:10",
        room: "",
        type: "all"
    },
    {
        id: "lightning_talk_community_1",
        speaker: "Interactive Art Lab",
        talk: "HOW WE DO CODE X ART LAB",
        time: "13:30 ~ 13:40",
        room: "402 Lightning Talks",
        type: "402",
        link: "/en/sponsors#community",
    },
    {
        id: "lightning_talk_community_2",
        speaker: "GDG Korea WebTech",
        talk: "GDG Korea WebTech에 관한 모든 것",
        time: "13:40 ~ 13:50",
        room: "402 Lightning Talks",
        type: "402",
        link: "/en/sponsors#community",
    },
    {
        id: "lightning_talk_community_3",
        speaker: "Women Who Code Seoul",
        talk: "자바스크립트 튜토리얼 한글화",
        time: "13:50 ~ 14:00",
        room: "402 Lightning Talks",
        type: "402",
        link: "/en/sponsors#community",
    },
    {
        id: "jeonghyo",
        speaker: "Jeonghyo",
        link:"/en/tutorials#jeonghyo",
        talk: "p5.js Taster: Making an Interactive Music Video Together",
        time: "13:50 ~ 15:20",
        room: "403 Tutorials",
        type: "403"
    },
    {
        id: "jaeman",
        speaker: "Jaeman An",
        link:"/en/speakers#jaeman",
        talk: "Lifesaving AI and JavaScript",
        time: "14:10 ~ 14:40",
        room: "401 Main Talks",
        type: "401"
    },
    {
        id: "willian",
        speaker: "Willian Martins",
        link:"/en/speakers#willian",
        talk: "Back to the future of JS II: Beyond what we can foresee",
        time: "14:40 ~ 15:10",
        room: "401 Main Talks",
        type: "401"
    },
    {
        id: "Break 2",
        talk: "Break",
        time: "15:10 ~ 15:30",
        type: "all"
    },
    {
        id: "lightning_talk_sponsor_2",
        speaker: "Woowa Brothers",
        talk: "우아한형제들의 개발팀 이야기",
        time: "15:10 ~ 15:30",
        room: "402 Lightning Talks",
        type: "402",
        link: "/en/sponsors#platinum",
    },
    {
        id: "gyujin",
        speaker: "Gyujin Cho",
        link:"/en/speakers#gyujin",
        talk: "Functional Programming: Learning by Application to Redux and Canvas",
        time: "15:30 ~ 16:00",
        room: "401 Main Talks",
        type: "401"
    },
    {
        id: "isha",
        speaker: "Isha Kasliwal",
        link:"/en/speakers#isha",
        talk: "How Typescript Can Power Design Systems",
        time: "16:00 ~ 16:30",
        room: "401 Main Talks",
        type: "401"
    },
    {
        id: "break_3",
        talk: "Break",
        time: "16:30 ~ 16:50",
        type: "all"
    },
    {
        id: "lightning_talk_sponsor_3",
        speaker: "Hyperconnect",
        talk: "JS Bundle 사이즈 최적화를 통한 성능개선",
        time: "16:30 ~ 16:50",
        room: "402 Lightning Talks",
        type: "402",
        link: "/en/sponsors/hyperconnect",
    },
    {
        id: "istvan",
        speaker: "Istvan Szmozsanszky aka Flaki",
        link:"/en/speakers#istvan",
        talk: "A WebAssembly Field Guide easily worth like 70 bottle caps",
        time: "16:50 ~ 17:20",
        room: "401 Main Talks",
        type: "401"
    },
    {
        id: "vitalii",
        speaker: "Vitalii Bobrov",
        link:"/en/speakers#vitalii",
        talk: "The Man who Sold the Amp: How to Process Music with JS",
        time: "17:20 ~ 17:50",
        room: "401 Main Talks",
        type: "401"
    },
    {
        id: "closing",
        talk: "Closing",
        speaker: "Chris Yang, Eunjeong Ko, Jiyoung Hwang",
        link: "/en/about#mcs",
        time: "17:50 ~ 17:55",
        room: "401 Main Talks",
        type: "401"
    },
    {
        id: "group_photo",
        talk: "Group Photo 📸",
        time: "17:55 ~ 18:00",
        room: "401 Main Talks",
        type: "401"
    }
]