export default [
    {
        id: "preregistration",
        talk: "사전 등록",
        time: "9월 2일 19:00 ~ 20:30",
        room: "",
        type: "all"
    },
    {
        id: "registration",
        talk: "등록",
        time: "09:00 ~ 10:00",
        room: "",
        type: "all"
    },
    {
        id: "opening",
        talk: "오프닝",
        speaker: "양성민, 고은정, 황지영",
        link: "/about#mcs",
        time: "10:00 ~ 10:10",
        room: "401호 메인 발표",
        type: "401"
    },
    {

        id: "jenn",
        speaker: "젠 크레이튼",
        link: "/speakers#jenn",
        talk: "다마고치로 배우는 제너레이터",
        time: "10:10 ~ 10:40",
        room: "401호 메인 발표",
        type: "401"
    },
    {
        id: "jaeseok",
        speaker: "강재석",
        link:"/speakers#jaeseok",
        talk: "Don't block the event loop! 매끄러운 경험을 위한 자바스크립트 비동기 처리",
        time: "10:40 ~ 11:10",
        room: "401호 메인 발표",
        type: "401"
    },
    {
        id: "break_1",
        talk: "휴식",
        time: "11:10 ~ 11:30",
        room: "",
        type: "all"
    },
    {
        id: "lightning_talk_sponsor_1",
        speaker: "루닛",
        talk: "NPM Package를 활용한 사내 공용 라이브러리와 컴포넌트 관리",
        time: "11:10 ~ 11:30",
        room: "402호 라이트닝 토크",
        type: "402",
        link: "/sponsors/lunit"
    },
    {
        id: "aaron",
        speaker: "에런 스노버거",
        link:"/tutorials#aaron",
        talk: "코드펜에 쇼핑 앱 만들며 자바스크립트 배우기!",
        time: "11:10 ~ 12:40",
        room: "403호 튜토리얼",
        type: "403"
    },
    {
        id: "wendy",
        speaker: "웬디 궈",
        link:"/speakers#wendy",
        talk: "축하합니다! 당신의 스타트업에서 처음으로 아이를 갖게 되셨습니다. 이제 어떡하죠?",
        time: "11:30 ~ 12:00",
        room: "401호 메인 발표",
        type: "401"
    },
    {
        id: "jeonghoon",
        speaker: "변정훈 aka Outsider",
        link:"/speakers#jeonghoon",
        talk: "오픈소스 프로젝트 mocha에서 배운 것들",
        time: "12:00 ~ 12:30",
        room: "401호 메인 발표",
        type: "401"
    },
    {
        id: "lunch",
        talk: "점심",
        link: "/accessibility#food",
        time: "12:30 ~ 14:10",
        speaker: "코엑스 스타필드몰",
        room: "",
        type: "all"
    },
    {
        id: "lightning_talk_community_1",
        speaker: "Interactive Art Lab",
        talk: "HOW WE DO CODE X ART LAB",
        time: "13:30 ~ 13:40",
        room: "402호 라이트닝 토크",
        type: "402",
        link: "/sponsors/#community",
    },
    {
        id: "lightning_talk_community_2",
        speaker: "GDG Korea WebTech",
        talk: "GDG Korea WebTech에 관한 모든 것",
        time: "13:40 ~ 13:50",
        room: "402호 라이트닝 토크",
        type: "402",
        link: "/sponsors/#community",
    },
    {
        id: "lightning_talk_community_3",
        speaker: "Women Who Code Seoul",
        talk: "자바스크립트 튜토리얼 한글화",
        time: "13:50 ~ 14:00",
        room: "402호 라이트닝 토크",
        type: "402",
        link: "/sponsors/#community",
    },
    {
        id: "jeonghyo",
        speaker: "정효",
        link:"/tutorials#jeonghyo",
        talk: "p5.js 맛보기: 함께 만들어보는 인터랙티브 뮤직비디오",
        time: "13:50 ~ 15:20",
        room: "403호 튜토리얼",
        type: "403"
    },
    {
        id: "jaeman",
        speaker: "안재만",
        link:"/speakers#jaeman",
        talk: "생명을 살리는 자바스크립트와 인공지능",
        time: "14:10 ~ 14:40",
        room: "401호 메인 발표",
        type: "401"
    },
    {
        id: "willian",
        speaker: "윌리언 마르틴쉬",
        link:"/speakers#willian",
        talk: "백 투 더 퓨처 오브 자바스크립트 II: 미래를 넘어",
        time: "14:40 ~ 15:10",
        room: "401호 메인 발표",
        type: "401"
    },
    {
        id: "break_2",
        talk: "휴식",
        time: "15:10 ~ 15:30",
        type: "all"
    },
    {
        id: "lightning_talk_sponsor_2",
        speaker: "우아한형제들",
        talk: "우아한형제들의 개발팀 이야기",
        time: "15:10 ~ 15:30",
        room: "402호 라이트닝 토크",
        type: "402",
        link: "/sponsors/#platinum",
    },
    {
        id: "gyujin",
        speaker: "조규진",
        link:"/speakers#gyujin",
        talk: "함수형, Redux와 Canvas에 적용하면서 배워나가기",
        time: "15:30 ~ 16:00",
        room: "401호 메인 발표",
        type: "401"
    },
    {
        id: "isha",
        speaker: "이샤 카슬리왈",
        link:"/speakers#isha",
        talk: "Typescript로 디자인 시스템에 날개 달기",
        time: "16:00 ~ 16:30",
        room: "401호 메인 발표",
        type: "401"
    },
    {
        id: "break_3",
        talk: "휴식",
        time: "16:30 ~ 16:50",
        type: "all"
    },
    {
        id: "lightning_talk_sponsor_3",
        speaker: "하이퍼커넥트",
        talk: "JS Bundle 사이즈 최적화를 통한 성능개선",
        time: "16:30 ~ 16:50",
        room: "402호 라이트닝 토크",
        type: "402",
        link: "/sponsors/hyperconnect",
    },
    {
        id: "istvan",
        speaker: "이슈트반 스모잔스키 aka Flaki",
        link:"/speakers#istvan",
        talk: "적어도 캡 70개 어치는 하는 웹어셈블리 지침서",
        time: "16:50 ~ 17:20",
        room: "401호 메인 발표",
        type: "401"
    },
    {
        id: "vitalii",
        speaker: "비탈리 보브로프",
        link:"/speakers#vitalii",
        talk: "The Man who Sold the Amp: 자바스크립트로 음악 처리하기",
        time: "17:20 ~ 17:50",
        room: "401호 메인 발표",
        type: "401"
    },
    {
        id: "closing",
        talk: "클로징",
        speaker: "양성민, 고은정, 황지영",
        link: "/about#mcs",
        time: "17:50 ~ 17:55",
        room: "401호 메인 발표",
        type: "401"
    },
    {
        id: "group_photo",
        talk: "단체사진 📸",
        time: "17:55 ~ 18:00",
        room: "401호 메인 발표",
        type: "401"
    }
]