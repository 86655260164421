import React from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import classnames from "classnames"
import ProtoTypes from "prop-types";

export default class IndexPage extends React.Component {
    constructor() {
        super();

        this.state = {
            email: null,
            resultMsg: "",
            resultState: ""
        }

        this._handleSubmit = this._handleSubmit.bind(this);
        this._handleChange = this._handleChange.bind(this);
    }

    render() {
        const resultMsg = this.state.resultMsg;
        const resultClass = (this.state.resultState === "error" ) ? "failed" : "success";
        const { handleCloseModal, isOpen } = this.props;
        const wrapperClass = classnames({
            "popup_subscribe": true,
            "is_activated": isOpen
        });
        
        return (
            <React.Fragment>
                <div className={ wrapperClass }>
                    <div className="popup_wrapper">
                        <p className="popup_desc">Subscribe to our newsletter!</p>
                        <form className="email_form_container" onSubmit={this._handleSubmit}>
                            <input className="email_input" type="email" onChange={this._handleChange} placeholder="email" name="email" />
                            <span className={`result ${resultClass}`}  dangerouslySetInnerHTML={{ __html: resultMsg }}></span>
                            <input className="button_default" type="submit"/>
                        </form>
                        <button className="button_close" onClick= { handleCloseModal }><span className="blind">click to close it</span></button>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    _handleSubmit = async (e) => {
        e.preventDefault();
        const result = await addToMailchimp(this.state.email);
        this.setState( {
            resultState: result.result,
            resultMsg: result.msg
        });
    }

    _handleChange(e) {
        this.setState({
            email: e.target.value
        });
    }
}

IndexPage.propTypes = {
    isOpen: ProtoTypes.bool,
    handleCloseModal: ProtoTypes.func
}