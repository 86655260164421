export default [
    {
        id: "sona",
        name: "Sona Lee",
        image: "profile_sona.png",
        desc: "Sona is a front-end engineer who loves art, music, beer, writing, and blending tech into all of the above. After an unforgettable time at JSConf EU last year, Sona started JSConf Korea to create a conference that’s as exciting and diverse as the JavaScript ecosystem.",
        links: [
            {
                id: "@mojosoeun",
                url: "https://twitter.com/mojosoeun"
            }
        ]
    },
    {
        id: "suzin",
        name: "Suzin You",
        image: "profile_suzin.png",
        desc: "Suzin dreams of a tech community where anyone in the world has a chance.",
        links: [
            {
                id: "@o0o000000000000",
                url: "https://twitter.com/o0o000000000000/"
            }
        ]
    },
    {
        id: "kukhwan",
        name: "Kukhwan Park",
        image: "profile_kukhwan.png",
        desc: "Seeking beauty through technology.",
        links: [
            {
                id: "@kukhwan1",
                url: "https://twitter.com/kukhwan1/"
            }
        ]
    },
    {
        id: "seungkyu",
        name: "Seungkyu Jang",
        image: "profile_seongkyu.png",
        desc: "선한 영향력을 꿈꾸는 기획자입니다. 사이드 허슬로 비영리단체를 꾸리고 있어요. 낭만적 감성과 달리 전략적이고 분석적인 사고를 즐깁니다. 새해 목표로 책 100권 읽기를 실천 중이며, 로망은 싱글 메뉴 레스토랑 브랜드를 만드는 것입니다.",
        links: [
            {
                id: "@sk.jang",
                url: "https://www.instagram.com/sk.jang/"
            }
        ]
    }
]