import React from "react"
import PageWrapper from '../../../components/wrapper/pageWrapper'
import ShowCase from "../../../components/showcase"

const Nexmo = () => (
    <PageWrapper locale="en" location="/sponsors/nexmo">
        <ShowCase type="nexmo"/>  
        <section className="subPage_contents_wrapper">
            <div className="sponsor_list sponsor_list_detail">
                <div className="sponsor_item">
                    <img className={`sponsor_img nexmo`} alt="nexmo" src={`/sponsors/logo_nexmo.svg`}/>
                </div>
            </div>
            <p className="subPage_contents">Nexmo is a global cloud communications platform, providing APIs and SDKs for messaging, voice, phone verification, advanced multi-channel conversations and video calling with the OpenTok API. With our Messages and Dispatch Beta you can now integrate with various communication channels including Facebook Messenger, WhatsApp and Viber. We support open source libraries for PHP, Python, Ruby, Node.JS, Java and C# .NET enabling you to build scalable communications features.</p>
        </section>
    </PageWrapper>
)

export default Nexmo