export default {
    title: "Beer",
    subTitle: "The Best Places to Grab a Craft Beer in Seoul",
    desc: `Gone are the days when beer brewing was <a href="https://econ.st/2GE3wLO" target="_blanc">“just about the only useful activity at which North Korea beats the South"</a>.  After a new law that passed in 2014, craft beer in Korea has <a  href="https://bit.ly/2ZDi1Is" target="_blanc">gone through a revolution</a>, with a host of idiosyncratic microbreweries springing up across the country.`,
    data: [
        {
            id: "Amazing Brewery (Gundae)",
            name: `Amazing Brewery (Gundae) - Map(<a href="https://bit.ly/2DDtJcD" target="_blanc">Link</a>)`,
            desc: `Recently opened tap room of one of the best craft brewers in Korea. A large space where you can help yourself through  an RFIDbased payment system.`
        },
        {
            id: "The Hand and Malt Brewing TAPROOM (Jongno)",
            name: `The Hand and Malt Brewing TAPROOM (Jongno) - Map(<a href="https://goo.gl/maps/fx2pri1g2WH2" target="_blanc">Link</a>)`,
            desc: `A great microbrewery in a quaint little Hanok`
        },
        {
            id: "Kiwa taproom (Jongno)",
            name: `Kiwa taproom (Jongno) - Map(<a href="https://goo.gl/maps/NiC21mLAtSU2" target="_blanc">Link</a>)`,
            desc: `This cozy Hanok-turned-taproom is hidden away in the winding alleys surrounding the MMCA. A great place to wind down.`
        },
        {
            id: "Magpie Brewery (Itaewon)",
            name: `Magpie Brewery (Itaewon) - Map(<a href="https://goo.gl/maps/tJy93aFxHM52" target="_blanc">Link</a>)`,
            desc: `One of the original craft brewers in Korea. Makes great consistent beers while also trying out new ideas.`
        },
        {
            id: "Anthracite Coffee Roasters (Seogyo)",
            name: `Anthracite Coffee Roasters (Seogyo) - Map(<a href="https://goo.gl/maps/xvkVNkja2W12" target="_blanc">Link</a>)`,
            desc: `Anthracite is the most hip cafe around in Seoul. Probably the best designed cafe that also serves single malt whiskey.`
        },
        {
            id: "The Booth (Gangnam)",
            name: `The Booth (Gangnam) - Map(<a href="https://goo.gl/maps/odFQuu8W8j42" target="_blanc">Link</a>)`,
            desc: `Cheap Korean craft brews in a camping-inspired, casual space.`
        },
        {
            id: "Mikkeller (Garosu-gil)",
            name: `Mikkeller (Garosu-gil) - Map(<a href="https://goo.gl/maps/Ctqxf56cXEM2" target="_blanc">Link</a>)`,
            desc: `Mikkeller has a Seoul branch! Great beers from one of the world’s best breweries.`
        }
    ]
}