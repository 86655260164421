import React from "react"
import PageWrapper from "../components/wrapper/pageWrapper"
import ShowCase from "../components/showcase";

const Scholarships = () => (
    <PageWrapper locale="ko" location="/scholarships">
        <ShowCase type="scholarships"/>
        <section className="subPage_contents_wrapper">
            <h1 className="subPage_title">장학 제도</h1>
            <p className="subPage_contents">
                JSConf Korea 2019의 장학생 모집이 마감되었습니다. 본 장학 제도는 한국의 기술분야에서 마땅히 대표되지 못해온 그룹에 속하신 분들을 위해 준비했습니다. 여러분의 다양한 경험에서 비롯되는 새로운 시각은 우리 모두가 바라는 건강한 자바스크립트 커뮤니티를 가꾸는데 있어 꼭 필요하기때문입니다.</p>
            <br/>
            <p className="subPage_contents">
                JSConf Korea 2019의 장학 제도는 장학금 지원 티켓을 구매해주신 멋진 후원자분들의 도움으로 운영할 수 있습니다. 장학 제도 후원에 관한 문의는 <a href="mailto:scholarships@jsconfkorea.com">scholarships@jsconfkorea.com</a>으로 연락해주시기 바랍니다.</p>
            <br/>
            <a href="" target="_blanc" className="button_default">장학 제도 모집 마감</a>
            <br/>
            <div className="listing_block beware_list">
                <ul className="subPage_list">
                <li className="subPage_list_item"><b>모집이 마감되었습니다.</b></li>
                <li className="subPage_list_item">모집 마감: 7월 19일 금요일 오후 11:59</li>
                <li className="subPage_list_item">결과 안내: 7월 28일 일요일까지 </li>
                </ul>
            </div>
            <h1 className="subPage_title">누가 신청할 수 있나요?</h1>
            <p className="subPage_contents">
                자바스크립트나 관련 기술에 관심이 있으신 분들, JSConf가 궁금하셨던 분들, 하지만 사회⋅경제 등 여건 때문에 기술 컨퍼런스나 커뮤니티 행사에 참여하는것이 불편하셨거나 어려우셨던 분들을 위해 이 장학 제도를 준비했습니다. 특히 한국의 기술 분야에서 마땅히 대표되지 못해온 여성, LGBTQI+, 장애가 있으신 분들, 수도권 외 지역에 거주하시는 분들, 사회⋅경제적으로 어려움을 겪고 계신 분들의 신청을 환영합니다.</p>
            <br/>
            <p className="subPage_contents">
                올해 장학 제도는 국내에 거주하고 계신 분들만 지원해드립니다.</p>
            <h1 className="subPage_title">장학생 혜택</h1>
            <p className="subPage_contents">장학생에게는 다음과 같은 혜택을 제공해드립니다.</p>
            <div className="listing_block notice_list">
                <ul className="subPage_list">
                <li className="subPage_list_item">컨퍼런스 티켓을 지원해 드립니다. 여기에는 점심 식사도 포함됩니다.</li>
                <li className="subPage_list_item">교통비를 지원해드립니다.</li>
                <li className="subPage_list_item">수도권 외 지역에서 오실 경우 컨퍼런스 전날 숙소를 제공해 드립니다.</li>
                </ul>
            </div>
            <h1 className="subPage_title">심사</h1>
            <p className="subPage_contents">
                국내 기술분야에서 제대로 대표되지 못해온 그룹에 속하시는 분들과 JSConf Korea 2019에 참여함으로써 가장 많이 얻어가실 수 있다고 판단되는 분들을 우선으로 선정합니다.</p>
            <br/>
            <p className="subPage_contents">
                심사위원회는 JSConf Korea 준비위원으로 구성됩니다.</p>
            <br/>
            <p className="subPage_contents">
                1차 심사에서는 JSConf Korea 2019에 오고 싶은 이유 기준으로 우선순위를 정한 후, 2차에서는 실명화하여 심사 기준과 장학금 후원 모금액을 고려하여 우선순위를 조정하고, 최종 선정합니다.</p>
            <br/>
            <p className="subPage_contents"></p><br/>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSeFLl7L940F9KuWVvHTBRsfK-v3LBs8eg7omhtlyIrMn5ICcA/viewform?usp=sf_link" target="_blanc" className="button_default">장학 제도 모집 마감</a>
            <h1 className="subPage_title">문의</h1>
            <p className="subPage_contents">
                장학 제도에 관한 질문은 <a href="mailto:scholarships@jsconfkorea.com">scholarships@jsconfkorea.com</a>로 문의해주세요.</p>
        </section>
    </PageWrapper>
)

export default Scholarships
