import React from "react"
import PropTypes from "prop-types"
import { injectIntl } from "react-intl"
import { Link } from "gatsby"

const Footer = ({intl, locale}) => {
  const loc = locale === "ko" ? `/` : `/${locale}/`;
  
  return (
    <footer className="section_footer">
      <div className="footer_contents_wrapper">
        <div>
          <a className="button_link link_to_twt" href="https://twitter.com/jsconfkorea" target="_blanc">{ intl.formatMessage({ id: "footer.twitter"})}</a>
          <a className="button_link link_to_FB" href="https://www.facebook.com/jsconfkorea" target="_blanc">{ intl.formatMessage({ id: "footer.facebook"})}</a>
          <span className="link_to_contact">
            { intl.formatMessage({ id: "footer.contact"})} <a href="mailto:contact@jsconfkorea.com">contact@jsconfkorea.com</a>
          </span>
        </div>
        <div className="link_to_page">
        <Link className="nav_item" to={ `${loc}faq`} activeClassName="is_activated"> <span className="nav_text">{ intl.formatMessage({ id: "footer.faq"})}</span></Link>
          <Link className="nav_item" to={ `${loc}coc`} activeClassName="is_activated"> <span className="nav_text">{ intl.formatMessage({ id: "footer.coc"})}</span></Link>
          <Link className="nav_item" to={ `${loc}accessibility`} activeClassName="is_activated"> <span className="nav_text">{ intl.formatMessage({ id: "footer.accessibility"})}</span></Link>
          <Link className="nav_item" to={ `${loc}scholarships`} activeClassName="is_activated"> <span className="nav_text">{ intl.formatMessage({ id: "footer.scholarships"})}</span></Link>
        </div>
      </div>
  </footer>
  );
}

Footer.propTypes = {
  intl: PropTypes.object,
  locale: PropTypes.string
}

export default injectIntl(Footer);
