import React, { Component } from "react"
import P5Wrapper from "./wrapper/p5Wrapper";
import sketch from "../sketches/sketch"
import PropTypes from "prop-types"
import { injectIntl } from "react-intl"
import { Link } from "gatsby"

export class ShowCase extends Component {
    render() {
        const desc = this._renderDesc();
        const className = this._getClassName();
        
        return (
            <div className="section_art">
                <div id="showcase" className={className}>
                    { this._renderP5() }
                    { desc }
                </div>
            </div>
        )
    }

    _getClassName() {
        const { type } = this.props;
        const className = type === "showcase" ? "showcase_main" : "showcase_guide";
        return className;
    }

    _renderP5() {
        return (<P5Wrapper sketch={sketch} />);
    }
    _renderDesc() {
        const { type, intl, locale } = this.props;
        const loc = locale === "en" ? `/en/` : `/`;
        const defalutStyle = { whiteSpace: "pre-wrap" };
        if (type === "showcase") {
            return (
                <div className="section_location" style={ this._getSectionStyle(defalutStyle) }>
                    <div className="location_info_wrapper">
                        <h1 className="location_info">{ intl.formatMessage({ id: "showcase.copy"}) }</h1>
                        <Link to={`${loc}speakers` } className="button_default">{ intl.formatMessage({ id: "showcase.buttonText"}) }</Link>
                    </div>
                    <span className="link_to_artwork">Javascript art by <a href="https://turtlefingers.com/" target="_blanc">@turtlefingers</a></span>
                </div>
            );
        } else {
            return (
                <div className="section_guide" style={ this._getSectionStyle(defalutStyle) }>
                    <h1 className="guide_info" dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: `${type}.copy`}) }}></h1>
                </div>
            );
        }
    }

    _getSectionStyle(defalutStyle) {
        return Object.assign(defalutStyle, {
            position: "absolute",
            backgroundColor: "rgba( 255, 255, 255, 0)"
        });
    }
}

ShowCase.defaultProps = {
    type: "showcase"
}

ShowCase.propTypes = {
    type: PropTypes.string,
    intl: PropTypes.object,
    locale: PropTypes.string
}

export default injectIntl(ShowCase);