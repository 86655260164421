import React from "react"
import PageWrapper from "../components/wrapper/pageWrapper"
import ShowCase from "../components/showcase"
const Ticket = () => (
  <PageWrapper locale="ko" location="/ticket">
    <ShowCase type="ticket"/>
    <section className="subPage_contents_wrapper">
    <h1 className="subPage_title">티켓</h1>
    <p className="subPage_contents">컨퍼런스 티켓이 매진되었습니다! 여러분의 관심과 성원에 감사드립니다. 우리 모두 9월에 만나요!</p><br/><br/>

    <a href="https://event-us.kr/jsconf-korea/event/8739" target="_blanc" className="button_default button_default_ticket"><s>국내 결제 수단으로 티켓 구매하기</s></a>
    <br/>
    <a href="https://ti.to/jsconf-korea/2019/" target="_blanc" className="button_default button_default_ticket"><s>해외 결제 수단으로 티켓 구매하기</s></a>


    <h1 className="subPage_title">자주 묻는 질문</h1>
    <br/>
    <h2 className="subPage_subTitle">Q. 티켓 환불은 어떻게 하나요?</h2>
    <br/>
    <p className="subPage_contents"><b>티켓 환불은 불가능</b>합니다. 다만 자유롭게 양도하실 수 있습니다.</p>
    <br/>
    <h2 className="subPage_subTitle">Q. 티켓 양도가 가능한가요?</h2>
    <br/>
    <p className="subPage_contents"> 모든 티켓은 양도 가능합니다. 이벤터스(국내 결제 수단)에서 구매하셨다면 직접 피양도자를 찾으신 후 <a href="mailto:ticket@jsconfkorea.com">ticket@jsconfkorea.com</a>로 <b>2019년 8월 23일</b>까지 문의해 주세요.</p>
    <br/>
    <h2 className="subPage_subTitle">Q. 영수증을 받을 수 있나요?</h2>
    <br/>
    <p className="subPage_contents">이벤터스의 <a href="https://event-us.kr/mypage/Certificate" target="_blanc">증빙서류 페이지</a>를 참고하세요.</p>
    <br/>
    <h2 className="subPage_subTitle">Q. 세금계산서를 발급 받을 수 있나요?</h2>
    <br/>
    <p className="subPage_contents">JSConf Korea는 법인으로 보는 단체로서 세금계산서 발행이 불가하오니, 카드로 결제하고 영수증을 증빙 자료로 제출하실 것을 제안드립니다.</p>
    <br/>
    <h2 className="subPage_subTitle">Q. 애프터파티 티켓은 컨퍼런스 티켓과 별도인가요?</h2>
    <br/>
    <p className="subPage_contents">네. 애프터파티는 컨퍼런스 이후 진행되는 별도의 이벤트입니다. 애프터파티 티켓만 구매하실 경우 컨퍼런스에는 참가하실 수 없습니다.</p>
    <br/>
    <h2 className="subPage_subTitle">Q. "장학생 지원" 옵션은 무엇인가요?</h2>
    <br/>
    <p className="subPage_contents">장학생 지원 옵션을 구매하시면 해당 금액은 JSConf Korea 2019의 장학생분들의 참가를 지원하는데에 쓰입니다. 장학생 프로그램에 대한 정보는 추후 공개될 예정입니다.</p>
    <br/>
    <h2 className="subPage_subTitle">Q. 행사 일정은 어떻게 되나요? </h2>
    <p className="subPage_contents"><a href="/schedule" target="_blanc">컨퍼런스 시간표 페이지</a>를 확인해주세요.</p>
    <br/>

    <p className="subPage_contents"> 그 외 질문은 <a href="mailto:ticket@jsconfkorea.com">ticket@jsconfkorea.com</a>로 문의해 주세요.</p>
    
    <br/>
    </section>
  </PageWrapper>
)

export default Ticket
