import React from "react"
import PageWrapper from "../components/wrapper/pageWrapper"
import ShowCase from "../components/showcase"
import Sponsor from "../components/sponsors";

import sponsors from "../i18n/ko/sponsors";

const { platinum, gold, community, lunch } = sponsors;

const Sponsors = () => (
  <PageWrapper locale="ko" location="/sponsors">
    <ShowCase type="sponsor"/>
    <section className="subPage_contents_wrapper">
      <p id="platinum" className="subPage_title">플래티넘 스폰서</p>
      <div className="sponsor_list">
          <Sponsor sponsors={platinum}/>
      </div>

      <p id="gold" className="subPage_title">골드 스폰서</p>
      <div className="sponsor_list">
          <Sponsor sponsors={gold}/>
      </div>

      <p id="lunch" className="subPage_title">점심 스폰서</p>
      <div className="sponsor_list">
          <Sponsor sponsors={lunch}/>
      </div>

      <p id="community" className="subPage_title">커뮤니티 스폰서</p>
      <div className="sponsor_list">
          <Sponsor sponsors={community}/>
      </div>
      <br/>
    </section>
  </PageWrapper>
);

export default Sponsors