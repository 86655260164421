import Leaf from "./leaf";
import Donut from "./donut";

const setting = {
    fieldDefinition : 0.005,
    bgColor :         null,
    leafColors :      [],
    mode :            false,
    leaves :          [],
    donuts :          [],
    bloomingPoints :  [],
    margin :          20,
    padding :         150,
    xOffset :         0,
    frameGap :        15
}

export default function sketch(p) {
    const p5 = require("../lib/p5");
    let showcase;
    p5.disableFriendlyErrors = true; 
    const bloomingEvent = () => {
        let inside = false;
        
        if(p.mouseX > setting.padding*0.1 && p.mouseX < p.width - setting.padding*0.1
            && p.mouseY > setting.padding*0.1 && p.mouseY < p.height - setting.padding*0.1){
            inside = true;
        }
        
        if(inside && p.frameCount%setting.frameGap === 0){
          let pos = new p5.Vector(p.mouseX,p.mouseY);
          blooming(pos);
        }
    }

    const blooming = (pos) => {
        let leafNum = Math.floor(p.random(30,50));
        if(leafNum%2===1)leafNum++;
        
        let colorNum = p.floor(p.random(setting.leafColors.length));
        let lineColor =  setting.leafColors[colorNum];
        let donut = new Donut(lineColor, p);
        
        for(let i=0; i<leafNum; i++){
          const acc = new p5.Vector(1,0);
          acc.rotate( (i/leafNum)*p.PI*2 );
          const leaf = new Leaf(pos, acc, lineColor, p, setting, donut);
          setting.leaves.push(leaf);
          donut.points.push(leaf);
        }
        setting.donuts.push(donut);
    }

    const setPallette = () => {
        setting.bgColor = p.color("#FFF0CA");
        setting.leafColors.push(p.color("#18C67F"));
        setting.leafColors.push(p.color("#FFA400"));
        setting.leafColors.push(p.color("#FF008A"));
        setting.leafColors.push(p.color("#00CDFF"));
        setting.leafColors.push(p.color("#FF5828"));
    }

    p.setup = () => {
        showcase = document.getElementById("showcase");
        const graphic = p.createCanvas(showcase.clientWidth, showcase.clientHeight);
        graphic.parent("showcase");
        setPallette();
        p.strokeWeight(2);
    }

    p.windowResized = () => {
        p.resizeCanvas(showcase.clientWidth,showcase.clientHeight);
    }

    p.draw = () => {
        bloomingEvent();
        
        p.background(setting.bgColor);
        
        for(let i=setting.leaves.length-1; i>=0; i--){
            setting.leaves[i].update();
            if( (!setting.mode && setting.leaves[i].isOut()) || setting.leaves[i].life < 0) {
            if(!setting.mode && setting.leaves[i].isOut()){
                let donutIndex = setting.donuts.indexOf(setting.leaves[i].donut);
                if(donutIndex!==-1) setting.donuts.splice(donutIndex,1);
            }
            setting.leaves.splice(i,1);
            }
        }
        
        for(let i=setting.donuts.length-1; i>=0; i--){
            if(setting.donuts[i].points[0].life < 0) setting.donuts.splice(i,1);
        }

        if(setting.mode){
            for(let i=0; i<setting.donuts.length; i++){
                setting.donuts[i].display();
            }
        }else{
            for(let i=0; i<setting.leaves.length; i++){
                setting.leaves[i].display();
            }
        }
    }

    p.mouseClicked = () => {
        setting.mode = !setting.mode;
    }
}