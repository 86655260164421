import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"

const Sponsor = ({ sponsors }) => {
      const hasDetailInfo = (link) => {
        if(link === "#" || link.indexOf("http") !== -1) {
            return false;
        }
        return true;
      }

      const renderSponsorLink = (sponsor) => {
          const {id, link } = sponsor;
          if (hasDetailInfo(link)) {
              return (
                <Link className="sponsor_link" to={link}>
                    <img className={`sponsor_img ${id}`} alt={id} src={`/sponsors/logo_${id}.svg`}/>
                </Link>
              )
          }

          return (
            <a className="sponsor_link" href={link} target="_blanc">
                <img className={`sponsor_img ${id}`} alt={id} src={`/sponsors/logo_${id}.svg`}/>
            </a>
          );
      }

      return (
          sponsors.map((sponsor) => {
              const { id } = sponsor;
              return (
                  <div className="sponsor_item" key={id}>
                    {renderSponsorLink(sponsor)}
                  </div>
                  
              );
          })
      );
}

Sponsor.propTypes = {
    sponsors: PropTypes.array
}

export default Sponsor;