import React from "react"
import PageWrapper from '../../components/wrapper/pageWrapper'
import ShowCase from "../../components/showcase"

const Woowahan = () => (
    <PageWrapper locale="ko" location="/sponsors/woowahan">
        <ShowCase type="woowahan"/>
        <section className="subPage_contents_wrapper">
            <div className="sponsor_list sponsor_list_detail">
                <div className="sponsor_item">
                    <img className={`sponsor_img woowahan`} alt="elastic" src={`/sponsors/logo_woowahan.svg`}/>
                </div>
            </div>
            <p className="subPage_contents">
            국내 1위 배달앱 배달의민족을 운영하는 ‘우아한형제들’은 '좋은 음식을 먹고 싶은 곳에서'라는 비전 아래, 배달이 안 되던 외식업소의 음식을 배달해주는 '배민라이더스',  음식업종 자영업자에게 좋은 품질의 배달용품을 합리적인 가격에 제공하는 '배민상회' 등으로 배달문화를 바꿔 나가고 있습니다. 
            또한 종합 '푸드테크' 기업으로 나아가기 위하여 해외시장 진출 등의 글로벌 사업과 자율주행 로봇 기술 개발 등의 미래사업으로 사업확장을 준비, 진행하고 있습니다. <br/>

            우아한형제들은 '구성원을 행복하게 만들면 행복한 구성원이 더 좋은 서비스를 만든다'는 믿음으로 모두가 즐겁게 일하는 회사를 만들어 가고 있습니다.
            그 성장의 중심에는 코드 덩어리가 아닌 가치를 만들고 스스로 가치를 높이며 일하는 우아한 개발자들이 있습니다.
            </p>
        </section>
    </PageWrapper>
)

export default Woowahan