export default [
    {
        id: "eun",
        name: "Eun Cho",
        image: "profile_eun.png",
        desc: "Passionate about technologies and changing the world through the Web. Web technology Google Developers Expert, photographer, translator, traveller, and much more.",
        links: [
            {
                id: "@apes01234",
                url: "https://twitter.com/apes01234"
            },
            {
                id: "https://techhtml.github.io",
                url: "https://techhtml.github.io"
            }
        ]
    }
]