import PropTypes from "prop-types"
import React, { useState } from "react"
import Img from "gatsby-image"
import SpeakerLinks from "./speakerLinks"
import { navigate } from '@reach/router'

// eslint-disable-next-line
import { fluidImage } from "../graphql/image"

const SpeakerDetail = ({ speaker, imageData, isDetail, locale, category }) => {
    const [open, setOpen] = useState(false)
    const { id, name, title, links, talk, desc, firstname } = speaker;
    const openClass = (open ? "show" : "hide");

    const handleClick = () => {
        setOpen(!open)
    };

    const handleMoveSpeakerDetail = () => {
        navigate(`${category}s#${id}`);
    }

    const renderAbout = () => {
        if (locale === "ko") {
            return (
                <>
                    <br/>
                    <p className={`speaker_about ${openClass}`}>{`${firstname}님은...`}</p>
                    <br/>
                    <p className={`speaker_intro ${openClass}`}>{desc}</p>
                </>
            )
        } else {
            return (
                <>
                    <br/>
                    <p className={`speaker_about ${openClass}`}>{`About ${firstname}`}</p>
                    <br/>
                    <p className={`speaker_intro ${openClass}`}>{desc}</p>
                </>
            )
        }
    }

    const rednerClickableName = () => {
        if (isDetail) {
            return (
                <p className="speaker_name">{name}</p>
            )
        } else {
            return (
                <a className="speaker_name" aria-label={`Read more ${name}'s introduction`} onClick={ handleMoveSpeakerDetail}><p>{name}</p></a>
            )
        }
    }

    const rednerClickableThumbnail = () => {
        if (isDetail) {
            return (
                <Img className="speaker_thumbnail" fluid={imageData} />
            )
        } else {
            return (
                <a aria-label={`Read more ${name}'s introduction`} onClick={handleMoveSpeakerDetail}><Img className="speaker_thumbnail" fluid={imageData} /></a>
            )
        }
    }
    
    const renderShowMoreBtn = () => {
        if (isDetail) {
            return (
                <div className="speaker_description_button_container">
                    <button className="speaker_description_button" onClick={handleClick}>{!open ? "Read More" : "Hide Content"}</button>
                </div>
            );
        }
    };
    return (
        <>
        <div className="speaker_image_container">
        { rednerClickableThumbnail() }
        {isDetail && <SpeakerLinks links={links} isDetail={isDetail}/> }
        </div>
        <div className="speaker_desc">
            <div className="speaker_name_box">
                { rednerClickableName() }
                {!isDetail && <SpeakerLinks links={links} isDetail={isDetail}/> }
            </div>
            <p className="speaker_title" dangerouslySetInnerHTML={{ __html: title }}></p>
            <br/>
            {isDetail && <p className="speaker_description" dangerouslySetInnerHTML={{ __html: talk }}></p>}
            <br/>
            { renderAbout() }
            { renderShowMoreBtn() }
        </div>
        </>
    )
}

SpeakerDetail.propTypes = {
    category: PropTypes.string,
    speaker: PropTypes.object,
    imageData: PropTypes.object,
    isDetail: PropTypes.bool,
    locale: PropTypes.string
}

export default SpeakerDetail;
