import React from "react"
import PageWrapper from "../components/wrapper/pageWrapper"
import ShowCase from "../components/showcase"

const COC = () => (
  <PageWrapper locale="ko" location="/coc">
    <ShowCase type="coc" />
    <section className="subPage_contents_wrapper">
      <h1 className="subPage_title">JSConf Korea의 가치관</h1>
      <p className="subPage_contents">지난 몇 년간 한국 사회는 수많은 측면에서 갈등을 겪으며 성장통을 앓고 있습니다. 
        자바스크립트 커뮤니티에서는 갈등과 상처의 악순환이 아니라 따뜻함과 긍정의 에너지가 선순환되어야 한다고 JSConf Korea는 굳게 믿습니다. 
        JSConf Korea는 우리 모두의 다양성과 존엄성이 존중되는 기술 커뮤니티를 만들기 위해 최선을 다할 것이며, 우리의 가치가 훼손되지 않도록 노력할 것입니다.</p> <br/>
      <p className="subPage_contents">우리의 가치관을 실천하기 위해 단호하게 아래의 핵심원칙을 규정했습니다.</p>
      <h1 id="antiharassment" className="subPage_title">모두가 존중받는 컨퍼런스</h1>
      <p className="subPage_contents">JSConf Korea는 성별, 성 정체성 및 표현 방법, 성적 지향, 장애, 외모, 신체 사이즈, 인종, 연령, 종교, 출신 지역, 학력, 경력, 사용 기술, 언어, <a href="https://en.wikiversity.org/wiki/The_Neurodiversity_Movement/Section_1:_The_Basics" target="_blanc">뉴로타입</a>에 상관없이 모두가 존중받는 컨퍼런스 경험을 제공하기 위해 최선을 다하고 있습니다. JSConf Korea에서는 컨퍼런스 참가자에 대한 어떠한 괴롭힘도 용납하지 않습니다. 성적인 언행이나 묘사는 세션들을 포함한 컨퍼런스 내 어느곳에서도 부적절합니다. 이러한 규칙을 위반하는 참가자는 컨퍼런스 주최 측의 판단에 따라 제재를 당하거나 환불 없이 퇴장당할 수 있습니다. </p>
      <br/>
      <p className="subPage_contents">여기서 <strong>괴롭힘</strong>이란 아래를 포함하지만 아래에 국한되지는 않습니다. </p>
      <div className="listing_block beware_list">
        <ul className="subPage_list">
          <li className="subPage_list_item">성별, 성 정체성 및 표현, 성적 지향, 장애, 외모, 신체 사이즈, 인종, 연령, 종교, 출신 지역, 학력, 언어, <a href="https://en.wikiversity.org/wiki/The_Neurodiversity_Movement/Section_1:_The_Basics" target="_blanc">뉴로타입</a>에 관한 사회적 억압을 재생산하는 언행</li>
          <li className="subPage_list_item">공공장소에서의 성적 묘사</li>
          <li className="subPage_list_item">위협, 스토킹</li>
          <li className="subPage_list_item">허락 없이 사진이나 비디오를 촬영하는 행위</li>
          <li className="subPage_list_item">컨퍼런스 진행을 지속적으로 방해하는 행위</li>
          <li className="subPage_list_item">부적절한 신체 접촉</li>
          <li className="subPage_list_item">프라이버시 침해</li>
          <li className="subPage_list_item">상대가 원하지 않는 성적 호감 표출</li>
          <li className="subPage_list_item">위 행위 중 어떤 것이라도 옹호하거나 장려하는 행위</li>
        </ul>
      </div>

      <h1 className="subPage_title">괴롭힘에 대해 우리는 이렇게 대응해요.</h1>
      <p className="subPage_contents">참가자에 대한 괴롭힘이 발생할 경우, 컨퍼런스 주최 측은 모두가 환영받는 환경을 유지하기 위해 어떠한 조치이든 취할 권리가 있습니다. 예를 들어, 행동강령 위반자에게 경고를 주거나 컨퍼런스에서 환불 없이 퇴장시킬 수 있습니다.</p>
      <br/>
      <p className="subPage_contents">뿐만 아니라 컨퍼런스 진행에 방해가 되거나 누구에게라도 적대적일 만한 상황을 발견하면 주최 측은 이를 해결하기 위한 조치를 취할 수 있습니다.</p>
      <br/>
      <p className="subPage_contents">JSConf Korea의 모든 참가자는 컨퍼런스장 및 컨퍼런스와 관련된 모든 행사에서 이 행동강령을 반드시 준수해야 합니다.</p>

      <h1 className="subPage_title">우리 함께 언행에 조금만 신경써요.</h1>
      <p className="subPage_contents">특정 집단에게 상처가 될 수 있는 고정 관념에서 비롯된 언행은 삼가주세요. 다음과 같은 예가 있지만 이에 국한되지는 않습니다.</p>
      <div className="listing_block beware_list">
        <ul className="subPage_list">
          <li className="subPage_list_item">특정 집단을 무시하는 발언</li>
          <li className="subPage_list_item">장애를 “비정상”적인 것이라 여기는 발언</li>
          <li className="subPage_list_item">상대방의 외모에 대해 원치 않는 코멘트나 “칭찬”을 하는 것</li>
        </ul>
      </div>
      <p className="subPage_contents">내가 쓰고 있는 말 중 차별적인 언어가 있는지 <a href="https://opengov.seoul.go.kr/seoullove/16424309" target="_blanc">이곳</a>을 통해 확인해보세요.</p>

      <h1 className="subPage_title" id="photo">내 얼굴은 나의 것: 촬영 정책</h1>
      <p className="subPage_contents">촬영된 사진과 영상은 JSConf Korea의 공식 채널을 통해 공개됩니다.</p>
      <br/>
      <p className="subPage_contents">참가자 여러분의 사진 및 영상 촬영 동의 여부는 명찰을 통해 구분지어집니다.</p> 
      <div className="listing_block notice_list">
        <ul className="subPage_list">
          <li className="subPage_list_item">검정색: "촬영해도 괜찮습니다."</li>
          <li className="subPage_list_item">노란색: "촬영하지 말아주세요."</li>
        </ul>
      </div>
      <p className="subPage_contents">참가자, 스폰서, 스태프 모두 촬영에 동의하지 않은 분들은 촬영하지 않도록 최선을 다할 것입니다. 꼭 촬영하고 싶으시다면 촬영 전에 동의를 구하시고, 실수로 노란 목걸이를 건 참가자가 찍혔다면 모자이크처리하거나 지워주셔야 합니다.</p>
      <br/>
      <p className="subPage_contents">촬영작가분들도 명찰을 따르도록 각별히 신경쓸 것입니다. 하지만 복잡한 환경에서는 간혹 촬영이 될 수 있는 점에 대해 미리 양해를 구합니다. 공식 촬영물에 노란 목걸이를 건 참가자가 찍혔다면, 공개 전 모자이크처리되거나 삭제됩니다. 또한 촬영물이 공개되면 공개되기를 원치 않는 사진에 대해 삭제를 요청하실 수 있습니다.</p>
      <br/>
      <p className="subPage_contents">단체 사진 촬영의 경우 동의 여부에 상관없이 진행되므로, 촬영되고싶지 않으실 경우 단체 사진에 참여하지 않으시길 요청드립니다.</p>

      <h1 id="report" className="subPage_title">이렇게 신고해주세요!</h1>
      <p className="subPage_contents">위협적이거나 적대적으로 느껴지는 상황이 있다면 최대한 빨리 신고해주세요. 여러분의 적극적인 참여는 JSConf Korea가 더 좋은 행사로 거듭나는데 큰 도움이 됩니다. 스태프에게 직접 신고하는 방법과 익명 신고 방법이 있습니다.</p>
      <h2 className="subPage_subTitle">익명으로 신고하기</h2>
      <p className="subPage_contents"><a href="https://bit.ly/2VLG1Ha" target="_blanc">익명 신고는 이 링크</a>에서 하실 수 있습니다. 익명으로 신고하실 경우 신고자께 직접 연락드릴 수는 없지만 최선을 다해 정황을 파악하고 같은 일이 일어나지 않도록 조치하겠습니다.</p>

      <h2 className="subPage_subTitle">직접 신고하기</h2>
      <div className="listing_block beware_list">
        <ul className="subPage_list">
          <li className="subPage_list_item">스태프에게 신고: 노란색 티셔츠를 입은 가까운 스태프에게 신고해주세요.</li>
          <li className="subPage_list_item">이메일 신고: <a href="mailto:contact@jsconfkorea.com">contact@jsconfkorea.com</a></li>
        </ul>
      </div>
      <br/>
      <p className="subPage_contents">스태프는 신고자의 안전과 비밀을 보장하기 위해 최선을 다할 것입니다. 신고에 적절히 대응하기 위해 다른 스태프에게 신고 내용을 알려야 할 수도 있습니다. 먼저 신고자의 안전을 보장한 후, 신고자의 존엄을 지키며 어떤 일이 일어났는지 물어볼 것입니다. 신고자께서는 누구를 직면해야 할 일도 없을 것이고 신분이 공개되는 일도 없을 것입니다.</p>
      <h2 className="subPage_subTitle">주요 연락처</h2>
      <div className="listing_block beware_list">
        <ul className="subPage_list">
          <li className="subPage_list_item with_children">경찰: 112 (잠금해제나 SIM 카드 없이 연결 가능)
            <ul className="subPage_list ">
              <li className="subPage_list_item">삼성1파출소, <a href="https://goo.gl/maps/dAMBBQSAuHv" target="_blanc">서울 강남구 영동대로112길 4</a></li>
            </ul>
          </li>
          <li className="subPage_list_item">구급차: 119 (잠금해제나 SIM 카드 없이 연결 가능)</li>
          <li className="subPage_list_item">코엑스 안전상황실: 02-6000-0112</li>
        </ul>
      </div>
      <br/>
      <p className="subPage_contents">신고자의 요청에 따라 스태프는 위 연락처로 신고해드리거나 신고자의 안전이 보장되는 곳으로 동행해드릴 수 있습니다.</p>
      <h1 className="subPage_title">참고 문서</h1>
      <p className="subPage_contents">JSConf Korea의 핵심원칙은 <a href="https://2019.jsconf.eu/code-of-conduct/" target="_blanc">JSConf EU의 행동강령(Code of Conduct)</a>을 한국의 문화와 언어에 맞게 수정하여 작성하였습니다.
      <a href="http://jsconfau.com/" target="_blanc">JSConf EU의 행동강령은 CSSConf / JSConf AU, The Geek Feminism Wiki</a>, 그리고 <a href="https://frameshiftconsulting.com/code-of-conduct-training/" target="_blanc">Valerie Aurora</a>님의 글 및 <a href="http://confcodeofconduct.com/" target="_blanc">Conference Code of Conduct</a>을 참고하여 작성되었습니다. <br/>
      </p>
      <br/>
      <br/>
      <p className="subPage_contents">마지막 수정일: 2019년 9월 26일</p>
      <p className="subPage_contents">
          <a href="#">🔼위로 가기</a></p>

      <h1 className="subPage_title">투명성 보고서</h1>
      <h2 className="subPage_subTitle" id="report_20191005">2019/10/05</h2>
      <p className="subPage_contents">지난 9월 3일 컨퍼런스에 참석한 참가자 A는 자신의 앞에 있던 참가자 B의 휴대폰의 셀카 뷰에 자신의 얼굴이 여러번 노출되는 것을 확인하고 불쾌감을 느꼈습니다. 참가자 A는 촬영 미동의를 의미하는 노란색 명찰 목걸이를 걸고 있었으나, 참가자 B는 목걸이 색깔의 의미를 포함하여 <a href="/coc#photo" target="_blanc">JSConf Korea의 사진 촬영 정책</a>에 대해 인지하지 못하고 있었습니다. 실제 사진을 촬영했는지 여부는 확인할 수 없었습니다. JSConf Korea 준비위원회는 참가자 B에게 목걸이 색깔의 의미를 설명하고, 촬영할 때는 촬영에 동의하지 않은 분이 찍히지 않도록 주의해야 하며, 인지하지 못한채로 찍혔을 때는 해당 사진을 지워야 한다고 숙지시켰고, 참가자 B는 앞으로는 조심할 것을 약속했습니다.</p><br/>
      <p className="subPage_contents">컨퍼런스 당일까지의 JSConf Korea의 핵심 원칙의 촬영 정책은 촬영 스태프가 촬영하는 경우에 대해서만 설명했고, 참가자들이 촬영을 하는 경우에 대해서는 설명이 미흡했습니다. 특히나 어떤 색깔의 명찰 줄이 촬영 동의/미동의를 의미하는지 일괄적으로 안내해드리지 못했습니다. 최신 버전의 핵심 원칙에 이 점을 정정하였습니다. 준비위의 불찰이 야기했을 혼란이나 오해에 대해 깊이 사과드립니다.</p><br/>
    </section>
</PageWrapper>
)

export default COC